import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EndSchoolYearComponent} from './end-school-year.component';
import {NoDataModule} from '@nit-components';

@NgModule({
  imports: [
    CommonModule,
    NoDataModule
  ],
  declarations: [
    EndSchoolYearComponent
  ],
  exports: [
    EndSchoolYearComponent
  ]
})

export class EndSchoolYearModule {
}
