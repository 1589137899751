import {environment} from '../../../environments/environment';
import {AuthConfig} from 'angular-oauth2-oidc';

export const authPasswordFlowConfig: AuthConfig = {
  issuer: environment.authUrl,
  tokenEndpoint: `${environment.authUrl}/connect/token`,
  userinfoEndpoint: `${environment.authUrl}/connect/userinfo`,
  revocationEndpoint: `${environment.authUrl}/connect/revocation`,
  requireHttps: false,
  redirectUri: window.location.origin + '/profile',
  logoutUrl: `${window.location.origin}/login`,
  postLogoutRedirectUri: 'login',
  clientId: 'nit',
  dummyClientSecret: 'secret',
  scope: 'openid profile permission other_info identity.api nit.api router.api offline_access chat.api school.api olympiad.api',
  showDebugInformation: false,
  oidc: false,
};
