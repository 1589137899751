import {authPasswordFlowConfig} from './auth.config';
import {ChangeEmailModel, ConfirmActionModel, LogInModel, ResendConfirmActionModel, SignUpModel} from './auth.model';
import {Injectable} from '@angular/core';
import {BehaviorSubject, from, Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {OAuthService, UserInfo} from 'angular-oauth2-oidc';
import {AuthIdentityClaims} from '@nit-models';
import {environment} from '../../../environments/environment';
import {LbsForm} from '@nit-core/models/lbs-form';
import {KitForm} from '@nit-core/models/kit-form';

@Injectable()
export class AuthService {

  schoolId$ = new BehaviorSubject(0);
  private _schoolId: number = 0;
  private _childrenSchools: { userId, schoolId }[] = [];
  private _userId: string;

  get claims(): AuthIdentityClaims {
    return new AuthIdentityClaims(this.oauthService.getIdentityClaims());
  }

  get userId(): string {
    return this.claims?.sub ?? this._userId;
  }

  constructor(private readonly http: HttpClient, private readonly oauthService: OAuthService) {
    this._configureOAuth();
  }

  getUserSchoolId(userId?: string): number | null {
    if (!userId) {
      return this._schoolId;
    }

    const userSchool = this._childrenSchools.find(x => x.userId === userId);

    return userSchool?.schoolId ?? null;
  }

  setUserId(userId: string): void {
    this._userId = userId;
  }

  setOwnSchoolId(schoolId: number): void {
    this._schoolId = schoolId;
    this.schoolId$.next(schoolId);
  }

  resetChildrenSchools(): void {
    this._childrenSchools = [];
  }

  setChildSchoolId(userId: string, schoolId: number): void {
    this._childrenSchools.push({userId: userId, schoolId: schoolId});
  }

  login(model: LogInModel): Observable<UserInfo> {
    return from(this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(model.userName, model.password)) as Observable<UserInfo>;
  }

  logout(): Observable<void> {
    return from(this.oauthService.revokeTokenAndLogout());
  }

  signUp(model: SignUpModel): Observable<any> {
    return this.http.post(`${environment.authUrl}/api/v1/account/signup`, model);
  }

  signUpLbs(model: LbsForm): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/account/tests-register`, model);
  }

  signUpKit(model: KitForm): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/account/olympiad-register`, model);
  }

  confirmEmail(model: ConfirmActionModel): Observable<any> {
    return this.http.post(`${environment.authUrl}/api/v1/account/email-confirm`, model);
  }

  confirmAccount(model: ConfirmActionModel): Observable<any> {
    return this.http.post(`${environment.authUrl}/api/v1/account/confirm`, model);
  }

  confirmChangeEmail(model: ConfirmActionModel): Observable<any> {
    return this.http.post(`${environment.authUrl}/api/v1/account/change-email-confirm`, model);
  }

  changePassword(model: ConfirmActionModel): Observable<any> {
    return this.http.post(`${environment.authUrl}/api/v1/account/change-password`, model);
  }

  confirmChangeEmailForNotVerifiedUser(model: ConfirmActionModel): Observable<void> {
    return this.http.post<void>(`${environment.authUrl}/api/v1/account/change-email-and-confirm-account`, model);
  }

  resetPassword(model: ConfirmActionModel): Observable<any> {
    return this.http.post(`${environment.authUrl}/api/v1/account/reset-password`, model);
  }

  changeEmail(model: ChangeEmailModel): Observable<any> {
    return this.http.post(`${environment.authUrl}/api/v1/account/change-email`, model, {headers: this._getHeaders()});
  }

  resendConfirmation(model: ResendConfirmActionModel): Observable<any> {
    return this.http.post(`${environment.authUrl}/api/v1/account/resend-confirmation`, model);
  }

  private _configureOAuth(): void {
    this.oauthService.configure(authPasswordFlowConfig);
    this.oauthService.setStorage(localStorage);
  }

  private _getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${this.oauthService.getAccessToken()}`,
      'X-NIT-REQUESTED-USER-ID': this.userId,
      'X-NIT-REQUESTED-SCHOOL-ID': this._schoolId.toString(),
      'X-NIT-CLIENT': 'web',
      'X-NIT-CLIENT-VERSION': environment.version
    });
  }
}
