import {Component} from '@angular/core';

@Component({
  selector: 'nit-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})

export class NitLegendComponent {
}
