import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AcquaintanceTimesheet, ScoreCardsBody} from '@nit-core/models/score-cards';
import {HTTPOptions, RestService} from '@nit-core/services/global/http-services/rest.service';
import {ApiResponse} from '@nit-models';

@Injectable({providedIn: 'root'})

export class ScoreCardService extends RestService<AcquaintanceTimesheet> {

  constructor() {
    super(AcquaintanceTimesheet, '/score-cards', 'score-cards');
  }

  getScoreCards(filter: HTTPOptions, childId?: string, schoolId?: number): Observable<ApiResponse<AcquaintanceTimesheet>> {
    return this.httpGet<ApiResponse<AcquaintanceTimesheet>>(this.apiHref(), {
      query: this.normalizeParams(filter),
      asUserId: childId,
      asUserSchoolId: schoolId
    });
  }

  checkScoreCards(): Observable<ApiResponse<AcquaintanceTimesheet>> {
    return this.httpGetAll<AcquaintanceTimesheet>(`${this.apiHref()}/check`);
  }

  updateScoreCards(data: ScoreCardsBody): Observable<void> {
    return this.httpPut(this.apiHref(), data);
  }

  updateScoreAccept(data: { childId: string }, userId: string, schoolId: number): Observable<void> {
    return this.httpPut(`${this.apiHref()}/accept`, data, {asUserId: userId, asUserSchoolId: schoolId});
  }
}
