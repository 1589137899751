import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ModalComponent} from './modal.component';
import {DialogsModule} from '@progress/kendo-angular-dialog';
import {KendoDialogExtensionDirective} from '../../directives/kendo-dialog-extension.directive';
import {ButtonsModule} from '@nit-components';

@NgModule({
  imports: [
    CommonModule,
    DialogsModule,
    ButtonsModule
  ],
  declarations: [
    ModalComponent,
    KendoDialogExtensionDirective
  ],
  exports: [
    ModalComponent,
    KendoDialogExtensionDirective
  ]
})
export class ModalModule { }
