export class AchievementWishes {
  id: string;
  classId: string;
  userId: string;
  transferId: string;
  wish: string;
  type: WishType;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export enum WishType {
  ClassTeacher,
  Parent
}
