import {Pipe, PipeTransform} from '@angular/core';
import {FdsPositionsList, GeneralPositionsList} from '@nit-core/global/domain/enums-select';
import {SchoolSubject} from '@nit-core/models/profile';


@Pipe({
  name: 'positionName'
})
export class PositionNamePipe implements PipeTransform {
  fdsPositionsList = FdsPositionsList;
  generalPositionsList = GeneralPositionsList;

  transform(schoolsWithSubjects: SchoolSubject[], schoolId: number): string {
    const currentSchoolSubjects = schoolsWithSubjects.find(school => school.schoolId === schoolId);
    const generalPositions = currentSchoolSubjects?.positions.map(pos => {
      return this.generalPositionsList[pos].text;
    }) || [];
    const fdsPosition = currentSchoolSubjects?.fdsPosition != null ?
      this.fdsPositionsList[currentSchoolSubjects.fdsPosition].text + ' ШПД' : null;

    if (fdsPosition) {
      generalPositions.push(fdsPosition);
    }

    return generalPositions.length ? generalPositions.join(', ') : '-';
  }
}
