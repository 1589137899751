export class MaterialAttachment {
  id: string;
  fullName: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class AssignAttachment {
  groupId: string;
  subject: string;
  classId: string;
  attachmentId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}


export class FileId {
  fileId?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
