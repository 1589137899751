<button type="button" class="transfer-btn">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_4868:5540)">
      <path d="M14.6237 2.72388L12.0963 0.176619C11.8639 -0.0576012 11.486 -0.0590075 11.2519 0.173244C11.0179 0.405495 11.0163 0.783404 11.2485 1.01744L13.357
           3.14264L11.2501 5.24952C11.0169 5.48256 11.0169 5.86068 11.2501 6.09372C11.3668 6.21034 11.5194 6.26865 11.6722 6.26865C11.8251 6.26865 11.9779 6.21034
            12.0945 6.09394L14.6219 3.56654C14.8545 3.33404 14.8551 2.95732 14.6237 2.72388Z" fill="#7F89A7"/>
      <path d="M14.2195 2.54712H1.78157C1.45182 2.54712 1.18457 2.81437 1.18457 3.14412V7.76106C1.18457 8.09081 1.45182 8.35806 1.78157 8.35806C2.11133 8.35806
           2.37858 8.09081 2.37858 7.76106V3.74116H14.2194C14.5492 3.74116 14.8164 3.47391 14.8164 3.14415C14.8165 2.8144 14.5492 2.54712 14.2195 2.54712Z" fill="#7F89A7"/>
      <path d="M2.62445 12.8573L4.73134 10.7506C4.96437 10.5175 4.96459 10.1396 4.73134 9.90638C4.49812 9.67313 4.11999 9.67313 3.88696 9.90638L1.35957
           12.4336C1.12694 12.666 1.12613 13.0427 1.35779 13.2762L3.88518 15.8234C4.00199 15.9411 4.15562 16 4.30905 16C4.46109 16 4.61315 15.9422 4.72956
            15.8268C4.96359 15.5946 4.96518 15.2167 4.73293 14.9825L2.62445 12.8573Z" fill="#7F89A7"/>
      <path d="M14.1996 7.44263C13.8698 7.44263 13.6025 7.70988 13.6025 8.03963V12.2586H1.78157C1.45182 12.2586 1.18457 12.5258 1.18457 12.8556C1.18457
           13.1853 1.45182 13.4526 1.78157 13.4526H14.1998C14.5295 13.4526 14.7968 13.1853 14.7966 12.8556V8.03966C14.7966 7.70991 14.5293 7.44263 14.1996 7.44263Z" fill="#7F89A7"/>
    </g>
    <defs>
      <clipPath id="clip0_4868:5540">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</button>
