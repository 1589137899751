import {Component, Input} from '@angular/core';
import {CalendarView} from '@progress/kendo-angular-dateinputs';

@Component({
  selector: 'nit-daterange-close-extension',
  templateUrl: './daterange-close-extension.component.html',
  styleUrls: ['./daterange-close-extension.component.scss']
})
export class DaterangeCloseExtensionComponent {
  @Input() topView: CalendarView = 'decade';
  @Input() bottomView: CalendarView;
}
