import {AccessibleOlympiadPaymentStatus} from '@nit-core/global/domain/enums';

export class ChildAccessibleOlympiad {
  childId: string;
  olympiadsForChildren: AccessibleOlympiad[];

  constructor(item: any = {}) {
    Object.assign(this, item);

    this.olympiadsForChildren = item.olympiadsForChildren ? item.olympiadsForChildren.map(olympiad => new AccessibleOlympiad(olympiad)) : [];
  }
}

export class AccessibleOlympiad {
  id: string;
  name: string;
  subject: string;
  startDate: string;
  status: AccessibleOlympiadPaymentStatus;
  price: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class OlympiadValidatePaymentRequest {
  olympiadId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class OlympiadPaymentResponse {
  data: string;
  signature: string;
  url: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class OlympiadPaymentFormRequest {
  olympId: string;
  userName: string;
  olympiadName: string;
  olympiadSubject: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}