import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormModule} from '@nit-core/modules';
import {DropdownComponent} from './dropdown.component';

@NgModule({
  declarations: [DropdownComponent],
  imports: [
    CommonModule,
    FormModule
  ],
  exports: [DropdownComponent]
})
export class DropdownModule { }
