import {ChangeDetectorRef, NgZone, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {DateTransformService} from '@nit-services';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'timeView'
})
export class TimeViewPipe implements PipeTransform, OnDestroy {

  private _timer: number;
  constructor(private readonly _dateTransformService: DateTransformService,
              private readonly _changeDetectorRef: ChangeDetectorRef,
              private readonly _ngZone: NgZone,
              private readonly _datePipe: DatePipe) {}

  ngOnDestroy(): void {
    this._removeTimer();
  }

  transform(value: Date): string
  {
    this._removeTimer();
    const d = new Date(this._dateTransformService.transformDate(value));
    const currentDay = new Date();
    const seconds = Math.round(Math.abs((currentDay.getTime() - d.getTime())/1000));
    const timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this._getSecondsUntilUpdate(seconds) *1000;
    this._timer = this._ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this._ngZone.run(() => this._changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }

      return null;
    });
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    if (hours < 24) {
      return this._datePipe.transform(value, 'HH:mm');
    } else {
      return this._datePipe.transform(value, 'dd.MM.yyyy');
    }
  }

  private _removeTimer(): void {
    if (this._timer) {
      window.clearTimeout(this._timer);
      this._timer = null;
    }
  }

  private _getSecondsUntilUpdate(seconds: number): number {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) {
      return 2;
    } else if (seconds < hr) {
      return 30;
    } else if (seconds < day) { // less than a day, update every 5 mins
      return 300;
    } else {
      return 3600;
    }
  }
}
