import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';
import {ColumbiaUniversityResponse} from '@nit-core/models';

@Injectable({providedIn: 'root'})
export class ColumbiaUniversityCourseService extends RestService<ColumbiaUniversityResponse> {
  constructor() {
    super(ColumbiaUniversityResponse, '/columbia-university-courses', 'columbia-university-courses');
  }

  sendNavigationInfo(): Observable<ColumbiaUniversityResponse> {
    return this.httpPost(`${this.apiHref()}/views`, {});
  }
}
