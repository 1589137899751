import {AttachedFile} from '@nit-core/models/class-material';

export class EducationPlan {
  day: Date;
  lessonContent: string;
  homework: string;
  firstFreeNumberOfLesson: string;
  firstFreePlanDate: string;
  homeworkAttachments: AttachedFile[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.day = item.day ? new Date(item.day) : null;
    this.homeworkAttachments = item.homeworkAttachments ? item.homeworkAttachments.map((x: any) => new AttachedFile(x)) : [];
  }
}
