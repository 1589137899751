export class DpaSubject {
  classNumber: number;
  subjectId: string;
  requiresColumn: boolean;
  subject?: string;
  id?: string;
  isFds?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class DpaClassData {
  isEdit: boolean;
  subjectsIds: string[];
  subjects: DpaSubject[];
  classNumber: number;
  subjectsRequiresColumnIds?: string[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.subjects = item.subjects ? item.subjects.map(x => new DpaSubject(x)) : [];
  }
}