import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Achievement} from '@nit-core/models/achievement';

@Injectable({providedIn: 'root'})
export class GeneralAchievementService extends RestService<Achievement> {
  constructor() {
    super(Achievement, '/general-achievements', 'general-achievements');
  }
}
