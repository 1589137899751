import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AvatarUploadFileComponent} from './avatar-upload-file.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    AvatarUploadFileComponent
  ],
  exports: [
    AvatarUploadFileComponent
  ]
})
export class AvatarUploadFileModule {}
