import {switchMap, catchError} from 'rxjs/operators';
import {Pipe, PipeTransform} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {of, Observable} from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';
@Pipe({
  name: 'authImage'
})

export class AuthImagePipe implements PipeTransform {

  constructor(private readonly http: HttpClient,
              private readonly oauthService: OAuthService) {
  }

  transform(src: string, userId?: string, path?: string): Observable<string> {
    if (!src) {
      return of('');
    }
    let headers: HttpHeaders = new HttpHeaders({Authorization: `Bearer ${this.oauthService.getAccessToken()}`});
    if (userId && path === 'school') {
      headers = headers.set('X-NIT-REQUESTED-USER-ID', userId);
    }

    return this.http.get(src, {
      responseType: 'blob',
      headers: headers
    })
      .pipe(
        switchMap(x => of(URL.createObjectURL(x))),
        catchError(err => {
          if (err.status === 204) {
            return of('');
          }
        })
      );
  }
}
