import {ProfileNotification} from '@nit-models';
import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ProfileNotificationService extends RestService<ProfileNotification> {
  constructor() { super(ProfileNotification, '/profile/notifications', 'notifications'); }

  updateStatus(id: string): Observable<any> {
    return this.httpPut(`${this.apiHref()}/${id}/read`, null);
  }

  readAll(): Observable<void> {
    return this.httpPut(`${this.apiHref()}/readall`, {});
  }
}
