import {ChatMedia} from './chat-file';
import {ChannelUser} from '@nit-core/models/channel';

export class Dialog {
  admins: any[];
  blocked: any[];
  class: {id: string; name: string;};
  createdAt: string;
  lastMsg;
  muted;
  name: string;
  owner: any;
  pinned: PinnedChat;
  pinnedMessage: any;
  school: number;
  semester: number;
  type: string;
  unread: number;
  updatedAt: string;
  users: any[];
  id: string;
  method: string;
  initials?: string;
  totalUsers?: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.initials = this.name ? this._getInitials(this.name) : '';
    this.pinned = item.pinned ? new PinnedChat(item.pinned) : new PinnedChat({});
  }

  private _getInitials(name: string): string {
    const words = name?.split(' ');
    if (words?.length > 1) {
      return (words[0][0] + words[words.length - 1][0]).toUpperCase();
    }

    return words[0].slice(0, 2);
  }
}

export class NewMessage {
  text: string;
  files?: ChatMedia[];
  replyTo?: string;
  targetId: string;
  targetType: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.files = item.files ? item.files.map((x: any) => new ChatMedia(x)) : [];
  }
}

export class ChatMessage {
  createdAt: Date;
  updatedAt?: Date;
  room: string;
  school: number;
  user: ChannelUser;
  text: string;
  readBy?: string[];
  _id: string;
  mentions?: any;
  replyTo?: any;
  localId?: string;
  important: boolean;
  original_id?: string;
  userId?: string;
  files?: ChatMedia[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.createdAt = item.createdAt ? new Date(item.createdAt) : null;
    this.updatedAt = item.updatedAt ? new Date(item.updatedAt) : null;
    this.user = item.user ? new ChannelUser(item.user) : null;
  }
}

export class PinnedChat {
  createdAt: Date;
  room: string;
  user: string;
  _id: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.createdAt = item.createdAt ? new Date(item.createdAt) : null;
  }
}

export class SearchMessage {
  original_id: string;
  userId: string;
  _id?: string;
  user?: ChannelUser;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
