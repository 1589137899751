import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {LearningOutcomesCharacteristics} from '@nit-core/models/practice-journal';

@Injectable({
  providedIn: 'root'
})
export class CompetenceService extends RestService<LearningOutcomesCharacteristics> {
  constructor() {
    super(LearningOutcomesCharacteristics, '/competences', 'competences');
  }
}
