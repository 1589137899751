<div *ngIf="isOpened"
     class="modal-window"
     [kendoDialogExtension]="modal"
     [isOpened]="isOpened"
     [closeOnEsc]="closeOnEsc"
     [closeOutside]="closeOutside"
     (closed)="close($event)">
  <kendo-dialog #modal
                [class.dialog]="isRegistrationForOlympics"
                [ngClass]="['title-align-' + titleAlign, 'title-size-' + titleSize, 'padding-size-' + paddingSize]"
                [width]="width ? width : '90%'"
                [height]="height ?? 'auto'"
                [minHeight]="minHeight"
                [maxWidth]="maxWidth"
                (close)="close($event)">
    <kendo-dialog-titlebar *ngIf="simple || title"
                           [class.simple]="simple"
                           [title]="title">
      <span *ngIf="!simple" class="title" style="padding: 0">
        {{title}}
      </span>
    </kendo-dialog-titlebar>

    <p *ngIf="subTitle" class="sub-title">
      {{subTitle}}
    </p>

    <ng-content></ng-content>

    <div *ngIf="!isPopup"
         class="button-wrapper"
         [ngStyle]="{width: buttonWidth + 'px'}">
      <nit-button *ngIf="displaySubmitBtn"
                  [type]="submitBtnType"
                  [isDisabled]="disableSubmitBtn"
                  (clicked)="submit()">
        <img *ngIf="displaySubmitIcon && submitIconPath"
             class="icon"
             alt="submit icon"
             [src]="submitIconPath">
        {{submitBtnText}}
      </nit-button>

      <nit-button *ngIf="displayCancelBtn"
                  type="cancel"
                  (clicked)="cancel()">
        {{cancelBtnText}}
      </nit-button>
    </div>
  </kendo-dialog>
</div>

