import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {AchievementWishes} from '@nit-core/models/achievement-wishes';

@Injectable({providedIn: 'root'})
export class AchievementWishService extends RestService<AchievementWishes> {
  constructor() {
    super(AchievementWishes, '/achievement-wishes', 'achievement-wishes');
  }
}
