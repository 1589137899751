import {Decision} from '@nit-core/models/decision';

export class AcquaintanceTimesheet {
  decision: Decision;
  parents: string[];
  parentsAreAquinted: boolean;
  parentsHaveAccess: boolean;
  userId: string;
  userName: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.decision = item.decision ? new Decision(item.decision) : null;
  }
}

export class ScoreCardsBody {
  classId: string;
  userIds?: string[];
  allowForAll: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
