export class DayOffs {
  id: string;
  name: string;
  dateFrom: string;
  dateTo: string;
  type: number;
  color?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}


export class VacationCalendar {
  id?: string;
  type: number | string;
  date?: Date;
  startDate?: Date;
  endDate?: Date;
  name?: string;
  color?: string;
  hideName?: boolean;
  hideOneDate?: boolean;
  disabledType?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.date = item.date ? new Date(item.date) : null;
    this.startDate = item.startDate ? new Date(item.startDate) : null;
    this.endDate = item.endDate ? new Date(item.endDate) : null;
  }
}
