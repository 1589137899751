import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timestamp'
})
export class TimestampPipe implements PipeTransform {
  transform(value: Date): number {
    return value.getTime();
  }
}
