import {Pipe, PipeTransform} from '@angular/core';
import {Profile} from '@nit-core/models';

@Pipe({
  name: 'subjects'
})
export class SubjectsPipe implements PipeTransform {
  transform(dataItem: Profile, schoolId: number): string {
    return dataItem.subjects.find(schoolWithSubjects =>
      schoolWithSubjects.schoolId === schoolId)?.subjects.map(subject => subject.name).join(', ');
  }
}
