import {Component} from '@angular/core';
import {UserService} from '@nit-services';

@Component({
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})

export class NotFoundComponent {
  isParent: boolean = false;
  isSchoolActive: boolean;

  constructor(private readonly _userService: UserService) {
    this._userService.currentUser$.subscribe(res => {
      if (res) {
        this.isParent = !res.isChild;
      }
    });
  }
}
