import {ComponentRef, Directive, HostListener, Input, ViewContainerRef} from '@angular/core';
import {TooltipComponent} from '../../../private/components/tooltip/tooltip.component';

@Directive({
  selector: '[NitTooltip]',
  exportAs: 'nitTooltip'
})
export class NitTooltipDirective {
  @Input() bottomText: string | number;
  @Input() topText: string | number;
  @Input() title: string | number = '';
  @Input() horizontalPosition: 'left' | 'right';
  @Input() verticalPosition: 'top' | 'bottom' = 'bottom';
  @Input() maxWidth: number = 300;
  @Input() minWidth: number = 200;
  @Input() arrowLeft: boolean = false;

  private _contentCmpRef: ComponentRef<TooltipComponent>;
  private _isClear: boolean = true;

  constructor(private readonly _viewContainerRef: ViewContainerRef) { }

  @HostListener('mouseenter', ['$event']) onMouseHover(event: MouseEvent): void {
    if (!this._isClear) {
      return;
    }

    this._buildComponent(event);
  }

  @HostListener('mouseleave', ['$event']) hideTooltip(): void {
    this.closeScroll();
  }

  @HostListener('window:scroll', []) scrollClose(): void {
    this.closeScroll();
  }

  closeScroll(): void {
    if (this._contentCmpRef) {
      this._contentCmpRef.destroy();
      this._isClear = true;
    }
  }

  private _buildComponent(options: MouseEvent): void {
    const data = {
      topText: this.topText,
      title: this.title,
      bottomText: this.bottomText,
      x: options.clientX,
      y: options.clientY,
      maxWidth: this.maxWidth + 'px',
      minWidth: this.minWidth + 'px'
    };

    if (data.topText || data.bottomText || data.title) {
      this._contentCmpRef = this._viewContainerRef.createComponent(TooltipComponent);
      (this._contentCmpRef.instance as any).options = options;
      (this._contentCmpRef.instance as any).data = data;
      (this._contentCmpRef.instance as any).horizontalPosition = this.horizontalPosition;
      (this._contentCmpRef.instance as any).verticalPosition = this.verticalPosition;
      (this._contentCmpRef.instance as any).arrowLeft = this.arrowLeft;
      this._isClear = false;
    }
  }
}
