import {PipeTransform, Pipe} from '@angular/core';

@Pipe({
  name: 'url'
})
export class UrlPipe implements PipeTransform {
  readonly http = 'http://';
  readonly https = 'https://';

  transform(value: string): string {
    if (value.startsWith(this.http) ) {
      return value.substr(this.http.length);
    } else if (value.startsWith(this.https) ) {
      return value.substr(this.https.length);
    } else {
      return value;
    }
  }
}
