import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {distinctUntilChanged, filter, map, startWith} from 'rxjs/operators';
import {FeathersService} from '@nit-services';
import {ChatActiveCall} from '@nit-core/models/chatCall';

@Component({
  selector: 'nit-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  breadcrumbs: IBreadCrumb[];
  activeCall: ChatActiveCall | null;

  constructor(
              private readonly _activatedRoute: ActivatedRoute,
              public router: Router,
              private readonly _feathersService: FeathersService
  ) {
    this.getActiveCall();
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      startWith(this.router),
      distinctUntilChanged(),
      map(() => this.buildBreadCrumb(this._activatedRoute.root)))
      .subscribe(x => this.breadcrumbs = x);
  }

  getActiveCall(): void {
    this._feathersService.activeCall$.subscribe((res) => {
      this.activeCall = res;
    });
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
    let label = route.routeConfig?.data ? route.routeConfig.data.breadcrumb : '';
    let path = route.routeConfig?.data ? route.routeConfig.path : '';

    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');

    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      const pathArray = path.split('/').filter(s => s.includes(':'));
      pathArray.forEach((x, index) => {
        path = path.replace(pathArray[index], route.snapshot.params[pathArray[index].split(':')[1]]);
      });
      if (paramName === 'academicYearId' || paramName === 'entityId' || paramName === 'courseId') {
        label = route.snapshot.data.breadcrumb;
      } else {
        label = label === '' ? route.snapshot.params[paramName] : label;
      }
    }
    const nextUrl = path ? `${url}/${path}` : url;
    const breadcrumb: IBreadCrumb = {
      label,
      url: nextUrl,
    };

    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return newBreadcrumbs;
  }
}

export interface IBreadCrumb {
  label: string;
  url: string;
}
