import {WorkContentType} from '@nit-core/global/domain/enums';

export class WorkContent {
  id: string;
  date: Date;
  content: string;
  classId: string;
  groupId: string;
  subjectName: string;
  teacherFullName: string;
  isCompleted: boolean;
  type: WorkContentType;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.date = item?.date ? new Date(item.date) : null;
  }
}
