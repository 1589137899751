import {Component} from '@angular/core';

@Component({
  selector: 'nit-button-remove',
  templateUrl: './button-remove.component.html',
  styleUrls: ['./button-remove.component.scss']
})

export class ButtonRemoveComponent {

}
