import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  FormGroupComponent,
  InputContainerComponent,
  NitFieldWrapperComponent,
  NitLegendComponent
} from '../forms/components';
import {ButtonsModule} from '../components/buttons/buttons.module';
import {PhoneInputModule} from '../components/phone-input/phone-input.module';
import {TrimInputDirective} from '@nit-core/directives/trim-input.directive';
import {NitTooltipDirectiveModule} from '@nit-core/directives/tooltip/nit-tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    ButtonsModule,
    NitTooltipDirectiveModule
  ],
  declarations: [
    NitFieldWrapperComponent,
    NitLegendComponent,
    FormGroupComponent,
    InputContainerComponent,
    TrimInputDirective
  ],
  exports: [
    NitFieldWrapperComponent,
    NitLegendComponent,
    FormsModule,
    ReactiveFormsModule,
    FormGroupComponent,
    InputContainerComponent,
    ButtonsModule,
    PhoneInputModule,
    TrimInputDirective
  ]
})
export class FormModule { }
