import {Injectable} from '@angular/core';
import {AddHomeworkAnswer, HomeworkImplementation} from '@nit-core/models/homework';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';
import {Filter} from '@nit-core/models/filter';
import {ApiResponse} from '@nit-models';
import {UserHomework} from '@nit-core/models/profile';

@Injectable({
  providedIn: 'root'
})

export class HomeworkImplementationService extends RestService<HomeworkImplementation> {
  constructor() {
    super(HomeworkImplementation, '/homework-implementations', 'homework-implementations');
  }

  addHomework(data: AddHomeworkAnswer): Observable<void> {
    return this.httpPost(this.apiHref(), data);
  }

  getHomeworkResults(filter: Filter): Observable<ApiResponse<UserHomework>> {
    return this.httpGet(this.apiHref(), {query: filter});
  }

  checkHomework(id: string, data?: boolean): Observable<void> {
    return this.httpPut(`${this.apiHref()}/${id}/check`, data);
  }

  getStudentHomework(id: string, childId?: string, schoolId?: number): Observable<HomeworkImplementation> {
    return this.httpGet(`${this.apiHref()}/${id}`, {
      factory: HomeworkImplementation,
      asUserId: childId,
      asUserSchoolId: schoolId
    });
  }
}
