import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'nit-button-filter',
  template: `
    <button (click)="clicked.emit()" class="btn-filter">
      <span>Фільтри</span>
    </button>`,
  styleUrls: ['./button-filter.component.scss']
})
export class ButtonFilterComponent {
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
}
