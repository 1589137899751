<div #dropdownSelect
     class="dropdown-container"
     [ngStyle]="{'height': height + 'px'}"
     [class.border]="border">
  <div class="select"
       NitTooltip
       horizontalPosition="right"
       verticalPosition="top"
       [topText]="selectedOption?.[textField] || defaultItem?.[textField]"
       [class.disabled]="disabled"
       (click)="toggleDropdown()">
    <div>
      <span *ngIf="(selectedOption && selectedOption?.[textField] !== defaultItem?.[textField])
              || !defaultItem?.[textField]"
            class="name"
            [ngStyle]="{'max-width': (selectedOption?.['isFds'] ? '90%' : '100%')}">
        {{textField ? selectedOption?.[textField] : selectedOption}}
      </span>

      <span *ngIf="defaultItem?.[textField] && (!selectedOption?.[textField]
              || selectedOption?.[textField] === defaultItem?.[textField])"
            class="name">
        {{defaultItem?.[textField]}}
      </span>

      <span *ngIf="selectedOption?.isFds"
            class="fds-label">
        ШПД
      </span>
    </div>
    <img src="/assets/icons/arrow-down-dark-grey.svg" alt="Arrow down">
  </div>
</div>
