import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChildren
} from '@angular/core';

@Component({
  selector: 'nit-dropdown-filter-options',
  templateUrl: './dropdown-filter-options.component.html',
  styleUrls: ['./dropdown-filter-options.component.scss']
})
export class DropdownFilterOptionsComponent implements OnInit, AfterViewInit {
  @ViewChildren('optionElement') optionDivs: QueryList<ElementRef<HTMLDivElement>>;
  @Input() defaultText: string = '';
  @Input() defaultValue: any;
  @Output() selected: EventEmitter<Record<string, any>> = new EventEmitter<Record<string, any>>();

  valueField: string;
  textField: string;
  options: Record<string, any>[];
  dropdownSelect: ElementRef;
  selectedOption: Record<string, any>;
  defaultItem: Record<string, any>;
  hasScroll: boolean = true;
  noDefaultItem: boolean = false;

  private readonly _elementRef = inject(ElementRef);
  private readonly _renderer: Renderer2 = inject(Renderer2);

  ngOnInit(): void {
    this.defaultItem = {
      [this.textField]: this.defaultText,
      [this.valueField]: this.defaultValue || ''
    };

    if (!this.selectedOption) {
      this.selectedOption = this.defaultItem;
    }
  }

  ngAfterViewInit(): void {
    const positionsSelectWidth = this.dropdownSelect.nativeElement.offsetWidth + 'px';
    const cellCoords = this.dropdownSelect.nativeElement.getBoundingClientRect();

    const xPosition = cellCoords.left + 'px';
    const yPosition = cellCoords.bottom + 'px';
    this._renderer.setStyle(this._elementRef.nativeElement, 'position', 'fixed');
    this._renderer.setStyle(this._elementRef.nativeElement, 'top', yPosition);
    this._renderer.setStyle(this._elementRef.nativeElement, 'left', xPosition);
    this._renderer.setStyle(this._elementRef.nativeElement, 'z-index', '1');
    this._renderer.setStyle(this._elementRef.nativeElement, 'width', positionsSelectWidth);

    this._scrollToChosen();
  }

  choseOption(option: Record<string, any>): void {
    this.selectedOption = option;
    this.selected.emit(option);
  }

  private _scrollToChosen(): void {
    const options = this.optionDivs.toArray();
    const targetElement = options.find((option: ElementRef<HTMLDivElement>) =>
      option.nativeElement.classList.contains('selected'));

    targetElement?.nativeElement?.scrollIntoView({
      behavior: 'auto',
      block: 'end',
      inline: 'center'
    });
  }
}
