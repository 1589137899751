import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';
import {BriefingPlan} from '@nit-core/models/briefing-plan';

@Injectable({
  providedIn: 'root'
})
export class DiscourceContentService extends RestService<BriefingPlan> {

  constructor() { super(BriefingPlan, '/discource-journals/work-content', 'discource-journals/work-content'); }

  createDiscourceContent(journalId: string, data: BriefingPlan): Observable<any> {
    return this.httpPost<BriefingPlan>(`${this.apiHref()}/${journalId}`, data);
  }
}
