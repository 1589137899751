import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Request, RejectRequest} from '@nit-models';
import {ChangeMarkRequest} from '@nit-core/models/journal';
import {PaymentData} from '@nit-core/models/payment';

@Injectable({providedIn: 'root'})
export class RequestService extends RestService<Request> {

  newRequests$: BehaviorSubject<Request[]> = new BehaviorSubject<Request[]>([]);

  constructor() { super(Request, '/requests', 'requests'); }

  approve(id: string): Observable<void> {
    return this.httpPut(`${this.apiHref()}/${id}/approve`, null);
  }

  reject(id: string, data: RejectRequest): Observable<void> {
    return this.httpPut(`${this.apiHref()}/${id}/reject`, data);
  }

  getSecureImage(url: string): Observable<Blob> {
    return this.httpGetBlob(url, {});
  }

  sendBatchGradesAndReason(data: { models: ChangeMarkRequest[] }): Observable<{results: string[]}> {
    return this.httpPost(`${this.apiHref()}/batch`, data);
  }

  getPaymentData(id: string): Observable<PaymentData> {
    return this.httpGet(`${this.apiHref()}/${id}/url`, null);
  }

  updateRequestsList(id: string): void {
    const newRequestsList = this.newRequests$.value.filter(request => request.id !== id);
    this.newRequests$.next(newRequestsList);
  }
}
