import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {PublishBody, ScheduledLesson} from '@nit-core/models';
import {Observable, from,mergeMap, toArray} from 'rxjs';
import {ScheduledLessonPayload} from '@nit-core/models/add-vacancy';

@Injectable({
  providedIn: 'root'
})
export class ScheduledLessonService extends RestService<ScheduledLesson> {
  constructor() {
    super(ScheduledLesson, '/scheduled-lessons', 'scheduled-lesson');
  }

  revertScheduledLesson(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiHref()}/${id}/revert`, {
      headers: this.aliasHeaders('scheduled-lesson', this.getHeaders()),
      body: this.body(id)
    });
  }

  createScheduledLessons(scheduledLessonsList: ScheduledLessonPayload[]): Observable<ScheduledLesson[]> {
    return from(scheduledLessonsList).pipe(
      mergeMap(scheduledLesson => this.createScheduleLesson(scheduledLesson)),
      toArray());
  }

  createScheduleLesson(scheduledLesson: ScheduledLessonPayload): Observable<ScheduledLesson> {
    return this.http.post(this.apiHref(), scheduledLesson, {
      headers: this.getHeaders()
    }) as Observable<ScheduledLesson>;
  }

  publishSchedule(scheduledLesson: PublishBody): Observable<any> {
    return this.http.post(`${this.apiHref()}/publish`, scheduledLesson, {headers: this.getHeaders()});
  }
}
