import {GridModule} from '@progress/kendo-angular-grid';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {DialogsModule} from '@progress/kendo-angular-dialog';
import {DateInputsModule} from '@progress/kendo-angular-dateinputs';
import {PopupModule} from '@progress/kendo-angular-popup';
import {LayoutModule} from '@progress/kendo-angular-layout';
import {InputsModule} from '@progress/kendo-angular-inputs';
import {DropDownsModule, DropDownListModule} from '@progress/kendo-angular-dropdowns';
import {NotificationModule} from '@progress/kendo-angular-notification';
import {IntlModule} from '@progress/kendo-angular-intl';
import {PopoverModule, TooltipModule} from '@progress/kendo-angular-tooltip';
import {SortableModule} from '@progress/kendo-angular-sortable';
import {LabelModule} from '@progress/kendo-angular-label';
import {UploadsModule} from '@progress/kendo-angular-upload';
import {MenusModule} from '@progress/kendo-angular-menu';
import {GridBulkDirective} from '../directives/grid-bulk.directive';
import {KendoDatePickerMessagesModule} from '@nit-core/components/kendo-datepicker-messages/kendo-datepicker-messages.module';
import {DaterangeCloseExtensionModule} from '@nit-core/components/daterange-close-extension/daterange-close-extension.module';
import {ChartsModule} from '@progress/kendo-angular-charts';
import {ScrollViewModule} from '@progress/kendo-angular-scrollview';
import {ProgressBarModule} from '@progress/kendo-angular-progressbar';
import {ExpansionPanelModule} from '@progress/kendo-angular-layout';

@NgModule({
  imports: [],
  declarations: [
    GridBulkDirective
  ],
  exports: [
    CommonModule,
    GridModule,
    ButtonsModule,
    DialogsModule,
    DateInputsModule,
    PopupModule,
    PopoverModule,
    LayoutModule,
    InputsModule,
    DropDownsModule,
    NotificationModule,
    IntlModule,
    TooltipModule,
    SortableModule,
    LabelModule,
    UploadsModule,
    DropDownListModule,
    GridBulkDirective,
    MenusModule,
    KendoDatePickerMessagesModule,
    DaterangeCloseExtensionModule,
    ChartsModule,
    ScrollViewModule,
    ProgressBarModule,
    ExpansionPanelModule
  ]
})
export class KendoModule {
}
