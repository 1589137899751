import {AbstractControl} from '@angular/forms';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {Subject} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'nit-field-wrapper',
  styleUrls: ['./field-wrapper.component.scss'],
  templateUrl: './field-wrapper.component.html',
  encapsulation: ViewEncapsulation.None
})
export class NitFieldWrapperComponent implements OnInit {
  @ViewChild('input') input: ElementRef;
  @Input() infoLabel: string;
  @Input() control: AbstractControl;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() label: string;
  @Input() icon: 'mail' | 'password' | '' = '';
  @Input() showHidePassword: boolean = false;
  @Input() formatTitle: string = 'Формати і розмір файлу';
  @Input() isFormat: boolean = false;
  @Input() formatLabel: string;
  @Input() showError: boolean = true;
  @Input() isHighError: boolean = false;
  @Input() showFormatLabel: boolean = true;
  @Input() position: 'right' | 'left' = 'right';

  showPassword: boolean = true;
  icons = {
    mail: '/assets/icons/auth/mail.svg',
    password: '/assets/icons/auth/password.svg'
  };

  messages: Subject<string[]> = new Subject<string[]>();

  ngOnInit(): void {
    if (this.control) {
      this.handleValidationMessages();
    }
  }

  togglePassword(): void {
    this.showPassword = !this.showPassword;
    this.input.nativeElement.lastChild.setAttribute('type', this.showPassword ? 'password' : 'text');
  }

  handleValidationMessages(): void {
    this.control.statusChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        const messages = this.control.errors
          ? Object.keys(this.control.errors).reduce((arr, errKey) => {
            return this.control.errors[errKey].message
              ? [...arr, this.control.errors[errKey].message] : arr;
          }, []) : [];
        this.messages.next(messages);
      });
  }
}
