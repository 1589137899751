import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DynamicComponentStateService {
  dynamicComponentState$: Observable<any>;
  EMPTY = {};
  dynamicComponentStateSubject: BehaviorSubject<any>;
  dynamicComponentState: any = null;
  constructor() {
    this.dynamicComponentStateSubject = new BehaviorSubject(this.dynamicComponentState) as BehaviorSubject<any>;
    this.dynamicComponentState$ = this.dynamicComponentStateSubject.asObservable();
  }

  updateDynamicComponentState(component: any): void {
    this.dynamicComponentState = component;
    this.dynamicComponentStateSubject.next(this.dynamicComponentState);
  }
}
