import {PaymentStatus} from '@nit-core/global/domain/enums';
import {Class} from '.';
import {Child} from './request';
import {Subject} from './subject';

export class Payment {
  id?: string;
  name: string;
  displayName?: string;
  amount: number;
  paymentSystemId: string;
  type: number;
  notificationDate?: Date;
  status?: PaymentStatus;
  isChecked?: boolean;
  payed?: number;
  monthlySettings?: MonthlySetting[];
  users?: Payer[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.notificationDate = item.notificationDate ? new Date(item.notificationDate) : undefined;
    this.monthlySettings = !item.monthlySettings?.length ? [] :
      item.monthlySettings.map(setting => new MonthlySetting(setting));
    this.users = !item.users.length ? [] :
      item.users.map(user => new Payer(user));

    if (this.users.length) {
      this.payed = this.users.reduce((acc, user) => {
        if (user.payed) {
          acc++;
        }

        return acc;
      }, 0);
    }

  }
}

export class MonthlySetting {
  month: number;
  notificationDate: Date;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.notificationDate = item.notificationDate ? new Date(item.notificationDate) : undefined;
  }
}

export class Payer {
  id: string;
  fullName: string;
  payed: 0 | 1;
  className?: string;
  classId?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ClassWithPayers extends Class {
  payers?: any;

  constructor(item: any = {}) {
    super(item);
    Object.assign(this, item);
  }
}
export class PaymentData {
  attachments: string;
  child: Child;
  createdAt: Date;
  data: string;
  mark: string;
  school: number;
  sender: string;
  signature: string;
  subjects: Subject[];
  url: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.createdAt = item.createdAt ? new Date(item.createdAt) : undefined;
    this.child = item.child ? new Child(item.child) : undefined;
    this.subjects = item.subjects ? item.subjects.map(subject => new Subject(subject)) : [];
  }
}
