<ng-container *ngIf="canUpload; then activeAvatar; else viewAvatar"></ng-container>

<ng-template #activeAvatar>
  <div class="avatar-container hover-blur"
       [ngClass]="[isValid ? (isDefault ? 'default' : 'valid') : 'invalid', size]"
       [ngStyle]="{'background-image': (image ? 'url(' + image  + ')':'')}"
       (dragover)="dragover($event)" (dragleave)="dragleave($event)" (drop)="drop($event)">
    <div *ngIf="!image" class="avatar-abr">
      {{ defaultText }}
    </div>
    <div class="overlay-change overlay" [class.invalid]="!isValid">
      <div class="text-change" (click)="fileInput.click()">
        {{ image ? 'Змінити фото' : 'Завантажити фото' }}
      </div>
    </div>
    <div class="overlay-delete overlay" [class.invalid]="!isValid">
      <div class="text-delete" (click)="removeImage()">
        {{'Видалити фото'}}
      </div>
    </div>
    <input #fileInput hidden type="file" (change)="uploadFile($event.target['files'][0])">
  </div>
</ng-template>
<ng-template #viewAvatar>
  <div class="avatar-container view"
       [ngClass]="size"
       [style.background-image]="'url(' + image + ')'">
    <div *ngIf="!image" class="avatar-abr">
      {{ defaultText }}
    </div>
  </div>
</ng-template>
<div class="error-message">{{ validationMessage }}</div>
