import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotFoundComponent} from './not-found.component';
import {RouterModule} from '@angular/router';
import {NoDataModule} from '@nit-core/components';
import {EndSchoolYearModule} from '../../../private/components/end-school-year/end-school-year.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NoDataModule,
    EndSchoolYearModule
  ],
  declarations: [
    NotFoundComponent
  ],
  exports: [
    NotFoundComponent
  ]
})

export class NotFoundModule {
}
