<a *ngIf="link; else simpleButton" [routerLink]="link">
  <ng-container *ngTemplateOutlet="simpleButton"></ng-container>
</a>

<ng-template #simpleButton>
  <button type="button"
          [class.secondary]="type === 'secondary'"
          [class.new-child]="type === 'new-child'"
          [class.add-domain]="type === 'add-domain'"
          [class.info]="type === 'info'"
          [class.cancel]="type === 'cancel'"
          [class.delete]="type === 'delete'"
          [class.cancel-edit]="type === 'cancel-edit'"
          [class.download]="type === 'download'"
          [class.small]="isSmall"
          [class.refuse]="type === 'refuse'"
          [disabled]="isDisabled || (disabledByRequestLoading && isTargetBtn)"
          (click)="clickHandler()">
    <ng-content></ng-content>
  </button>
</ng-template>
