import {NitToastr} from '@nit-services';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {ExternalValidationError} from '@nit-models';
import {OAuthService} from 'angular-oauth2-oidc';
import {catchError, delay, filter, finalize, switchMap, take} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable()
export class ErroInterceptor implements HttpInterceptor {
  private _refreshTokenInProgress: boolean = false;
  private _isForbiddenNotification: boolean = false;

  constructor(private readonly _toastr: NitToastr, private readonly oauthService: OAuthService, private readonly _router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(catchError(err => {
        switch (err.status) {
        case 400:
          return this._handle400Response(err);
        case 401:
          return this._handle401Response(req, next);
        case 403:
          return this._handle403Response(err);
        case 422:
          return this._handle422Response(err);
        case 503:
          return this._handle503Response(err);
        default:
          return this._handleUnknownResponse(err);
        }
      }));
  }

  private _handle400Response(err: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (err.error && err.error['error_description'] === 'invalid_username_or_password') {
      this._toastr.error('Неправильний email або пароль');
    }

    return throwError(err);
  }

  private _handle401Response(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this._refreshTokenInProgress) {
      return this.oauthService.events.pipe(
        filter(result => result.type === 'token_received'),
        take(1),
        switchMap(() => next.handle(this._addAuthenticationToken(req)))
      );
    } else {
      this._refreshTokenInProgress = true;

      return from(this.oauthService.refreshToken())
        .pipe(
          switchMap(() => from(this.oauthService.loadUserProfile())),
          switchMap(() => next.handle(this._addAuthenticationToken(req))),
          catchError(err => this._logOut(err)),
          finalize(() => this._refreshTokenInProgress = false)
        );
    }
  }

  private _handle403Response(err: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (!this._isForbiddenNotification) {
      this._isForbiddenNotification = true;
      of(this._toastr.error('У вас немає прав доступу для запитуваної сторінки'))
        .pipe(delay(this._toastr.DELAY))
        .subscribe({next: () => this._isForbiddenNotification = false});
    }

    return from(this._router.navigate(['/']))
      .pipe(switchMap(() => throwError(err)));
  }

  private _handle422Response(err: HttpErrorResponse): Observable<HttpEvent<any>> {
    const validation = new ExternalValidationError(err.error);
    if (validation.general) {
      validation.general.forEach(externalMessage => {
        this._toastr.error(externalMessage);
      });
    }

    return throwError(err);
  }

  private _handle503Response(err: HttpErrorResponse): Observable<HttpEvent<any>> {
    this._toastr.error('Проводяться тех.роботи');

    return from(this._router.navigate(['/']))
      .pipe(switchMap(() => throwError(err)));
  }

  private _handleUnknownResponse(err: HttpErrorResponse): Observable<HttpEvent<any>> {
    this._toastr.error('Сталася невідома помилка.');

    return throwError(err);
  }

  private _addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {Authorization: `Bearer ${this.oauthService.getAccessToken()}`}
    });
  }

  private _logOut(error: any): Observable<any> {
    return of(error).pipe(
      finalize(() => this._router.navigate(['/login'])),
      finalize(() => localStorage.clear()),
      finalize(() => sessionStorage.clear()),
      switchMap(() => throwError(error))
    );
  }
}
