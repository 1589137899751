import {Injectable} from '@angular/core';
import {SchoolSettings} from '@nit-models';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SchoolSettingService extends RestService<SchoolSettings> {
  constructor() {
    super(SchoolSettings, '/settings', 'settings');
  }

  getSettings(userId?: string, schoolId?: number): Observable<SchoolSettings> {
    return this.httpGet<SchoolSettings>(this.apiHref(), {
      alias: 'school-settings',
      asUserId: userId,
      asUserSchoolId: schoolId
    });
  }

  updateSettings(data: SchoolSettings): Observable<SchoolSettings> {
    return this.httpPut(this.apiHref(), data);
  }
}
