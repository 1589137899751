import {ButtonsModule} from '@nit-core/components';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DaterangeCloseExtensionComponent} from './daterange-close-extension.component';
import {DateInputsModule} from '@progress/kendo-angular-dateinputs';

@NgModule({
  imports: [
    CommonModule,
    DateInputsModule,
    ButtonsModule
  ],
  declarations: [
    DaterangeCloseExtensionComponent
  ],
  exports: [
    DaterangeCloseExtensionComponent
  ]
})

export class DaterangeCloseExtensionModule { }
