import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {RemoveRelations} from '@nit-core/models/profile';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RelationService extends RestService<RemoveRelations> {

  constructor() { super(RemoveRelations, '/relations', 'relations'); }

  removeRelations(data: RemoveRelations): Observable<void> {
    return this.httpDelete(this.apiHref(), {}, data);
  }
}
