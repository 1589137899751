import {Injectable} from '@angular/core';
import {HTTPOptions, RestService} from '@nit-core/services/global/http-services/rest.service';
import {DiagnosticWork, DiagnosticWorkPage, DiagnosticWorkUser} from '@nit-core/models/diagnostic-work';
import {ApiResponse} from '@nit-core/models';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class DiagnosticWorkService extends RestService<DiagnosticWork> {

  constructor() {
    super(DiagnosticWork, '/diagnostic-works', 'diagnostic-works');
  }

  getDiagnosticUsers(diagnosticId: string, childId?: string, schoolId?: number): Observable<ApiResponse<DiagnosticWorkUser>> {
    let options: HTTPOptions = {
      alias: 'diagnostic-users'
    };

    if (childId) {
      options = {
        alias: 'diagnostic-users',
        asUserId: childId,
        asUserSchoolId: schoolId,
      };
    }

    return this.httpGetAll<DiagnosticWorkUser>(`${this.apiHref()}/${diagnosticId}/users`, options);
  }

  getDiagnosticColumns(diagnosticId: string, childId: string = '', schoolId: number, page: number = 1): Observable<DiagnosticWorkPage> {
    let options: HTTPOptions = {
      alias: 'diagnostic-pages'
    };

    if (childId) {
      options = {
        alias: 'diagnostic-pages',
        asUserId: childId,
        asUserSchoolId: schoolId,
      };
    }

    return this.httpGet<DiagnosticWorkPage>(`${this.apiHref()}/${diagnosticId}/pages?page=${page}`, options);
  }

}
