import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'daysFromToday'})
export class DaysFromTodayPipe implements PipeTransform {
  transform(value: Date, returnType: 'number' | 'string' = 'string'): string | number {
    const daysQuantity = this._calculateDaysFromToday(value);
    if (returnType === 'number') {
      return daysQuantity;
    }

    return this._getString(daysQuantity);
  }

  private _calculateDaysFromToday(value: Date): number {
    const currentDate = new Date();
    const givenDate = new Date(value);

    if (givenDate < currentDate) {
      return 0;
    }

    const diffInMs = this._getDiffInMs(currentDate, givenDate);

    return this._convertMsToDays(diffInMs);
  }

  private _getDiffInMs(date1: Date, date2: Date): number {
    return Math.abs(date1.getTime() - date2.getTime());
  }

  private _convertMsToDays(ms: number): number {
    const msPerDay = 1000 * 60 * 60 * 24;

    return Math.floor(ms / msPerDay);
  }

  private _getString(number: number): string {
    const lastDigits = number % 100;
    
    if (lastDigits >= 11 && lastDigits <= 14) {
      return `Залишилось ${number} днів`;
    }

    const lastDigit = number.toString().split('').pop();

    switch (lastDigit) {
    case '1':
      return `Залишився ${number} день`;
    case '2':
    case '3':
    case '4':
      return `Залишилось ${number} дні`;
    default:
      return `Залишилось ${number} днів`;
    }
  }
}
