import {User} from './common/user';

export class ClassMaterial {
  classId: string;
  subject: string;
  teacher?: Teacher;
  isFds: boolean;
  teachers: Teacher[];
  attachments: AttachedFile[];
  groupAttachments: GroupAttachment[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.attachments = item.attachments ? item.attachments.map((x: any) => new AttachedFile(x)) : undefined;
    this.groupAttachments = item.groupAttachments ? item.groupAttachments.map((x: any) => new GroupAttachment(x)) : undefined;
    this.teachers = item.teachers ? item.teachers.map((x: any) => new Teacher(x)) : [];
  }

  setTeacher(userId: string) {
    this.teacher = this.teachers.find(x => x.id === userId);
  }
}

export class AttachedFile {
  fileName: string;
  content: string;
  filePath: string;
  id: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class GroupAttachment {
  groupId: string;
  groupName: string;
  attachments: AttachedFile[];
  teacher: Teacher;
  teachers: Teacher[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.attachments = item.attachments ? item.attachments.map((x: any) => new AttachedFile(x)) : undefined;
    this.teacher = item.teacher ? new Teacher(item.teacher) : undefined;
    this.teachers = item.teachers ? item.teachers.map((x: any) => new Teacher(x)) : [];
  }
}

export class Teacher extends User {
  id: string;
  initials?: string;
  isVacancy?: boolean;

  constructor(item: any = {}) {
    super(item);
    Object.assign(this, item);
  }
}
