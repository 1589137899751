import {FileRestrictions} from '@progress/kendo-angular-upload';
import {Days} from '@nit-core/models/journal-day';

export const DAYS: Days = {
  1: {
    full: 'Понеділок',
    short: 'Пн'
  },
  2: {
    full: 'Вівторок',
    short: 'Вт'
  },
  3: {
    full: 'Середа',
    short: 'Ср'
  },
  4: {
    full: 'Четвер',
    short: 'Чт'
  },
  5: {
    full: 'П\'ятниця',
    short: 'Пт'
  },
  6: {
    full: 'Субота',
    short: 'Сб'
  },
  0: {
    full: 'Неділя',
    short: 'Нд'
  },
};

export const NUMBER_OF_LESSONS: number = 20;

export const NUMBER_OF_CLASSES: number = 12;

export const NUMBER_OF_CLASSES_RENDER: number = 12;

export const LESSONS_ARRAY: number[] = Array.from(Array(NUMBER_OF_LESSONS).keys());

export const CLASSES_ARRAY: number[] = Array.from(Array(NUMBER_OF_CLASSES), (_, i) => i + 1);

export const CLASSES_ARRAY_RENDER: number[] = Array.from(Array(NUMBER_OF_CLASSES_RENDER), (_, i) => i + 1);

export const LETTERS_ARRAY: string[] = ['А', 'Б', 'В', 'Г', 'Ґ', 'Д', 'Е', 'Є', 'Ж', 'З', 'И', 'І', 'Ї', 'Й', 'К', 'Л',
  'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Ю', 'Я'];

export const SHORT_DAYS: string[] = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'];

export const SHORT_DAYS_ENG_ORDER: string[] = ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

export const LONG_DAYS_ENG_ORDER: string[] = ['Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер', 'П\'ятниця', 'Субота'];

export const MONTHS: string[] = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень',
  'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];

export const MONTHS_GENITIVE: string[] = ['січня', 'лютого', 'березня', 'квітня', 'травня', 'червня',
  'липня', 'серпня', 'вересня', 'жовтня', 'листопада', 'грудня'];

export const ATTACHMENT_FILE_RESTRICTION: FileRestrictions = {
  allowedExtensions: ['jpg', 'pdf', 'doc', 'xls', 'pptx', 'xlt', 'xml', 'xlsm', 'txt', 'docx', 'jpeg', 'png', 'xlsx',
    'pptx', 'pptm', 'ppt', 'M4A', 'FLAC', 'MP3', 'MP4', 'wav','mov', 'avi', 'odt', 'odf'],
  maxFileSize: 52428800
};

export const REQUEST_FILE_RESTRICTION: FileRestrictions = {
  allowedExtensions: ['jpg', 'png', 'jpeg', 'pdf'],
  maxFileSize: 10485760
};

export const REQUEST_FILE_RESTRICTION_HOMEWORK_CHILDREN: FileRestrictions = {
  allowedExtensions: ['jpg', 'pdf', 'doc', 'xls', 'pptx', 'xlt', 'xml', 'xlsm', 'txt', 'docx', 'jpeg', 'png', 'xlsx', 'mp3', 'mp4', 'mov', 'avi', 'odt', 'odf', 'xsl'],
  maxFileSize: 6291456
};

export const REQUEST_FILE_RESTRICTION_HOMEWORK: FileRestrictions = {
  allowedExtensions: ['jpg', 'pdf', 'doc', 'xls', 'pptx', 'xlt', 'xml', 'xlsm', 'txt', 'docx', 'jpeg', 'png', 'xlsx', 'mp3', 'mp4', 'mov', 'avi', 'odt', 'odf'],
  maxFileSize: 15728640
};

export const IMPORT_FILE_RESTRICTION: FileRestrictions = {
  allowedExtensions: ['xlt', 'xlsm', 'xlsx'],
  maxFileSize: 6291456
};

export const INDEXED_MONTHS = MONTHS.map((monthName, i) => ({
  index: i,
  name: monthName
}));
