import {NitFormArray} from './nit-form-array';
import {FormGroup, AbstractControl, FormControl, FormArray} from '@angular/forms';
import {ExternalValidationError} from '@nit-models';
import {HttpErrorResponse} from '@angular/common/http';

export class NitForm extends FormGroup {
  constructor(controls: {[key: string]: AbstractControl}, defaultValue?: any, validatorOrOpts?: any) {
    super(controls, validatorOrOpts);
    if (defaultValue) {
      this.patchValue(defaultValue);
    }
  }

  validate(shouldEmitEvent: boolean = true): boolean {
    this.markAllAsTouched();

    Object.keys(this.controls).forEach(key => {
      if (this.controls[key] instanceof NitFormArray) {
        (this.controls[key] as NitFormArray).validate();
      } else if (this.controls[key] instanceof NitForm) {
        (this.controls[key] as NitForm).validate();
      } else {
        this.controls[key].updateValueAndValidity({emitEvent: shouldEmitEvent});
      }
    });

    return this.valid;
  }

  externalValidationError(errors: ExternalValidationError): void {
    if (errors?.fields) {
      Object.keys(errors.fields).forEach(key => {
        const changedKey = key.replace('[', '.').replace(']', '');
        const control = this.get(changedKey);

        if (control) {
          control.markAsTouched();
          control.setErrors({[changedKey]: {message: errors.fields[key]}});
        }
      });
    }
  }

  externalValidationHandler(e: HttpErrorResponse): void {
    if (e.status === 422 && e.error) {
      this.externalValidationError(new ExternalValidationError(e.error));
    }
  }

  clone(): NitForm {
    const form = new NitForm({});

    Object.keys(this.controls).forEach(control => {
      const element = this.copyFormControl(this.controls[control]);
      form.addControl(control, element);
    });

    return form;
  }

  copyFormControl(control: AbstractControl): AbstractControl {
    if (control instanceof FormControl) {
      return new FormControl(control.value, control.validator);
    } else if (control instanceof FormGroup) {
      const copy = new FormGroup({});
      Object.keys(control.getRawValue()).forEach(key => {
        copy.addControl(key, this.copyFormControl(control.controls[key]));
      });

      return copy;
    } else if (control instanceof FormArray) {
      const copy = new FormArray([]);
      control.controls.forEach(x => {
        copy.push(this.copyFormControl(x));
      });

      return copy;
    }
  }

  asFormData(): FormData {
    const formData = new FormData();
    this._addValueToFormData(this, formData);

    return formData;
  }

  private _addValueToFormData(control: any, formData: FormData, parentKey: string = '', parentRoute: string = ''): void {
    parentRoute = parentRoute ? `${parentRoute}.${parentKey}` : parentKey;

    if (control instanceof FormControl) {
      const value = this._transformData(control.value);

      if (value instanceof Array) {
        value.forEach((res , index) => {
          if (res instanceof File) {
            formData.append(parentRoute, res, res.name);
          } else {
            formData.append(`${parentRoute}[${index}]`, res);
          }
        });
      } else {
        formData.append(parentRoute, value);
      }
    } else {
      Object.keys(control.controls).forEach(key => {
        this._addValueToFormData(control.controls[key], formData, key, parentRoute);
      });
    }
  }

  private _transformData(data: any): any {
    if (data instanceof Date) {
      return data?.toJSON();
    } else if (data === null) {
      return '';
    }

    return data;
  }
}
