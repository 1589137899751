import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';
import {ComparisonReport, DataForStep} from '@nit-core/models/comparison-report';

@Injectable({
  providedIn: 'root'
})
export class ComparisonReportService extends RestService<ComparisonReport> {

  constructor() {
    super(ComparisonReport, '/comparisons', 'comparisons');
  }

  sendDecision(id: string, decision: boolean): Observable<void> {
    return this.httpPost(`${this.apiHref()}/comparison-users/${id}?isApproved=${decision}`, {});
  }

  sendDataForStep(id: string, data: DataForStep): Observable<void> {
    return this.httpPost(`${this.apiHref()}/${id}/step`, data);
  }
}
