import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserImportService extends RestService<FormData> {

  constructor() {super(FormData, '/users/import', 'users/import'); }

  getTemplateUsers(importType: number): Observable<Blob> {
    return this.httpGetBlob(`${this.apiHref()}/templates/${importType}`, {});
  }
}
