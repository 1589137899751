import {SexType, SyncRecordType, SyncResolutionType, SyncType} from '@nit-core/global/domain/enums';

export class SyncInstanceBody {
  type?: SyncType;
  id?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class SyncRecord {
  syncType: SyncType;
  type: SyncRecordType;
  aikomUser: AikomUser;
  schoolUsers: SchoolUser[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.aikomUser = item.aikomUser ? new AikomUser(item.aikomUser) : null;
    this.schoolUsers = item.schoolUsers?.length ?
      item.schoolUsers.map(schoolUser => new SchoolUser(schoolUser)) : [];
  }
}

export class AikomUser {
  id: string;
  fullName: string;
  birthday: Date;
  isChild?: boolean;
  phone?: string;
  sex?: SexType;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.birthday = item.birthday ? new Date(item.birthday) : null;
  }
}

export class SchoolUser {
  id: string;
  fullName: string;
  isChild: boolean;
  birthday: Date;
  phone?: string;
  code?: string;
  fromSchool: boolean;
  sex?: SexType;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.birthday = item.birthday ? new Date(item.birthday) : null;
  }
}

export class SyncResolutionVM {
  resolutionType: SyncResolutionType;
  id?: string | number;
  email?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class SyncResponse {
  id: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
