import {Injectable} from '@angular/core';
import {StudentGeneralInfo} from '@nit-core/models/student-general-info';
import {RestService} from '@nit-core/services/global/http-services/rest.service';

@Injectable({
  providedIn: 'root'
})
export class StudentGeneralInfoService extends RestService<StudentGeneralInfo> {
  constructor() {
    super(StudentGeneralInfo, '/student-general-info', 'student-general-info');
  }
}
