import {NgModule} from '@angular/core';
import {TooltipComponent} from './tooltip.component';
import {CommonModule} from '@angular/common';



@NgModule({
  declarations: [TooltipComponent],
  exports: [
    TooltipComponent
  ],
  imports: [
    CommonModule
  ]
})
export class NitTooltipComponentModule { }
