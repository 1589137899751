import {ClassGroup} from '@nit-models';
import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';
import {GroupPairs} from '@nit-core/models/class-group';

@Injectable({providedIn: 'root'})
export class ClassGroupService extends RestService<ClassGroup> {
  constructor() { super(ClassGroup, '/groups', 'class-group'); }

  updateClassGroup(classId: string, group: ClassGroup): Observable<any> {
    return this.httpPut(`${this.apiBaseHref()}/classes/${classId}/groups/${group.id}`, group);
  }

  createClassGroup(classId: string, group: ClassGroup): Observable<ClassGroup> {
    return this.httpPost(`${this.apiBaseHref()}/classes/${classId}/groups`, group);
  }

  deleteClassGroup(classId: string, groupId: string): Observable<any> {
    return this.httpDelete(`${this.apiBaseHref()}/classes/${classId}/groups/${groupId}`);
  }

  getGroupsPairs(): Observable<GroupPairs> {
    return this.httpGet(`${this.apiHref()}/pairs`, {factory: GroupPairs});
  }
}
