import {AuthService} from '@nit-auth';
import {Injectable} from '@angular/core';
import {NgxPermissionsService} from 'ngx-permissions';
import {UserService} from '@nit-services';

@Injectable()
export class PermissionService {
  isHasPermission = false;

  constructor(private readonly _authService: AuthService,
              private readonly _ngxPermissionsService: NgxPermissionsService,
              private readonly _userService: UserService) {
  }

  hasPermission(permissions: string[]): boolean {
    this.isHasPermission = false;
    for (let i = 0; i < permissions.length; i++) {
      if (this._ngxPermissionsService.getPermissions()[permissions[i]]) {
        this.isHasPermission = true;
        break;
      }
    }

    return this.isHasPermission;
  }

  hasPermissionByUser(userId: string, permission: string[]): boolean {
    if (this._authService.userId === userId) {
      return this.hasPermission(permission);
    }
    const userPermissions = this._userService.userPermissions$.getValue();
    const permissionByUser = userPermissions?.permissions.find(x => x.active && x.forUserId === userId);
    if (permissionByUser) {
      for (let i = 0; i < permissionByUser.permissions.length; i++) {
        if (permissionByUser.permissions.some(p => permission.includes(p))) {
          return true;
        }
      }
    }

    return false;
  }
}
