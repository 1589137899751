import {UserType} from '@nit-core/global/domain/enums';
import {SchoolSubject} from './profile';
import {DayWithLessons} from '@nit-core/models/scheduled-lesson';
import {Load, Workload} from '@nit-core/models/workload';
import {Subject} from './subject';

// Teacher from /school/teachers request
export class Teacher {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  subjects: SchoolSubject[];
  days: DayWithLessons[];
  shortName: string;
  code: string;
  weeklyWorkLoadLimit: Workload[];
  load: Load;
  isActive: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.shortName = `${item.lastName} ${item.firstName.slice(0, 1)}.${
      item.middleName ? item.middleName.slice(0, 1) + '.': ''}`;
    this.shortName = formatShortName(this);

    this.subjects = item.subjects.length ? item.subjects.map(subject => new SchoolSubject(subject)) : [];
    this.days = item.days?.length ? item.days.map(day => new DayWithLessons(day)) : [];
    this.weeklyWorkLoadLimit = item.weeklyWorkLoadLimit ? item.weeklyWorkLoadLimit.map(workload =>
      new Workload(workload)) : [];
    this.load = item.load ? new Load(item.load) : null;
  }
}

export class CreateTeacherBody {
  firstName: string;
  lastName: string;
  middleName: string;
  phoneNumber: string;
  email: string;
  birthDay: string;
  type: UserType;
  sex: string;
  schoolSubject?: string[];
  subjects?: Subject[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.schoolSubject = item.schoolSubject?.length ? [...item.schoolSubject] : null;
    this.subjects = item.subjects?.length ? item.subjects.map(subject => new Subject(subject)) : null;
  }
}

function formatShortName(teacher: Teacher): string {
  return teacher.isActive ? teacher.shortName : `${teacher.shortName} (Вилучений)`;
}
