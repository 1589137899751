import {getShortName} from '@nit-core/methods';

export abstract class User {
  firstName: string;
  lastName: string;
  middleName: string;
  shortName?: string;
  fullName: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.shortName = getShortName(item.firstName, item.lastName, item.middleName);
  }
}
