import {NgModule} from '@angular/core';
import {ChartjsModule} from '@ctrl/ngx-chartjs';

@NgModule({
  exports: [
    ChartjsModule
  ]
})

export class ChartModule {
}
