import {Teacher} from './teacher';

export class Workload {
  id?: string;
  teacherId: string;
  subject: string;
  hours: number;
  isSubjectAbsent?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class SubjectWithWorkload {
  name: string;
  workload: Workload | string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.workload = !item.workload ? null :
      typeof item.workload === 'string' ? item.workload : new Workload(item.workload);
  }
}

export type TeacherWithWorkloads = Omit<Teacher, 'subjects'> & {
  subjects: SubjectWithWorkload[];
}

export class Load {
  plannedLoad: number;
  existingLoad: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
