import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'transformType'
})

export class TransformTypePipe implements PipeTransform {

  typeList: Map<string, string> = new Map();

  transform(type: string): string {
    type = type.split('/')[1];
    
    this.typeList.set('vnd.openxmlformats-officedocument.presentationml.presentation', 'pptx');
    this.typeList.set('svg+xml', 'svg');
    this.typeList.set('mpeg', 'mp3');
    this.typeList.set('quicktime', 'mov');
    this.typeList.set('vnd.ms-excel', 'xls');
    this.typeList.set('vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'xlsx');
    this.typeList.set('vnd.oasis.opendocument.text', 'odt');
    this.typeList.set('vnd.openxmlformats-officedocument.wordprocessingml.document', 'docs');
    this.typeList.set('vnd.oasis.opendocument.spreadsheet', 'ods');
    this.typeList.set('vnd.oasis.opendocument.presentation', 'odp');
    this.typeList.set('vnd.oasis.opendocument.graphics', 'odg');

    if (this.typeList.get(type)) {
      return this.typeList.get(type);
    }

    return type;
  }
}
