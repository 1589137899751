import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonSquareComponent} from './button-square/button-square.component';
import {ButtonPlusComponent} from './button-plus/button-plus.component';
import {ButtonComponent} from './button/button.component';
import {RouterModule} from '@angular/router';
import {ButtonContextMenuComponent} from './button-context-menu/button-context-menu.component';
import {ButtonRemoveComponent} from './button-remove/button-remove.component';
import {ButtonsModule as KendoButtonModule} from '@progress/kendo-angular-buttons';
import {ButtonTransferComponent} from '@nit-core/components/buttons/button-transfer/button-transfer.component';
import {ButtonFilterComponent} from './button-filter/button-filter.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    KendoButtonModule
  ],
  declarations: [
    ButtonSquareComponent,
    ButtonPlusComponent,
    ButtonComponent,
    ButtonContextMenuComponent,
    ButtonRemoveComponent,
    ButtonTransferComponent,
    ButtonFilterComponent
  ],
  exports: [
    ButtonSquareComponent,
    ButtonPlusComponent,
    ButtonComponent,
    ButtonContextMenuComponent,
    ButtonRemoveComponent,
    ButtonTransferComponent,
    ButtonFilterComponent
  ]
})
export class ButtonsModule { }
