type TrimSide = 'left' | 'right' | 'both';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface String {
  trimSymbol(symbol: string, side?: TrimSide): string;
}

String.prototype.trimSymbol = trimSymbol;


function trimSymbol(symbol: string, side: TrimSide = 'both'): string {
  let start = 0;
  let end = this.length;

  if (side !== 'right') {
    while (start < end && this[start] === symbol) { ++start; }
  }
  if (side !== 'left') {
    while (end > start && this[end - 1] === symbol) { --end; }
  }

  return (start > 0 || end < this.length) ? this.substring(start, end) : this;
}

