export class LogInModel {
  userName: string;
  password: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class SignUpModel {
  userName: string;
  password: string;
  confirmPassword: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ConfirmActionModel {
  email: string;
  userCode?: string;
  token?: string;
  password?: string;
  confirmPassword?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ResendConfirmActionModel {
  email: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ChangeEmailModel {
  email: string;
  userId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
