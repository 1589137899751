import {NitToastr, SchoolService, UserService} from '@nit-services';
import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '@nit-auth';
import {NAvatarUploadValidate} from '@nit-forms';

@Component({
  selector: 'nit-avatar-upload-file',
  templateUrl: './avatar-upload-file.component.html',
  styleUrls: ['./avatar-upload-file.component.scss']
})

export class AvatarUploadFileComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  @Input() image: any;
  @Input() canUpload: boolean = true;
  @Input() childId: string;
  @Input() size: 'large' | 'medium' = 'large';
  @Input() type: 'user' | 'school' = 'user';
  @Input() defaultText: string = '';

  avatarValidator = new NAvatarUploadValidate();
  validationMessage: string;
  isValid = true;
  isDefault = true;
  profileId: string;

  constructor(private readonly _profileService: UserService,
              private readonly _schoolService: SchoolService,
              private readonly _toastr: NitToastr,
              private readonly _authService: AuthService) {
  }

  ngOnInit(): void {
    this.profileId = this.childId ? this.childId : this._authService.userId;
  }

  uploadFile(file: File): void {
    if (!this.avatarValidator.isTypeValid(file.type)) {
      this.uploadWindowCongif(false, this.avatarValidator.wrongFormatMessage);
      this._toastr.error('Неправильний формат файлу');
    } else if (!this.avatarValidator.isSizeValid(file.size)) {
      this.uploadWindowCongif(false, this.avatarValidator.wrongSizeMessage);
      this._toastr.error('Перевищений допустимий ліміт файлу');
    } else {
      this.uploadWindowCongif(true, '');

      if (this.type === 'user') {
        this._profileService.updateImage(this.profileId, this.getFormData(file))
          .subscribe(() => {
            this._uploadSuccessHandler(file);
            this._getProfile();
          });
      } else {
        this._schoolService.updateImage(this.getFormData(file))
          .subscribe(() => this._uploadSuccessHandler(file));
      }
    }
  }

  getFormData(file: File): FormData {
    const formData = new FormData();
    formData.append('data', file, file.name);

    return formData;
  }

  preventEvent(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
  }

  dragover(evt: any): void {
    this.preventEvent(evt);
    if (evt.dataTransfer.items.length > 1) {
      this.uploadWindowCongif(false, this.avatarValidator.wrongAmountOfFilesMessage);
    } else if (!this.avatarValidator.isTypeValid(evt.dataTransfer.items[0].type)) {
      this.uploadWindowCongif(false, this.avatarValidator.wrongFormatMessage);
    } else {
      this.uploadWindowCongif(true, '');
      this.isDefault = false;
    }
  }

  dragleave(evt: any): void {
    this.preventEvent(evt);
    this.uploadWindowCongif(true, '');
    this.isDefault = true;
  }

  drop(evt: any): void {
    this.preventEvent(evt);
    const files = evt.dataTransfer.files;
    if (files?.length > 1) {
      this.uploadWindowCongif(false, this.avatarValidator.wrongAmountOfFilesMessage);
    } else if (!this.avatarValidator.isTypeValid(files[0].type)) {
      this.uploadWindowCongif(false, this.avatarValidator.wrongFormatMessage);
    } else if (!this.avatarValidator.isSizeValid(files[0].size)) {
      this.uploadWindowCongif(false, this.avatarValidator.wrongSizeMessage);
    } else {
      this.uploadFile(files[0]);
      this.isDefault = true;
    }
  }

  uploadWindowCongif(isValid: boolean, validationMessage: string): void {
    this.isValid = isValid;
    this.validationMessage = validationMessage;
  }

  removeImage(): void {
    if (this.image) {
      if (this.type === 'user') {
        this._profileService.removeImage(this.profileId).subscribe(() => {
          this.image = null;
          this._toastr.success('Фотографію видалено');
          this._getProfile();
        });
      } else {
        this._schoolService.removeImage().subscribe(() => {
          this.image = null;
          this._toastr.success('Фотографію видалено');
        });
      }
      this.fileInput.nativeElement.value = '';
    }
  }

  private _getProfile(): void {
    this._profileService.read(this._authService.userId).subscribe(profile => this._profileService.currentUser$.next(profile));
  }

  private _uploadSuccessHandler(file: File): void {
    this._toastr.success('Фотографію додано');
    this._previewImage(file);
  }

  private _previewImage(file: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.image = reader.result;
    };
  }
}
