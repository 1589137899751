import {Pipe, PipeTransform} from '@angular/core';
import {LogoByDateService, LogoDate} from '@nit-core/services/global/logo-by-date.service';
import {DateTransformService} from '@nit-services';

@Pipe({
  name: 'calendarLogo'
})
export class CalendarLogoPipe implements PipeTransform {
  data: LogoDate[];

  today: Date = new Date();
  constructor(private readonly _logoByDateService: LogoByDateService,
              private readonly _dateTransformService: DateTransformService) {
    this.data = this._logoByDateService.date;
  }

  transform(value: string): string {
    const filtered: LogoDate[] = this.data.filter(x => x.start.getTime() <= this.today.getTime() && x.end.getTime() >= this.today.getTime());
    let result;
    if (filtered?.length === 2) {
      result = filtered.find(x => !x.season)[value];
    } else {
      result = filtered[0]?.[value];
    }

    return result;
  }
}
