import {Alternation, LessonVersionState, SortOrder} from '@nit-core/global/domain/enums';
import {Teacher} from '@nit-core/models/class-material';
import {JournalDay} from './journal-day';
import {Class} from '@nit-core/models/class';

export class ScheduledLesson {
  id?: string;
  academicYearId: string;
  scheduleId: string;
  dayOfWeek: number;
  lessonNumber: number;
  versions: Versions;
  classroom?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.versions = item.versions ? new Versions(item.versions) : null;
  }
}

export class PublishBody {
  academicYearId: string;
  draftId: string;
  scheduleId: string;
  publishChangesOnly: boolean;
  publishWholeSemester: boolean;
  from?: Date;
  to?: Date;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.from = item.from ? new Date(item.from) : null;
    this.to = item.to ? new Date(item.to) : null;
  }
}

export class Versions {
  state: LessonVersionState;
  saved: Version | Record<string, never>;
  draft: Version | Record<string, never>;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.saved = item.saved ? new Version(item.saved) : null;
    this.draft = item.draft ? new Version(item.draft) : null;
  }
}

export class Version {
  subject: string;
  teacherId: string;
  isVacancy: boolean;
  classId: string;
  className?: string;
  classGroupName?: string;
  classGroupId: string;
  classroom: string;
  type?: Alternation;
  isFds: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class DayWithLessons {
  dayIndex?: number;
  lessons: ScheduledLesson[] | JournalDay[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.lessons = item.lessons ? item.lessons.map((x: any) => {
      return x.versions ? new ScheduledLesson(x) : new JournalDay(x);
    }) : [];
  }
}

export class LessonSetting {
  id?: string;
  isChosen: boolean;
  lessonNumber: number;
  type: Alternation | null;
  hasCreatedLessons: LessonInfoForTip[];
  dayOfWeek?: number;
  classroom?: string;
  hasConflictsFds: number[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.hasCreatedLessons = item.hasCreatedLessons ?
      item.hasCreatedLessons.map((x: any) => new LessonInfoForTip(x)) : [];
  }
}

export class PastLessonSettings {
  dayIndex: number;
  lessons: LessonSetting[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.lessons = item.lessons ? item.lessons.map(lesson => new LessonSetting(lesson)) : [];
  }
}

export class LessonsInformation {
  lessons: ScheduledLesson[] | JournalDay[];
  teacher: Teacher;
  academicYearId: string;
  tileId?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.lessons = item.lessons ? item.lessons.map((x: any) => isJournalDay(x) ? new JournalDay(x) : new ScheduledLesson(x)) : [];
    this.teacher = item.teacher ? new Teacher(item.teacher) : undefined;
  }
}

export class ChangesInfo {
  teacherId: string;
  id: string;
  dayOfWeek?: number;
  lessonNumber?: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class LessonInfoForTip {
  className: string;
  classroom: string;
  classGroupName: string;
  subject: string;
  type: Alternation;
  teacherName: string;
  lessonId: string;
  teacherId?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class InfoForVacancies {
  className: string;
  subjectName: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ClassesInfo {
  id: string;
  name: string;
  allowedClasses: Class[];
  lessonTimeslots: DayWithLessons[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.allowedClasses = item.allowedClasses ? item.allowedClasses.map(i => new Class(i)) : [];
    this.lessonTimeslots = item?.lessonTimeslots ? item.lessonTimeslots.map(x => new DayWithLessons(x)) : [];
  }
}

export class ClassOrGroupSelected {
  isEveryClassSelected: boolean;
  hasGroups: boolean;
  isEveryGroupSelected: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ScheduleState {
  tileId: string;
  filterSelected?: string;
  sortingOrder?: SortOrder;
  teacherOrVacancyInfo?: TeacherOrVacancyInfo;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.teacherOrVacancyInfo = item.teacherOrVacancyInfo ? new TeacherOrVacancyInfo(item.teacherOrVacancyInfo) : null;
  }
}

export class TeacherOrVacancyInfo {
  id: string;
  isTeacher?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

function isJournalDay(lesson: ScheduledLesson | JournalDay): lesson is JournalDay {
  return 'journalId' in lesson;
}
