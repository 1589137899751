import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';
import {Greeting} from '@nit-core/models/greeting';

@Injectable({providedIn: 'root'})

export class GreetingService extends RestService<Greeting> {
  constructor() {super(Greeting, '/postcard', 'postcard'); }

  postGreeting(isSubmit?: boolean): Observable<Greeting> {
    return this.httpPost(this.apiHref(), {isSubmit});
  }

  getGreeting(): Observable<Greeting> {
    return this.httpGet(`${this.apiHref()}/by-user`);
  }
}
