<div class="control-group"
     [class.disabled]="control?.disabled || disabled"
     [class.high-invalid]="isHighError"
     [class.invalid]="control?.invalid && control?.dirty">
  <!-- eslint-disable-next-line -->
  <label *ngIf="label && !isFormat" class="control-label">
    {{label}}
    <span *ngIf="required" class="required">*</span>
    <div *ngIf="infoLabel" class="info-label">
      <div NitTooltip class="icon" [topText]="infoLabel"></div>
    </div>
  </label>
  <!-- eslint-disable-next-line -->
  <label *ngIf="label && isFormat" class="is-format">
    <div class="d-flex">
      <span class="text">{{label}}<span *ngIf="required" class="required">*</span></span>
      <div *ngIf="infoLabel" class="info-label">
        <span NitTooltip class="icon" [topText]="infoLabel"></span>
      </div>
    </div>
    <div *ngIf="formatLabel else noFormatLable" class="info-format">
      <div NitTooltip [topText]="formatLabel" [horizontalPosition]="position">
        <span class="format-text">{{formatTitle}}</span>
        <span class="icon"></span>
      </div>
    </div>
    <ng-template #noFormatLable>
      <div *ngIf="showFormatLabel" class="info-format">
        <span class="format-text">{{formatTitle}}</span>
      </div>
    </ng-template>
  </label>
  <div class="input-container">
    <img *ngIf="!!icon" class="left-icon" alt="icon" [src]="icons[icon]"/>
    <img *ngIf="!!control?.value && showHidePassword" class="eye-icon" alt="eye icon" [src]="this.showPassword ? '/assets/icons/auth/eye.svg' : '/assets/icons/auth/cross-eye.svg'"
      (click)="togglePassword()" />
    <div #input>
      <ng-content></ng-content>
    </div>
  </div>
  <div *ngIf="showError" class="error-container">
    <ng-container *ngFor="let err of messages | async; let isFirst = first">
      <span *ngIf="isFirst && (control?.dirty || control?.touched)" class="control-error">{{err}}</span>
    </ng-container>
  </div>
</div>
