import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {DayOffs} from '@nit-core/models/day-offs';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})

export class VacationService extends RestService<DayOffs> {
  vacationsState$: Observable<any>;
  vacationsState = null;
  private readonly _vacationsSubject: BehaviorSubject<any>;
  constructor() {
    super(DayOffs, '/day-offs', 'day-offs');
    this._vacationsSubject = new BehaviorSubject(this.vacationsState);
    this.vacationsState$ = this._vacationsSubject.asObservable();
  }

  updateVacationsState(state: any): void {
    this.vacationsState = state;
    this._vacationsSubject.next(this.vacationsState);
  }
}
