import {JournalHistoryActions} from '@nit-core/global/domain/enums';

export class JournalSnapshot {
  id: string;
  journalId: string;
  academicYearId: string;
  scheduleId: string;
  teacherId: string;
  createdAt: Date;
  from: Date;
  to: Date;
  doneBy: string;
  doneByFullName: string;
  classId: string;
  className: string;
  classGroupId: string;
  classGroupName: string;
  subject: string;
  changeType: number;
  type: JournalHistoryActions;
  modifiedDays: ModifiedDay[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.from = item.from ? new Date(item.from) : null;
    this.to = item.to ? new Date(item.to) : null;
    this.createdAt = item.createdAt ? new Date(item.createdAt) : null;
    this.modifiedDays = item.modifiedDays?.length ? item.modifiedDays.map(day => new ModifiedDay(day)) : [];
  }
}

class ModifiedDay {
  date: Date;
  lesson: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.date = item.date ? new Date(item.date) : null;
  }
}
