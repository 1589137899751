import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {
  transform(address: {region?: string; fullAddress: string, [key: string]: string}): string {
    return address.fullAddress ? `${address.region ? address.region + ', ' : ''}${address.fullAddress}` : '';
  }
}
