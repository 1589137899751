import {NgModule} from '@angular/core';
import {PhoneInputComponent} from './phone-input.component';
import {InputsModule} from '@progress/kendo-angular-inputs';

@NgModule({
  imports: [
    InputsModule
  ],
  declarations: [
    PhoneInputComponent,
  ],
  exports: [
    PhoneInputComponent,
  ]
})
export class PhoneInputModule { }
