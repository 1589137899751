import {Injectable} from '@angular/core';
import {RestService} from '../global/http-services/rest.service';
import {SchoolByEdrpou} from '@nit-models';

@Injectable({
  providedIn: 'root'
})
export class AikomUniversitiesService extends RestService<SchoolByEdrpou> {
  constructor() {
    super(SchoolByEdrpou, 'aikom/universities', 'aikom-universities');
  }
}
