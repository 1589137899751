import {Injectable} from '@angular/core';
import {CompleteTestBody, CompleteTestResponse, CourseLesson, LastTestResult} from '@nit-core/models';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CourseLessonService extends RestService<CourseLesson> {
  constructor() {
    super(CourseLesson, '/course-lessons', 'course-lessons');
  }

  getLastTestResult(lessonId: string, blockId: string): Observable<LastTestResult> {
    return this.httpGet<LastTestResult>(`${this.apiHref()}/${lessonId}/last-test-result`, {
      query: {blockId}
    });
  }

  completeTest(lessonId: string, body: CompleteTestBody): Observable<CompleteTestResponse> {
    return this.httpPost(`${this.apiHref()}/${lessonId}/complete-test`, body);
  }

  uploadFile(data: FormData): Observable<any> {
    return this.httpPost(`${this.apiHref()}/image`, data);
  }
}
