import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {SupportProfile} from '@nit-models';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupportSauronEyeService extends RestService<SupportProfile> {
  constructor() {
    super(SupportProfile, '/support/users', 'support');
  }

  deleteUser(id: string, adminId: string): Observable<void> {
    return this.httpDelete(`${this.apiHref()}/${id}`, {asUserId: adminId});
  }
}
