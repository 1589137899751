import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResponse} from '@nit-models';
import {HTTPOptions, RestService} from '@nit-core/services/global/http-services/rest.service';
import {RuleDropdown} from '@nit-core/models/rule-dropdown';
import {KendoDataQuery} from '@nit-core/models/common/kendo-data-query';
import {KendoFilterOperator} from '@nit-core/global/domain/enums';
import {NushRule} from '@nit-core/models';

@Injectable({providedIn: 'root'})
export class NushRuleService extends RestService<NushRule> {
  constructor() {
    super(NushRule, '/rules', 'nush-rules');
  }

  getDropdownRules(classId: string, schoolId: number, childId?: string): Observable<ApiResponse<RuleDropdown>> {
    const queryFilters: KendoDataQuery = new KendoDataQuery(0, 999);
    queryFilters.pushFilters([{field: 'classId', operator: KendoFilterOperator.Eq, value: classId}]);

    let options: HTTPOptions = {
      alias: 'dropdown-rules',
      query: queryFilters.query
    };

    if (childId) {
      options = {
        alias: 'dropdown-rules',
        query: queryFilters.query,
        asUserId: childId,
        asUserSchoolId: schoolId,
      };
    }

    return this.httpGet<ApiResponse<RuleDropdown>>(`${this.apiHref()}/dropdown`, options);
  }
}
