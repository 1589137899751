import {Pipe, PipeTransform} from '@angular/core';
import {ChatMessage} from '@nit-models';

@Pipe({
  name: 'userNameDialog'
})
export class UserNameDialogPipe implements PipeTransform {

  transform(messages: ChatMessage[], index: number): unknown {
    return messages[index - 1]?.user._id !== messages[index]?.user._id;
  }
}
