import {Injectable} from '@angular/core';
import {Observable, of, switchMap} from 'rxjs';
import {JournalService, VacancyService} from '@nit-services';

@Injectable({
  providedIn: 'root'
})
export class ReplacementNameService {
  constructor(private readonly _vacanciesService: VacancyService,
              private readonly _journalService: JournalService) { }

  getReplacementName(teacherId: string): Observable<string> {
    if (!teacherId) {
      return of('');
    }

    return this._journalService.getJournalTeacher(teacherId).pipe(
      switchMap(teacher => {
        if (teacher) {
          return of(teacher.shortName);
        } else {
          return this._vacanciesService.read(teacherId).pipe(
            switchMap(res => of(res?.shortName))
          );
        }
      }),
    );
  }
}
