import {AfterViewInit, Directive, EventEmitter, ElementRef, HostListener, Input, OnDestroy, Output, Renderer2} from '@angular/core';
import {DialogComponent} from '@progress/kendo-angular-dialog';

@Directive({
  selector: '[kendoDialogExtension]'
})
export class KendoDialogExtensionDirective implements AfterViewInit, OnDestroy {
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @Input() kendoDialogExtension: DialogComponent;
  @Input() closeOutside: boolean;
  @Input() isOpened: boolean;
  @Input() closeOnEsc: boolean;

  constructor(private readonly _el: ElementRef, private readonly _renderer: Renderer2) {
    this._renderer.listen('window', 'click', event => {
      if (this.isOpened && !this.closeOutside && !this.closeOnEsc &&
        (event.target.closest('.menu') || event.target.closest('.notification'))) {
        this.closed.emit();
        this.kendoDialogExtension.close.emit();
      }
    });
  }

  ngAfterViewInit(): void {
    if (!this.closeOutside) {
      const element = this._el.nativeElement.getElementsByTagName('div')[0];
      this._renderer.listen(element, 'click', () => {
        this.closed.emit();
        this.kendoDialogExtension.close.emit();
      });
    }
  }

  @HostListener('document:keydown', ['$event'])
  handelKeyboardEvent(event: KeyboardEvent): void {
    if (event.keyCode === 27 && !this.closeOnEsc && this.kendoDialogExtension) {
      this.kendoDialogExtension.close.emit();
    }
  }

  ngOnDestroy(): void {
    this.closed.emit();
    this.kendoDialogExtension.close.emit();
  }
}
