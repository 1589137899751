import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'nit-button-square',
  template: `
    <button type="button" (click)="clicked.emit()" class="size-{{size}}">
      <img *ngIf="icon" [src]="icons[icon]" alt="notification">
    </button>
  `,
  styleUrls: ['./button-square.component.scss']
})

export class ButtonSquareComponent {
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() icon: 'alarm' | 'edit' | 'delete' | 'settings' | '' = '';
  @Input() size: 'small' | 'large' = 'large';
  icons: any = {
    settings: '/assets/icons/profile/settings.svg',
    alarm: '/assets/icons/alarm.svg',
    edit: '/assets/icons/profile/edit.svg',
    delete: '/assets/images/delete.svg'
  };

  constructor(private readonly _router: Router) { }
}
