import {Component, Output, EventEmitter, Input} from '@angular/core';

@Component({
  selector: 'nit-button-context-menu',
  templateUrl: './button-context-menu.component.html',
  styleUrls: ['./button-context-menu.component.scss']
})
export class ButtonContextMenuComponent {

  @Input() size: 'small' | 'large' = 'large';
  @Output() itemClicked: EventEmitter<ContextItem> = new EventEmitter<ContextItem>();

  private _items: ContextItem[] = [];
  private _classes = 'context-btn';

  @Input() set className(value: string) {
    this._classes += ' ' + value;
  }
  @Input() set items(value: ContextItem[]) {
    this._items = value;
  }

  get items(): ContextItem[] {
    return this._items?.filter(x => x.visible);
  }

  get classes() {
    return this._classes;
  }

  selectItemHandler(event: ContextItem): void {
    if (event.disabled) return;
    this.itemClicked.emit(event);
  }
}

export class ContextItem {
  mode: string;
  text?: string;
  icon?: string;
  disabled?: boolean;
  visible?: boolean;
  color?: string;
}
