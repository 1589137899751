export class ExternalValidationError {
  fields: { [key: string]: string[] } = {};
  general: string[] = [];

  constructor(item: any = {}) {
    if (item.fields) {
      Object.keys(item.fields).forEach(key => {
        this.fields[key[0].toLowerCase() + key.slice(1)] = item.fields[key];
      });
    }
    if (item.general) {
      this.general.push(...item.general);
    }
  }
}
