export class UserPermissions {
  userId: string;
  isDeleted: boolean;
  permissions: Permission[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.permissions = item.permissions ? item.permissions.map((x: any) => new Permission(x)) : undefined;
  }
}

export class Permission {
  active: boolean;
  startDate: Date;
  endDate: Date;
  forUserId: string;
  isChild: boolean;
  permissions: string[];
  schoolId: number;
  classIds: string[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.startDate = item.startDate ? new Date(item.startDate) : undefined;
    this.endDate = item.endDate ? new Date(item.endDate) : undefined;
  }
}
