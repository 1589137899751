import {Class, AssignUserToClass, ApiResponse} from '@nit-models';
import {Injectable} from '@angular/core';
import {HTTPOptions, RestService} from '@nit-core/services/global/http-services/rest.service';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {CreateUpdateClassBody, ManagingGroupUsers} from '@nit-core/models/class';

@Injectable({providedIn: 'root'})
export class ClassService extends RestService<Class> {

  selectedClass$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() { super(Class, '/classes', 'classes'); }

  getSortedClasses(options?: HTTPOptions): Observable<ApiResponse<Class>> {
    return this.all(options).pipe(map((res) => this._sortClasses(res)));
  }

  createClass(body: CreateUpdateClassBody): Observable<any> {
    return this.httpPost(this.apiHref(), body);
  }

  assignUserToClass(classId: string, assignUser: AssignUserToClass): Observable<any> {
    return this.httpPost(`${this.apiHref()}/${classId}/users`, assignUser);
  }

  deleteUserFromClass(classId: string, userId: string): Observable<void> {
    return this.httpDelete(`${this.apiHref()}/${classId}/users/${userId}`);
  }

  transferToGroup(classId: string, groupId: string, body: ManagingGroupUsers): Observable<void> {
    return this.httpPut(`${this.apiHref()}/${classId}/groups/${groupId}`, body);
  }

  removeFromGroup(classId: string, subjectGroupId: string, body: ManagingGroupUsers): Observable<void> {
    return this.httpPut(`${this.apiHref()}/${classId}/groups/${subjectGroupId}/delete-users`, body);
  }

  private _sortClasses(res: ApiResponse<Class>) {
    res.data.sort((a, b) => {
      if (a.number === b.number) {
        const aCode = this._getLetterCode(a.letter);
        const bCode = this._getLetterCode(b.letter);

        return aCode > bCode ? 1 : -1;
      }

      return +a.number > +b.number ? 1 : -1;
    });

    return res;
  }

  private _getLetterCode(letter: string): number {
    let code = letter.charCodeAt(0);

    switch (code) {
    case 1168:
      code = 1043.5;
      break;
    case 1028:
      code = 1045.5;
      break;
    case 1030:
      code = 1048.25;
      break;
    case 1031:
      code = 1048.75;
      break;
    }

    return code;
  }
}
