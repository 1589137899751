import {Teacher} from '@nit-core/models/teacher';
import {Vacancy} from '@nit-core/models/vacancy';
import {JournalDay, ScheduledLesson, SubjectGroup, Workload} from '@nit-models';
import {ClassesInfo, DayWithLessons} from '@nit-core/models/scheduled-lesson';
import {Lesson, NewAcademicYear} from '@nit-core/models/new-academic-year';
import {SemesterNumber} from '@nit-core/global/domain/enums';
import {NitForm, NitFormArray} from '@nit-forms';
import {AbstractControl} from '@angular/forms';

export const joinScheduledLessonsWithEntities = (
  entities: (Teacher | Vacancy)[],
  academicYear: NewAcademicYear | { lessons: Lesson[], studyDays: number[] },
  scheduledLessons: ScheduledLesson[] | JournalDay[],
  isDraftOn: boolean = false,
): (Teacher | Vacancy)[] => {
  return entities.map(teacherOrVacancy => {
    const {lessons, studyDays} = academicYear;

    let teacherLessons: ScheduledLesson[] | JournalDay[];
    if (isDraftOn) {
      teacherLessons = (scheduledLessons as ScheduledLesson[]).filter(scheduledLesson => {
        return (scheduledLesson.versions.draft?.teacherId || scheduledLesson.versions.saved?.teacherId) === teacherOrVacancy.id;
      });
    } else {
      teacherLessons = (scheduledLessons as JournalDay[]).filter(scheduledLesson => {
        return (scheduledLesson as JournalDay).journalTeacherId === teacherOrVacancy.id;
      });
    }

    const days: DayWithLessons[] = studyDays.map(day => {
      let lessonsOfDay: ScheduledLesson[] | JournalDay[];
      if (isDraftOn) {
        lessonsOfDay = (teacherLessons as ScheduledLesson[]).filter(lesson => {
          return lesson.dayOfWeek === day;
        });
      } else {
        lessonsOfDay = (teacherLessons as JournalDay[]).filter(lesson => {
          return new Date(lesson.date).getDay() === day;
        });
      }

      const filledDay = {
        dayIndex: day,
        lessons: []
      };

      let slotCandidates: ScheduledLesson[] | JournalDay[] = [];
      lessons.forEach(lessonSlot => {
        if (isDraftOn) {
          slotCandidates = (lessonsOfDay as ScheduledLesson[]).filter(lesson => {
            return lessonSlot.number === lesson.lessonNumber;
          });
        } else {
          slotCandidates = (lessonsOfDay as JournalDay[]).filter(lesson => {
            return lessonSlot.number === lesson.lesson;
          });
        }

        filledDay.lessons.push(slotCandidates);
      });

      return filledDay;
    });

    return {...teacherOrVacancy, days};
  });
};

export const joinWeeklyWorkLoadLimitWithTeachers =
  (entities: (Teacher | Vacancy)[], workloads: Workload[], schoolId: number): (Teacher | Vacancy)[] => {
    return entities.map(entity => {
      if (isTeacher(entity)) {
        const schoolSubjects = entity.subjects.find(schoolWithSubjects =>
          schoolWithSubjects.schoolId === schoolId)?.subjects;

        const weeklyWorkLoadLimit = workloads.filter(load =>
          entity.id === load.teacherId && schoolSubjects?.some(subject => subject.name === load.subject));

        return {...entity, weeklyWorkLoadLimit};
      }

      return entity;
    });
  };

export const joinLoadWithTeachers = (entities: (Teacher | Vacancy)[], isDraftOn?: boolean) => {
  return entities.map(entity => {
    let plannedLoad = 0;
    let existingLoad = 0;

    entity.days.forEach(day => {
      day.lessons.forEach(lesson => {
        let anyDraft;
        let anySaved;

        if (isDraftOn) {
          anyDraft = lesson.find(scheduledLesson => scheduledLesson.versions.draft &&
          Object.keys(scheduledLesson.versions.draft).length);
        } else {
          anySaved = lesson.find(scheduledLesson => Object.keys(scheduledLesson).length);
        }

        if (anyDraft !== undefined) plannedLoad++;
        if (anySaved !== undefined) existingLoad++;
      });
    });

    return {
      ...entity,
      load: {plannedLoad, existingLoad}
    };
  });
};

export const isTeacher = (columnItem: Teacher | Vacancy): columnItem is Teacher => {
  return 'code' in columnItem;
};

export function combineClassData(form: ClassesInfo[]): DayWithLessons[] {
  return form.flatMap(currentClass => currentClass.lessonTimeslots);
}

export function checkClassroomsValidation(lessonTimeslots: DayWithLessons[]): boolean {
  const lessons: ScheduledLesson[] = [];

  lessonTimeslots.forEach(day => {
    day.lessons.forEach(lesson => {
      if (lesson instanceof JournalDay) {
        return false;
      } else {
        lessons.push(lesson);
      }
    });
  });

  const classroomsArr: string[] = lessons.flatMap((el => el.classroom ? [el.classroom] : []));

  return !classroomsArr.some(el => el.length > 15);
}

export const getAcademicYearRange = (
  data: NewAcademicYear | NewAcademicYear[],
  selectedSemester: SemesterNumber
): {from: Date, to: Date} => {
  const isList = Array.isArray(data);

  if (isList) {
    const startDate = data.reduce((res: string, current: NewAcademicYear) => {
      return res < current.firstSemester.from ? res : current.firstSemester.from;
    }, data[0].firstSemester.from);

    const endDate = data.reduce((res: string, current: NewAcademicYear) => {
      return res > current.secondSemester.to ? res : current.secondSemester.to;
    }, data[0].secondSemester.to);

    const from = new Date(new Date(startDate).setHours(0,0,0,0));
    const to = new Date(new Date(endDate).setHours(0,0,0,0));

    return {from, to};
  }

  const semester = selectedSemester === SemesterNumber.First ? 'firstSemester' : 'secondSemester';

  return {
    from: new Date(new Date(data[semester].from).setHours(0, 0, 0, 0)),
    to: new Date(new Date(data[semester].to).setHours(0, 0, 0, 0))
  };
};

export function checkVacancyOrTeacherForConflicts(classControlIndex: number, scheduledLessonsOfClass: ScheduledLesson[], classes: AbstractControl, subject: string, teacherOrVacancyId?: string): boolean {
  const classControl = (classes as NitFormArray).at(classControlIndex) as NitForm;
  const groups = (classControl?.get('groups') as NitFormArray)?.value;
  const hasGroups = groups?.length;
  const isGroupChosen = groups?.some((group: SubjectGroup) => group.chosen);
  let chosenGroupId: string;

  if (hasGroups && isGroupChosen) {
    chosenGroupId = groups.find(group => group.chosen).id;
  }

  return scheduledLessonsOfClass.some(scheduledLesson => {
    const checkedVersion = scheduledLesson.versions.draft && 'subject' in scheduledLesson.versions.draft ?
      scheduledLesson.versions.draft : scheduledLesson.versions.saved;

    const matchCheckedVersion = checkedVersion.subject === subject &&
      checkedVersion?.classId === classControl?.value.id &&
      (!hasGroups || isGroupChosen && checkedVersion?.classGroupId === chosenGroupId);

    const teacherIdNotMatch = teacherOrVacancyId ? checkedVersion.teacherId !== teacherOrVacancyId : true;

    return matchCheckedVersion && teacherIdNotMatch;
  });
}

export function sortTeachers(teachers: Teacher[]): Teacher[] {
  return teachers.sort((a, b) => {
    if (a.isActive === b.isActive) {
      return a.shortName.localeCompare(b.shortName);
    }

    return a.isActive ? -1 : 1;
  });
}
