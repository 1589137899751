import {AttachedFile} from '@nit-core/models/class-material';
import {JournalClass, JournalSubjectGroup} from '@nit-core/models/filter';

export class Homework {
  attachments: AttachedFile[];
  content: string;
  date: Date;
  doneCount: number;
  key: number;
  studentsCount: number;
  subject: string;
  journalId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.date = item?.date ? new Date(item.date) : null;
    this.attachments = item.attachments ? item.attachments.map((x: any) => new AttachedFile(x)) : [];
  }
}

export class TrainingPlan {
  day: Date;
  homework: string;
  homeworkAttachments: AttachedFile[];
  homeworkDate: Date;
  homeworkId: string;
  id: string;
  journalId: string;
  classId: string;
  lesson: number;
  lessonContent: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.homeworkDate = item?.homeworkDate ? new Date(item.homeworkDate) : null;
    this.day = item?.day ? new Date(item.day) : null;
    this.homeworkAttachments = item.homeworkAttachments ? item.homeworkAttachments.map((x: any) => new AttachedFile(x)) : [];
  }
}


export class AddHomeworkAnswer {
  userId: string;
  answer: string;
  homeworkId: string;
  homeworkAttachmentIds: string[];

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}


export class HomeworkImplementation {
  id: string;
  content: string;
  isChecked: boolean;
  answer: string;
  date: Date;
  homeworkAttachments: AttachedFile[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.date = item?.date ? new Date(item.date) : null;
    this.homeworkAttachments = item.homeworkAttachments ? item.homeworkAttachments.map((x: any) => new AttachedFile(x)) : [];
  }
}


export class HomeworkClassTeacherInfo {
  classes: TeacherClasses[];
  subjects: TeacherSubjects[];
  groups: SubjectGroups[];
  basicClasses: JournalClass[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.classes = item?.classes ? item.classes.map((x: any) => new TeacherClasses(x)) : [];
    this.subjects = item?.subjects ? item.subjects.map((x: any) => new TeacherSubjects(x)) : [];
    this.basicClasses = item?.basicClasses?.data ? item.basicClasses.data.map((x: any) => new JournalClass(x)) : [];
    this.groups = item?.groups ? item.groups.map((x: any) => new SubjectGroups(x)) : [];
  }
}

export class TeacherClasses {
  classId: string;
  className: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class TeacherSubjects {
  subject: string;
  subjectName: string;
  groups?: JournalSubjectGroup[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.groups = item?.groups ? this.groups.map((x) => new JournalSubjectGroup(x)) : null;
  }
}

export class SubjectGroups {
  groupNumber: string;
  groupId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
