<div #tooltip
     id="tooltip"
     [ngStyle]="{'max-width': data.maxWidth, 'min-width': data.minWidth}">
  <div *ngIf="verticalPosition === 'bottom'"
       class="triangle" [ngStyle]="{'right' : arrowLeft ? '' : '12px'}">
  </div>
  <div *ngIf="data?.topText"
       class="custom-mark-name">
    {{data?.topText}}
  </div>
  <div *ngIf="verticalPosition === 'top'"
       class="bottom-triangle"
       [class.right]="horizontalPosition === 'left'">
  </div>

  <div *ngIf="data?.bottomText">
    <span class="title">{{data?.title}}</span>
    <span class="comment">{{data?.bottomText}}</span>
  </div>
</div>
