import {PipeTransform, Pipe} from '@angular/core';
import {SHORT_DAYS_ENG_ORDER} from '@nit-core/global/domain/constans';

@Pipe({
  name: 'dayOfWeek'
})
export class DayOfWeekPipe implements PipeTransform {
  transform(date: string | Date): string {
    const days = SHORT_DAYS_ENG_ORDER;
    const d = new Date(date);

    return days[d.getDay()];
  }
}
