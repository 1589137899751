import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {AchievementMark} from '@nit-core/models/nush-mark';

@Injectable({providedIn: 'root'})
export class AchievementMarkService extends RestService<AchievementMark> {
  constructor() {
    super(AchievementMark, '/achievement-marks', 'achievement-marks');
  }
}
