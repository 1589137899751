
export class MonthChangelogCreatedAt {
  name: string;
  createdAt: string;
  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class MonthChangelogFinalDate {
  name: string;
  finalDate: string;
  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
