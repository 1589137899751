import {Injectable} from '@angular/core';
import {ApiResponse, Mark} from '@nit-models';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class MarkService extends RestService<Mark> {
  constructor() { super(Mark, '/marks', 'marks'); }

  addAverageRating(marks: Mark[]): Observable<ApiResponse<Mark>> {
    return this.httpPost(`${this.apiHref()}/average`, {marks});
  }
}
