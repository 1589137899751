import {Pipe, PipeTransform} from '@angular/core';
import {Class} from '@nit-models';

@Pipe({
  name: 'classesByNumber'
})
export class ClassesByNumberPipe implements PipeTransform {

  transform(classes: Class[], lessonNumber: number): Class[] {
    return classes ? classes.filter(x => +x.number === lessonNumber).naturalSort('name') : [];
  }

}
