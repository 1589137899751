import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class HttpPendingService {
  isInProgress$: Subject<boolean> = new Subject<boolean>();
  isLoadInProgress$: Subject<IPendingEvent> = new BehaviorSubject<IPendingEvent>(null);

  startProgress(): void {
    this.isInProgress$.next(true);
  }

  stopProgress(): void {
    this.isInProgress$.next(false);
  }

  startGetProgress(event: string): void {
    this.isLoadInProgress$.next({inProgress: true, event});
  }

  stopGetProgress(event: string): void {
    this.isLoadInProgress$.next({inProgress: false, event});
  }
}

export interface IPendingEvent {
  inProgress: boolean;
  event: string;
}
