import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {getPass, SafeguardQuestionsResponse} from '@nit-core/models';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SafeguardCourseService extends RestService<SafeguardQuestionsResponse> {
  constructor() {
    super(SafeguardQuestionsResponse, '/safeguard-courses', 'safeguard-courses');
  }

  sendPass(): Observable<void> {
    return this.httpPost(`${this.apiHref()}/views`, {});
  }

  getPass(): Observable<getPass> {
    return this.httpGet(`${this.apiHref()}/views`, {});
  }

  generatedCertificate(): Observable<void> {
    return this.httpPost(`${this.apiHref()}/certificates `, {});
  }
}
