import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';
import {Homework} from '@nit-core/models/homework';

@Injectable({
  providedIn: 'root'
})
export class HomeworkService extends RestService<Homework> {

  constructor() { super(Homework, '/homeworks', 'homeworks'); }

  getHomework(id: string): Observable<Homework> {
    return this.httpGet(`${this.apiHref()}/${id}`);
  }

}
