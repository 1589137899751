import {NitToastr} from '@nit-services';
import {Component, Input} from '@angular/core';

@Component({
  selector: 'nit-copy-id',
  templateUrl: './copy-id.component.html',
  styleUrls: ['./copy-id.component.scss']
})
export class CopyIdComponent {
  @Input() code: string;

  constructor(private readonly _nitToastr: NitToastr) { }

  copyId(): void {
    const selBox = document.createElement('textarea');
    selBox.value = this.code;
    selBox.style.position = 'fixed';
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._nitToastr.info('ID скопійовано в буфер обміну');
  }
}
