import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {WorkContent} from '@nit-core/models/work-content';


@Injectable({
  providedIn: 'root'
})
export class WorkContentService extends RestService<WorkContent> {
  constructor() { super(WorkContent, '/work-content', 'work-content'); }
}
