import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {NushDecision} from '@nit-core/models/nush-decision';

@Injectable({
  providedIn: 'root'
})
export class NushDecisionService extends RestService<NushDecision> {
  constructor() {
    super(NushDecision, '/nush-decisions', 'nush-decisions');
  }
}
