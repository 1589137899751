export class Greeting {
  id?: string;
  userId?: string;
  sawCard?: boolean = false;
  date?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
