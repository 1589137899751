import {Injectable} from '@angular/core';
import {CourseService} from '@nit-services';
import {Observable, of, switchMap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CourseNameService {
  constructor(private readonly _courseService: CourseService) { }
  getCourseName(courseId: string): Observable<string> {
    return this._courseService.read(courseId).pipe(switchMap(data => {
      if (data) {
        this._courseService.course.next(data);

        return of(data.title);
      }
    }));
  }
}
