export class Channel {
  _id: string;
  audience: Audience[];
  name: string;
  admins: ChannelUser[];

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}


export class Audience {
  school: number;
  roles: string[];

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ChannelUser {
  _id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  fullName: string;
  email: string;
  permissions: Permissions[];
  online: boolean;
  lastSeen: string;
  shortName: string;
  nameAcronyms: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.shortName = item.fullName?.slice(0, 1)?.toUpperCase() + item.fullName?.split(' ')[1]?.slice(0, 1)?.toUpperCase() ||
      item?.fullName?.slice(1, 2)?.toUpperCase();
    this.nameAcronyms = item?.lastName?.slice(0, 1) + item?.firstName?.slice(0, 1);
  }
}

export class Permissions {
  role: string;
  schoolId: number;
  childId?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
