import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'stringTime'
})
export class StringTimePipe implements PipeTransform {
  transform(value: string) {
    return value.split(':').slice(0, 2).join(':');
  }
}
