Array.prototype.naturalSort = naturalSort;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Array<T> {
  naturalSort(propName?: string): T[];
}

function naturalSort<T>(propertyName?: string): T[] {
  this.sort((a: T, b: T) => {
    const aP = propertyName ? a[propertyName] : a;
    const bP = propertyName ? b[propertyName] : b;

    if ((typeof aP === 'number' && typeof bP === 'number') || (aP instanceof Date && bP instanceof Date)) {
      return (aP as any) - (bP as any);
    }

    return aP.localeCompare(bP, undefined, {numeric: true, sensitivity: 'base'});
  });

  return this;
}
