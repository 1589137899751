import {CommonModule} from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {BaseFilterCellComponent} from '@progress/kendo-angular-grid';
import {PositionsOptionsComponent} from '../position-options/positions-options.component';
import {NitTooltipDirectiveModule} from '@nit-core/directives/tooltip/nit-tooltip.module';
import {PositionType} from '@nit-core/models';
import {CompositeFilterDescriptor} from '@progress/kendo-data-query';
import {BehaviorSubject} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {SelectEvent} from '@nit-core/models/fds';

@UntilDestroy({checkProperties: true})
@Component({
  standalone: true,
  selector: 'nit-positions-filter',
  templateUrl: './positions-filter.component.html',
  styleUrls: ['./positions-filter.component.scss'],
  imports: [
    CommonModule,
    NitTooltipDirectiveModule,
    PositionsOptionsComponent
  ]
})
export class PositionsFilterComponent extends BaseFilterCellComponent implements OnInit {
  @ViewChild('positionsSelect') positionsSelect: ElementRef;
  @Input() filter: CompositeFilterDescriptor;
  @Output() opened: EventEmitter<SelectEvent> = new EventEmitter<SelectEvent>();
  @Output() clickOutside: EventEmitter<string> = new EventEmitter<string>();

  selectedOption: PositionType = {type: 'all', text: 'Всі', value: 0};
  selectedOption$: BehaviorSubject<PositionType> = new BehaviorSubject(this.selectedOption);
  isOpened: boolean = false;
  component: string = 'positions';

  @HostListener('document:click', ['$event.target']) onClick(target): void {
    if (this.isOpened) {
      const isOutside = !this.positionsSelect?.nativeElement.contains(target);

      if (isOutside) {
        this.isOpened = false;
        this.clickOutside.emit('positions');
      }
    }
  }

  ngOnInit(): void {
    this.changeOptionSubscription();
  }

  openPopover(): void {
    this.isOpened = !this.isOpened;
    this.opened.emit({
      isOpened: this.isOpened,
      dropdownSelect: this.positionsSelect,
      selectedOption: this.selectedOption
    });
  }

  changeOptionSubscription(): void {
    this.selectedOption$.subscribe(option => {
      this.selectedOption = option;

      switch (option.type) {
      case 'all': {
        this.filter = this.removeFilter('fdsPosition');
        this.filter = this.removeFilter('positions');
        this.applyFilter(this.filter);
        break;
      }
      case 'fds': {
        this.applyFilter(this.updateFilter({
          field: 'fdsPosition',
          operator: 'eq',
          value: option.value
        }));
        break;
      }
      case 'general': {
        this.applyFilter(this.updateFilter({
          field: 'positions',
          operator: 'eq',
          value: option.value
        }));
        break;
      }
      }
    });
  }
}
