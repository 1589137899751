import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Transfer} from '@nit-core/models/transfer';

@Injectable({
  providedIn: 'root'
})
export class TransferService extends RestService<Transfer> {

  constructor() {
    super(Transfer, '/transfers', 'transfers');
  }
}
