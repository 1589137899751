import {AuthService} from '@nit-auth';
import {Injectable} from '@angular/core';
import {SchoolService, UserService} from '@nit-services';

@Injectable({
  providedIn: 'root'
})

export class EndSchoolYearService {
  endsOfSchoolYear: boolean = true;

  constructor(private readonly _authService: AuthService,
              private readonly _schoolService: SchoolService,
              private readonly _userService: UserService) {
  }

  checkSchoolStatus(urlChildId?: string, urlSchoolId?: number): boolean {
    this._userService.currentUser$.subscribe(res => {
      if (res) {
        let childId: string = '';
        if (!this._authService.getUserSchoolId()) {
          childId = urlChildId || res.relations?.find(child => this._authService.getUserSchoolId(child.id))?.id;
        }
        if (this._authService.getUserSchoolId() || childId) {
          const getSchoolStatus = this._authService.getUserSchoolId() ? this._schoolService.getSchoolStatus() :
            this._schoolService.getSchoolStatus(childId, urlSchoolId);
          if (urlSchoolId) {
            getSchoolStatus.subscribe((result) => {
              this.endsOfSchoolYear = result.active;
            });
          }
        }
      }
    });

    return this.endsOfSchoolYear;
  }
}
