import {NgModule} from '@angular/core';
import {CopyIdComponent} from './copy-id.component';

@NgModule({
  imports: [],
  declarations: [
    CopyIdComponent,
  ],
  exports: [
    CopyIdComponent,
  ]
})
export class CopyIdModule { }
