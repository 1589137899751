import {Pipe, PipeTransform} from '@angular/core';
import {Profile} from '@nit-models';

@Pipe({
  name: 'namePipeCircle'
})
export class NamePipeCirclePipe implements PipeTransform {

  transform(profile: Profile): string {
    return profile.firstName?.slice(0, 1) + profile.lastName?.slice(0, 1);
  }

}
