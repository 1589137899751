import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ServiceType} from '@nit-core/global/domain/enums';
import {GlobalService} from '@nit-core/services/global/global.service';
import {AppInjector} from '@nit-core/global/nit.injector';

export abstract class HTTPService {
  private _http: HttpClient;
  private readonly _cleanOrigin: string;
  private readonly _cleanPath: string;
  private readonly _prefix: string;

  protected globalService: GlobalService;

  protected get http(): HttpClient {
    return this._http ?? (this._http = this._getHttpClient());
  }

  protected get apiOrigin(): string {
    return this._cleanOrigin;
  }

  constructor(path: string, origin: string | ServiceType = ServiceType.Api, prefix: string = 'v1') {
    this.globalService = this._getGlobalService();
    this._cleanOrigin = this._getOrigin(origin);
    this._cleanPath = path ? path.trimSymbol('/') : '';
    this._prefix = prefix;
  }

  protected apiHref(): string {
    return `${this.apiBaseHref()}/${this._cleanPath}`;
  }

  protected apiBaseHref(): string {
    return `${this._cleanOrigin}/api/${this._prefix}`;
  }

  protected getApiHeaders(): HttpHeaders {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return new HttpHeaders({'Content-Type': 'application/json', Accept: 'application/json'});
  }

  protected defaultHeaders = (data: any): HttpHeaders | null => data instanceof FormData ? new HttpHeaders() : this.getApiHeaders();
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  protected body = (data: any | FormData): FormData | string => data instanceof FormData ? data : JSON.stringify(data);
  protected aliasHeaders = (alias: string, headers: HttpHeaders): HttpHeaders => alias ? headers.append('Alias', alias) : headers;

  private _getOrigin(origin: string | ServiceType): string {
    switch (origin) {
    case ServiceType.Auth:
      return this.globalService.authUrl;
    case ServiceType.Api:
      return this.globalService.apiUrl;
    case ServiceType.Maintenance:
      return this.globalService.maintenanceUrl;
    default:
      return (origin as string).trimSymbol('/', 'right');
    }
  }

  private readonly _getHttpClient = (): HttpClient => AppInjector.getInjector().get(HttpClient);
  private readonly _getGlobalService = (): GlobalService => AppInjector.getInjector().get(GlobalService);
}
