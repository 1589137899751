import {Injectable} from '@angular/core';
import {ApiResponse} from '@nit-core/models';
import {Decision} from '@nit-core/models/decision';
import {PresenceTotalBody} from '@nit-core/models/presence';
import {Observable} from 'rxjs';
import {RestService} from '@nit-core/services/global/http-services/rest.service';

@Injectable({
  providedIn: 'root'
})
export class DecisionService extends RestService<Decision> {
  constructor() {
    super(Decision, '/decisions', 'decisions');
  }

  getUserVisitingTotal(decisionId: string, asUserId: string, asUserSchoolId: number): Observable<ApiResponse<PresenceTotalBody>> {
    return this.httpGetAll(`${this.apiHref()}/${decisionId}/total-visiting`, {
      asUserId,
      asUserSchoolId,
      factory: PresenceTotalBody
    });
  }
}
