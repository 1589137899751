import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {AchievementMark} from '@nit-core/models/nush-mark';

@Injectable({providedIn: 'root'})
export class GeneralAchievementMarkService extends RestService<AchievementMark> {
  constructor() {
    super(AchievementMark, '/general-achievement-marks', 'general-achievement-marks');
  }
}
