import {Pipe, PipeTransform} from '@angular/core';
import {NitForm} from '@nit-forms';

@Pipe({
  name: 'studentFormErrors'
})
export class StudentFormErrorsPipe implements PipeTransform {

  transform(form: NitForm): boolean {
    Object.keys(form.controls).forEach(controlKey => {
      if (form.controls[controlKey].touched && form.controls[controlKey].errors) {
        return true;
      }
    });

    return false;
  }

}
