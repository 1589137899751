<button type="button" class="remove-btn">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="#F8D3D2" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M13.8112 6.89556L12.2457 6.83813L11.9062 16.107L13.4718 16.1642L13.8112 6.89556Z"/>
      <path d="M10.7834 6.86633H9.2168V16.1353H10.7834V6.86633Z"/>
      <path d="M8.09344 16.1068L7.75402 6.83789L6.18848 6.89535L6.52793 16.1642L8.09344 16.1068Z"/>
      <path d="M0.678711 3.00256V4.56913H2.31109L3.60609 19.2853C3.6416 19.6898 3.98027 19.9999 4.38625 19.9999H15.587C15.9931 19.9999 16.332 19.6895 16.3672 19.2851L17.6622 4.56913H19.321V3.00256H0.678711ZM14.8696 18.4334H5.10352L3.88367 4.56913H16.0897L14.8696 18.4334Z"/>
      <path d="M12.5597 0H7.44218C6.72234 0 6.13672 0.585625 6.13672 1.30547V3.7859H7.70328V1.56656H12.2986V3.7859H13.8651V1.30547C13.8651 0.585625 13.2795 0 12.5597 0Z"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</button>
