import {NitForm} from './nit-form';
import {AbstractControl, AbstractControlOptions, AsyncValidatorFn, FormArray, ValidatorFn} from '@angular/forms';

export class NitFormArray extends FormArray {
  protected _baseControl: NitForm;

  constructor(
    controls: AbstractControl[],
    baseControl: NitForm,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
    super(controls, validatorOrOpts, asyncValidator);
    this._baseControl = baseControl;
  }

  addControl(index?: number): void {
    if (!Number.isNaN(index)) {
      this.insert(index, this._baseControl.clone());
    } else {
      this.push(this._baseControl.clone());
    }
  }

  validate(): boolean {
    this.controls.forEach(control => {
      (control as NitForm).validate();
    });

    return this.controls.some(x => x.valid);
  }
}
