import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {JournalSnapshot} from '@nit-core/models';

@Injectable({
  providedIn: 'root'
})
export class JournalSnapshotService extends RestService<JournalSnapshot> {
  constructor() {
    super(JournalSnapshot, '/journal-snapshots', 'journal-snapshots');
  }
}
