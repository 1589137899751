import {NotificationService, NotificationSettings} from '@progress/kendo-angular-notification';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class NitToastr {
  readonly DELAY: number = 5000;

  private readonly _defaultSuccess = 'Зміни збережено!';
  private readonly _defaultError = 'Виникла помилка!';
  private readonly _defaultWarning = 'Заповніть обов\'язкові поля!';
  private readonly _defaultInfo = 'Зміни збережено!';

  constructor(private readonly _notification: NotificationService) { }


  success(message?: string): void {
    this._notification.show(this._notificationSettings(message ?? this._defaultSuccess, 'success'));
  }

  error(message?: string): void {
    this._notification.show(this._notificationSettings(message ?? this._defaultError, 'error'));
  }

  warning(message: string): void {
    this._notification.show(this._notificationSettings(message ?? this._defaultWarning, 'warning'));
  }

  info(message: string): void {
    this._notification.show(this._notificationSettings(message ?? this._defaultInfo, 'info'));
  }

  private _notificationSettings(message: string, type: 'success' | 'warning' | 'error' | 'info'): NotificationSettings {
    return {
      content: message,
      animation: {type: 'fade', duration: 400},
      position: {horizontal: 'right', vertical: 'top'},
      type: {style: type, icon: true},
      hideAfter: this.DELAY,
    } as NotificationSettings;
  }

}
