import {Injectable} from '@angular/core';
import {WindowReferenceService} from '@nit-services';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  private readonly _window: any;
  constructor(private readonly _windowRef: WindowReferenceService) {
    this._window = _windowRef.nativeWindow;
  }

  logPageView(url): void {
    const hit = {event: 'content-view', pageName: url};
    this._pingHome(hit);
  }

  logEvent(event,category,action,label): void {
    const hit = {
      event: event,
      category: category,
      action: action,
      label: label
    };
    this._pingHome(hit);
  }

  logCustomDimensionTest(value): void {
    const hit = {event: 'custom-dimension', value: value};
    this._pingHome(hit);
  }

  private _pingHome(obj): void {
    if (obj) this._window.dataLayer.push(obj);
  }
}
