import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Report} from '@nit-core/models/report';
import {Observable} from 'rxjs';
import {ComparisonReports} from '@nit-core/models/comparison-report';

@Injectable({
  providedIn: 'root'
})
export class ReportsForComparisonService extends RestService<Report> {

  constructor() {
    super(Report, '/identity-reports', 'identity-reports');
  }

  requestReport(filter: ComparisonReports): Observable<void> {
    return this.httpPost(`${this.apiHref()}/comparison`, filter);
  }
}
