import {Injectable} from '@angular/core';
import {FeathersResponse} from '@nit-core/models/common/response';
import auth from '@feathersjs/authentication-client';
import {
  BehaviorSubject, concatMap, EMPTY,
  forkJoin,
  from,
  Observable,
  of,
  Subject
} from 'rxjs';
import {GlobalService} from '@nit-core/services/global/global.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {FeathersHubMethod, FeathersHubService} from '@nit-core/global/domain/enums';
import {filter, finalize, map, switchMap} from 'rxjs/operators';
import {Application, feathers, Query} from '@feathersjs/feathers';
import * as io from 'socket.io-client';
import {socketio} from '@feathersjs/client';
import rest from '@feathersjs/rest-client';
import {environment} from '../../../../environments/environment';
import {ChatCall} from '@nit-core/models/chat-notification';
import {Router} from '@angular/router';
import {ChatActiveCall} from '@nit-core/models/chatCall';
import {NitToastr} from '@nit-services';

@Injectable({
  providedIn: 'root'
})
export class FeathersService {
  public calls$: BehaviorSubject<ChatCall[]> = new BehaviorSubject<ChatCall[]>([]);
  public ringCalls$: BehaviorSubject<ChatCall[]> = new BehaviorSubject<ChatCall[]>([]);
  public activeCall$: BehaviorSubject<ChatActiveCall | null> = new BehaviorSubject<ChatActiveCall | null>(null);
  private readonly _feathers: Application = feathers();
  private readonly _feathersRest: Application = feathers();
  private readonly _hubResults$: Subject<FeathersResponse> = new Subject<FeathersResponse>();
  private readonly _socket = io.connect(environment.chatUrl, {transports: ['websocket']});
  private readonly _rest = rest(environment.chatUrl);

  constructor(private readonly _global: GlobalService,
              private readonly _oauth: OAuthService,
              private readonly _router: Router,
              private readonly _toastr: NitToastr) {
    this._feathers
      .configure(socketio(this._socket))
      .configure(auth({jwtStrategy: 'oidc', locationKey: 'feathers-jwt', platform: 'web'} as any))
      .use('rooms', socketio(this._socket).service('rooms'),
        {
          methods: ['find', 'get', 'create', 'patch', 'remove', 'leave']
        })
      .use('media', socketio(this._socket).service('media'),
        {
          methods: ['find', 'get', 'create', 'patch', 'remove', 'decline']
        });

    this._feathersRest
      .configure(this._rest.fetch)
      .configure(auth({jwtStrategy: 'oidc', locationKey: 'feathers-jwt', platform: 'web'} as any));

    this._feathers.authentication.handleSocket(this._socket);

    this._checkToken().subscribe(res => {
      this._refreshToken(res).subscribe(() => {
        this._feathers.authenticate({
          strategy: 'oidc',
          accessToken: this._oauth.getAccessToken(),
          platform: 'web'
        }).then(() => {
          this._connectToMethods();
        });
      });
    });
  }

  listen(method: FeathersHubMethod): Observable<FeathersResponse> {
    return this._requestWrapper().pipe(
      concatMap(() => this._hubResults$.asObservable()),
      filter(value => {
        return value.method === method;
      })
    );
  }

  remove(method: FeathersHubService, id: string, query?: Query): Observable<FeathersResponse> {
    return this._requestWrapper().pipe(switchMap(() => this._feathers.service(method).remove(id, query)));
  }

  removeChannel(method: FeathersHubService, query: {channel: string}): Observable<FeathersResponse> {
    // @ts-expect-error feathers custom method
    return this._requestWrapper().pipe(switchMap(() => this._feathers.service(method).removeChannel(query)));
  }

  find(method: FeathersHubService, query?: { [key: string]: any }): Observable<FeathersResponse> {
    return this._requestWrapper().pipe(
      concatMap(() => this._feathers.service(method).find({query})),
      map(res => ({method, data: res}))
    );
  }

  create(method: FeathersHubService, query: any): Observable<FeathersResponse> {
    return this._requestWrapper().pipe(switchMap(() => this._feathers.service(method).create(query)));
  }

  patch(method: FeathersHubService, id: string, body?: any, query?: any): Observable<FeathersResponse[]> {
    return this._requestWrapper().pipe(switchMap(() => this._feathers.service(method).patch(id, body, query)));
  }

  get(method: FeathersHubService, id: string, query?: Query): Observable<FeathersResponse> {
    return this._requestWrapper().pipe(
      concatMap(() => this._feathers.service(method).get(id, {query})),
      map(res =>({method, data: res}))
    );
  }

  leave(method: FeathersHubService, query: { room: string }): Observable<FeathersResponse> {
    // @ts-expect-error feathers custom method
    return this._requestWrapper().pipe(
      // @ts-expect-error feathers custom method
      switchMap(() => (this._feathers.service(method)).leave(query)),
      map(res => {
        return ({serviceName: method, data: res});
      })
    );
  }

  decline(method: FeathersHubService, query: { id: string }): Observable<FeathersResponse> {
    // @ts-expect-error feathers custom method
    return this._requestWrapper().pipe(
      // @ts-expect-error feathers custom method
      concatMap(() => (this._feathers.service(method)).decline(query)),
      map(res => {
        return ({serviceName: method, data: res});
      })
    );
  }

  private _refreshTime(): number {
    const refreshTime = this._oauth.getAccessTokenExpiration() - new Date().getTime();

    return refreshTime - 1000 * 30;
  }

  private _connectToMethods(): void {
    Object.values(FeathersHubMethod).forEach(method => {
      const [serviceName, methodName] = method.split(' ');
      this._feathers.service(serviceName).on(methodName, (arg: any) => {
        this._hubResults$.next({method, data: arg});
      });
    });
  }

  private _logOut(): Observable<boolean> {
    return of(false).pipe(
      finalize(() => this._router.navigate(['login'])),
      finalize(() => localStorage.clear()),
      finalize(() => sessionStorage.clear()),
    );
  }

  private _checkToken(): any {
    return forkJoin({
      tokenTimeValid: of(this._refreshTime() > 0),
      hasRefreshToken: of(!!this._oauth.getRefreshToken())
    });
  }

  private _refreshToken(data: any): Observable<any> {
    if (data.tokenTimeValid) {
      return of(true);
    }
    if (!data.hasRefreshToken) {
      this._logOut();
    } else {
      return from(this._oauth.refreshToken()).pipe(
        switchMap(res => {
          return of(this._feathers.authenticate({ // store refreshTime from  jwt.exp - 1h => refresh local storage => this.feathers.reAuthenticate()
            strategy: 'oidc',
            accessToken: res.access_token,
            platform: 'web'
          }));
        }),
      );
    }
  }

  private _requestWrapper(): Observable<any> {
    return this._checkToken().pipe(
      switchMap((res: any) => {
        if (navigator.onLine) {
          return this._refreshToken(res);
        } else {
          this._toastr.warning('Перевірте, будь ласка, з\'єднання з інтернетом');

          return of(EMPTY);
        }
      }),
    );
  }
}
