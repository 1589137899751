import {SyncRecord, SyncResolutionVM} from '@nit-core/models';
import {RestService} from '../global/http-services/rest.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {SyncInstanceBody} from '@nit-core/models/aikom';

@Injectable({providedIn: 'root'})
export class AikomSyncService extends RestService<SyncInstanceBody> {
  constructor() {
    super(SyncInstanceBody, 'aikom/sync', 'aikom-sync');
  }

  getNextUser(id: string): Observable<SyncRecord> {
    return this.httpGet(`${this.apiHref()}/${id}/users`);
  }

  resolveSyncCase(id: string, userId: string, body: SyncResolutionVM): Observable<SyncRecord> {
    return this.httpPut(`${this.apiHref()}/${id}/users/${userId}`, body);
  }
}
