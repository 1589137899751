import {MonthChangelogCreatedAt, MonthChangelogFinalDate} from '@nit-core/models/month-changelog-created-at';
import {LeveledAssessmentType} from '@nit-core/global/domain/enums';
import {JournalClass, JournalSubjectGroup, Subject} from '@nit-core/models/filter';

export class MarksChangelog {
  changeType: number;
  classId: string;
  columnType: number;
  comment: string;
  newMark: MarkForChange;
  oldMark: MarkForChange;
  subject: string;
  subjectId: string;
  isFds: boolean;
  teacherFullName: string;
  teacherId: string;
  userFullName: string;
  userId: string;
  createdAt: Date;
  day?: Date;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.newMark = item.newMark ? new MarkForChange(item.newMark) : null;
    this.oldMark = item.oldMark ? new MarkForChange(item.oldMark) : null;
    this.createdAt = item.createdAt ? new Date(item.createdAt) : null;
    this.day = item.day ? new Date(item.day) : null;
  }
}

export class Olympiads {
  paid: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class MarkForChange {
  absense: number;
  createdAt: string;
  day: string;
  id: string;
  isBlockedForChanging: boolean;
  journalDayId: string;
  journalId: string;
  rating: number;
  scheduleId: string;
  sick: boolean;
  updatedAt: string;
  userId: string;
  customMark?: number;
  description?: string;
  classId: string;
  className: string;
  subject: string;
  leveledMark?: LeveledAssessmentType;
  isVerbalFormed?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class OlympiadsStatistic {
  topCodeApplieds: TopCodeApplieds[];
  totalCodeApplieds: number;
  unicTeachers: number;


  constructor(item: any = {}) {
    Object.assign(this, item);
    this.topCodeApplieds = item.topCodeApplieds ? item.topCodeApplieds.map((x: any) => new TopCodeApplieds(x)) : [];
  }
}

export class TopCodeApplieds {
  code: string;
  schoolName: string;
  codeAppliedCounter: number;
  teacherFullName: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}


export class OlympiadsPayed {
  paymentStatus: string;
  olympiadName: string;
  classNumber: number;
  paymentDate: Date;


  constructor(item: any = {}) {
    Object.assign(this, item);
    this.paymentDate = item.paymentDate ? new Date(item.paymentDate) : null;
  }
}

export class SubjectsForJournalChanges {
  subjectName: string;
  subject: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class SubjectsForNotesJournalChanges extends SubjectsForJournalChanges {
  groups?: JournalSubjectGroup[];

  constructor(values: any = {}) {
    super();
    this.groups = values.groups ? this.groups.map(el => new JournalSubjectGroup(el)) : null;
  }
}

export class TeacherData {
  fullName: string;
  shortName: string;
  teacherId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class StudentsData {
  userId: string;
  userFullName: string;
  shortName?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class JournalChangesData {
  subjects: SubjectsForJournalChanges[];
  teachers: TeacherData[];
  currentCreatedAtSemesterMonths: MonthChangelogCreatedAt[];
  students: StudentsData[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.teachers = item.teachers ? item.teachers.map((x) => new TeacherData(x)) : [];
    this.subjects = item.subjects ? item.subjects.map((x) => new SubjectsForJournalChanges(x)) : [];
    this.currentCreatedAtSemesterMonths = item.currentSemesterMonths ? item.currentSemesterMonths.map((x) => new MonthChangelogCreatedAt(x)) : [];
    this.students = item.students ? item.students.map((x) => new StudentsData(x)) : [];
  }
}

export class StatusForJournalKeeping {
  text: string;
  RemarkAction: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class GroupsForJournalKeeping {
  name: string;
  group: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ClassesForJournalKeeping {
  name: string;
  class: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class JournalRemarksTeacherData {
  teacherFullName: string;
  shortName: string;
  teacherId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class NotesForJournalKeepingChangesData {
  subjects: SubjectsForNotesJournalChanges[];
  teachers: JournalRemarksTeacherData[];
  groups: GroupsForJournalKeeping[];
  status: StatusForJournalKeeping[];
  classes?: ClassesForJournalKeeping[];
  classesBasicData: JournalClass[];
  currentCreatedAtSemesterMonths: MonthChangelogCreatedAt[];
  currentFinalDateSemesterMouths: MonthChangelogFinalDate[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.teachers = item.teachers ? item.teachers.map((x) => new JournalRemarksTeacherData(x)) : [];
    this.subjects = item.subjects ? item.subjects.map((x) => new Subject(x)) : [];
    this.groups = item.groups ? item.groups.map((x) => new GroupsForJournalKeeping(x)) : [];
    this.status = item.status ? item.status.map((x) => new StatusForJournalKeeping(x)) : [];
    this.classes = item.classes ? item.classes.map((x) => new ClassesForJournalKeeping(x)) : [];
    this.classesBasicData = item.classesBasicData ? item.classesBasicData.map((x) => new JournalClass(x)) : [];
    this.currentCreatedAtSemesterMonths = item.currentCreatedAtSemesterMonths ? item.currentCreatedAtSemesterMonths.map((x) => new MonthChangelogCreatedAt(x)) : [];
    this.currentFinalDateSemesterMouths = item.currentFinalDateSemesterMouths ? item.currentFinalDateSemesterMouths.map((x) => new MonthChangelogFinalDate(x)) : [];
  }
}
