import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {
  transform(number: number, wordsToPluralize: string[]): number | string {
    if (!wordsToPluralize) return number;

    if (number % 10 === 1 && number % 100 !== 11) {
      return `${number} ${wordsToPluralize[0]}`;
    }

    if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
      return `${number} ${wordsToPluralize[1]}`;
    }

    return `${number} ${wordsToPluralize[2]}`;
  }
}
