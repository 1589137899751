import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {SchoolEvent} from '@nit-core/models/school-events';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SchoolEventService extends RestService<SchoolEvent> {

  constructor() {
    super(SchoolEvent, '/events', 'events');
  }

  getEventInfo(id: string): Observable<SchoolEvent> {
    return this.httpGet<SchoolEvent>(`${this.apiHref()}/${id}`);
  }

  checkSchoolEventNotification(token: string): Observable<void> {
    return this.httpPost(`${this.apiBaseHref()}/fcm-notifications`, {token});
  }
}
