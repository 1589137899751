<kendo-dropdownbutton *ngIf="items.length"
                      #ddButton
                      [disabled]="!items.length"
                      [data]="items"
                      [class]="classes"
                      [size]="size"
                      [popupSettings]="{
                        align: 'right',
                        popupClass: 'ddbutton-context'
                      }"
                      (itemClick)="selectItemHandler($event)">
  <img src="/assets/icons/dots.svg"
       alt="Three dots"
       class="three-dots"
       [class.active]="ddButton.isOpen">

  <ng-template kendoDropDownButtonItemTemplate let-dataItem>
    <div class="option">
      <img *ngIf="dataItem.icon" alt="icon" [src]="dataItem.icon">
      <span [ngStyle]="{'color': (dataItem.color ? dataItem.color :'var(--text-colors-body-300)')}">
        {{dataItem.text}}
      </span>
    </div>
  </ng-template>
</kendo-dropdownbutton>
