import {Profile} from '@nit-core/models/profile';
import {Subject} from './filter';

export class TeacherReplacement {
  id?: string;
  number: number;
  journalId: string;
  originalTeacherId: string;
  originalTeacherFullName?: string;
  replacementTeacherId: string;
  replacementTeacherFullName?: string;
  date: Date;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.date = item.date ? new Date(item.date) : null;
  }
}

export class ReplacementClass {
  classId: string;
  subjects: ReplacementClassSubject[];
  teacherDays: ReplacementClassTeacherDay[];
  name?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.subjects = item.subjects ? item.subjects.map(x => new Subject(x)) : [];
    this.teacherDays = item.teacherDays ? item.teacherDays.map(x => new ReplacementClassTeacherDay(x)) : [];
  }
}

export class ReplacementClassSubject {
  subject: string;
  journalId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ReplacementClassTeacherDay {
  date: Date;
  day: number;
  lesson: number;
  subject: string;
  journalId: string;
  groupId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.date = item.date ? new Date(item.date) : null;
  }
}

export class ReplacementTeacher {
  teacherId: string;
  teacherFullName: string;
  lessonName: string;
  isBusy: boolean;
  hasSameProfile: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ReplacementTeachers {
  profileTeachers: Profile[];
  nonProfileTeachers: Profile[];
  sameDayTeachersLoaded: boolean = false;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.profileTeachers = item.profileTeachers ? item.profileTeachers.map(x => new ReplacementTeacher(x)) : [];
    this.nonProfileTeachers = item.nonProfileTeachers ? item.nonProfileTeachers.map(x => new ReplacementTeacher(x)) : [];
  }
}

