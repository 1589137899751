import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';
import {Filter} from '@nit-core/models/filter';
import {ApiResponse} from '@nit-models';
import {ChangelogInfo} from '@nit-core/models/class';
import {KendoDataQuery} from '@nit-core/models/common/kendo-data-query';
import {KendoFilterOperator} from '@nit-core/global/domain/enums';

@Injectable({
  providedIn: 'root'
})
export class ClassTeachersChangelogService extends RestService<any> {

  constructor() { super(Filter, '/class-teachers-changelog', '/class-teachers-changelog'); }

  getClassTeachersChangelog(classId: string): Observable<ApiResponse<ChangelogInfo>> {
    const queryFilters: KendoDataQuery = new KendoDataQuery(0, 999);
    queryFilters.pushFilters([{field: 'classId', operator: KendoFilterOperator.Eq, value: classId}]);

    return this.httpGet<ApiResponse<ChangelogInfo>>(this.apiHref(), {
      alias: 'class-teachers-changelog',
      query: queryFilters.query
    });
  }
}
