export class NAvatarUploadValidate {

  fileRestrictions = {
    allowedExtensions: ['image/jpg', 'image/png', 'image/jpeg'],
    maxFileSize: 2097152
  };

  wrongFormatMessage = 'Допустимі формати файлу .jpg .png .jpeg';
  wrongSizeMessage = 'Розмір файлу перевищує допустимий ліміт 2Мб';
  wrongAmountOfFilesMessage = 'Максимальна кількість завантажених файлів  – 1';
  serverErrorMessage = 'Серверна помилка';

  isTypeValid(type: string): boolean {
    return this.fileRestrictions.allowedExtensions.includes(type);
  }

  isSizeValid(size: number): boolean {
    return this.fileRestrictions.maxFileSize >= size;
  }

  isSingleFile(files: any): boolean {
    return files.length < 1;
  }
}
