import {Component, ViewChild} from '@angular/core';
import {ModalComponent} from '@nit-components';

@Component({
  selector: 'nit-update-version-modal',
  templateUrl: './update-version-modal.component.html',
  styleUrls: ['./update-version-modal.component.scss']
})
export class UpdateVersionModalComponent {
  @ViewChild('modal') modal: ModalComponent;

  open(): void {
    this.modal.open();
  }

  reloadPage(): void {
    document.location.reload();
  }
}
