import {Injectable} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreviousRouteService {
  previousUrl$: BehaviorSubject<string> = new BehaviorSubject(null);
  private _currentUrl: string;

  constructor(private readonly router: Router) {
    this._currentUrl = this.router.url;
    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl$.next(this._currentUrl);
        this._currentUrl = event.urlAfterRedirects;
      });
  }
}
