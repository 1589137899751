import {Component, forwardRef, Input, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'nit-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PhoneInputComponent),
    multi: true
  }]
})
export class PhoneInputComponent implements ControlValueAccessor {
  @Input() prefix = '+38';
  isDisabled: boolean;
  value: string;
  propagateChange: any = () => {};
  propagateTouch: any = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(onChange: () => void): void {
    this.propagateChange = onChange;
  }

  registerOnTouched(touch: () => void): void {
    this.propagateTouch = touch;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChange(value: string): void {
    this.propagateChange(value);
  }

  onTouch(): void {
    this.propagateTouch();
  }
}
