import {Component} from '@angular/core';

@Component({
  selector: 'nit-button-plus',
  template: `
    <button type="button" class="add-class">+</button>
  `,
  styleUrls: ['./button-plus.component.scss']
})

export class ButtonPlusComponent {

}
