import {Injectable} from '@angular/core';
import {ApiResponse, EducationPlan} from '@nit-models';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})

export class EducationPlanService extends RestService<EducationPlan> {
  trainingPlanState$: Observable<any>;
  trainingPlanState = {trainingPlanNextDate: null, classData: null, classGroup: null};
  private readonly _trainingPlanSubject: BehaviorSubject<any>;

  constructor() {
    super(EducationPlan, '/training-plans', 'training-plans');
    this._trainingPlanSubject = new BehaviorSubject(this.trainingPlanState);
    this.trainingPlanState$ = this._trainingPlanSubject.asObservable();
  }
  updateTrainingPlanNextDateState(state: ApiResponse<EducationPlan>, type: string): void {
    this.trainingPlanState[type] = state;
    this._trainingPlanSubject.next(this.trainingPlanState);
  }
}
