import {Component, Input} from '@angular/core';

@Component({
  selector: 'nit-input-container',
  templateUrl: './input-container.component.html',
  styleUrls: ['./input-container.component.scss']
})

export class InputContainerComponent {
  @Input() icon: 'mail' | 'password' | '' = '';

  icons = {
    mail: '/assets/icons/auth/mail.svg',
    password: '/assets/icons/auth/password.svg'
  };
}
