import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {DiagnosticWorkMark} from '@nit-core/models/nush-mark';

@Injectable({providedIn: 'root'})
export class DiagnosticWorkMarkService extends RestService<DiagnosticWorkMark> {
  constructor() {
    super(DiagnosticWorkMark, '/diagnostic-work-marks', 'diagnostic-work-marks');
  }
}
