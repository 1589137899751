import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'nit-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss']
})

export class FormGroupComponent {
  @Input() name: string = '';
  @Input() allowDeletion: boolean = false;
  @Output() delete: EventEmitter<void> = new EventEmitter<void>();
}
