import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Vacancy} from '@nit-core/models/vacancy';

@Injectable({providedIn: 'root'})
export class VacancyService extends RestService<Vacancy> {
  constructor() {
    super(Vacancy, '/vacancies', 'vacancies');
  }
}
