import {Directive, ElementRef, HostListener, Input, Optional} from '@angular/core';
import {AbstractControl, NgControl} from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';

@Directive({
  selector: '[nitTrim]'
})
@UntilDestroy()
export class TrimInputDirective {
  @Input() control: AbstractControl;
  constructor(@Optional() private readonly _ngControl: NgControl,
              private readonly _el: ElementRef) {}

  @HostListener('blur') onBlur(): void {
    this._ngControl.control.setValue(this._el.nativeElement.value.trim());
  }
}
