import {Pipe, PipeTransform} from '@angular/core';
import {ChatCall} from '@nit-core/models/chat-notification';

@Pipe({
  name: 'incomingCallStyle'
})
export class IncomingCallStylePipe implements PipeTransform {

  transform(index: number, ringCalls: ChatCall[], isHover: boolean): { [key: string]: string } {
    if (ringCalls.length > 3) {
      return {'right': !isHover ? (40 * (index - 1) - 40)+'px' : (280 * (index - 1) - 280)+'px'};
    } else if (ringCalls.length === 3) {
      return {'right': !isHover ? (40 * index - 40)+'px' : (280 * index - 280)+'px'};
    } else if (ringCalls.length === 2) {
      return {'right': !isHover ? (40 * index - 40)+'px' : !index ? '-140px' : '140px'};
    } else {
      return {'right': '40px'};
    }
  }

}
