import {Injectable} from '@angular/core';
import {ApiResponse, TeacherReplacement} from '@nit-models';
import {Observable} from 'rxjs';
import {HTTPOptions, RestService} from '@nit-core/services/global/http-services/rest.service';
import {ReplacementClass} from '@nit-core/models/teacher-replacement';

@Injectable({providedIn: 'root'})
export class TeacherReplacementService extends RestService<ReplacementClass> {
  constructor() {
    super(ReplacementClass, '/replacements', 'replacements');
  }

  getTeacherSchedule(options: HTTPOptions): Observable<ApiResponse<ReplacementClass>> {
    return this.httpGetAll(`${this.apiHref()}/teacher-schedule`, options);
  }

  getTeachersForReplace(options: HTTPOptions): Observable<any> {
    return this.httpGetAll(`${this.apiHref()}/teachers`, options);
  }

  createReplaceRecord(data: TeacherReplacement[]): Observable<any> {
    return this.httpPost(this.apiHref(), {data: data});
  }

  getReplacementRecords(options: HTTPOptions): Observable<ApiResponse<TeacherReplacement>> {
    return this.httpGet(this.apiHref(), options);
  }
}
