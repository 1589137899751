import {Pipe, PipeTransform} from '@angular/core';
import {HealthCardGroupType} from '@nit-core/global/domain/enums';

@Pipe({name: 'healthGroup'})
export class HealthGroupPipe implements PipeTransform {
  private readonly _healthGroups: (string | HealthCardGroupType)[] = Object.values(HealthCardGroupType).filter((val: unknown) => typeof val === 'string');

  private readonly _healthGroupsMap: {[key: string]: string} = {
    Main: 'Основна',
    Preparation: 'Підготовча',
    Special: 'Спеціальна'
  };

  transform(value: number | string, customEnding?: string): string {
    const group = this._healthGroups[Number(value)];

    if (!group) return '';

    if (customEnding) {
      return this._healthGroupsMap[group].slice(0, -1) + customEnding;
    }

    return this._healthGroupsMap[group];
  }
}
