import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';
import {FileId} from '@nit-core/models/material-attachment';
import {IdentityAttachments} from '@nit-core/models/comparison-report';

@Injectable({
  providedIn: 'root'
})
export class UploadFilesComparisonReportService extends RestService<IdentityAttachments> {

  constructor() {
    super(IdentityAttachments, '/identity-attachments', 'identity-attachments');
  }

  uploadFiles(data: FormData): Observable<FileId> {
    return this.httpPost(`${this.apiHref()}/temporary`, data);
  }
}
