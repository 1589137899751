import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {HTTPService} from '@nit-core/services/global/http-services/http.service';
import {ApiResponse, SimpleSchool} from '@nit-models';
import {map} from 'rxjs/operators';
import {Subject} from '@nit-core/models/subject';

@Injectable({providedIn: 'root'})
export class SearchService extends HTTPService {
  private readonly _strType: any = String;

  constructor() {
    super('/search');
  }

  regeons(): Observable<ApiResponse<string>> {
    return this.http.get<ApiResponse<string>>(`${this.apiHref()}/regions`, {headers: this.aliasHeaders('search-regions', this.getApiHeaders())})
      .pipe(map(x => this._dataMapper(x)));
  }

  cities(region: string): Observable<ApiResponse<string>> {
    const params = new HttpParams({fromObject: {region}});

    return this.http.get<string[]>(`${this.apiHref()}/cities`, {
      headers: this.aliasHeaders('search-cities', this.getApiHeaders()),
      params
    })
      .pipe(map(x => this._dataMapper(x)));
  }

  schools(region: string, city: string): Observable<ApiResponse<SimpleSchool>> {
    const params = new HttpParams({fromObject: {region, city}});

    return this.http.get<ApiResponse<SimpleSchool>>(`${this.apiHref()}/schools`, {
      headers: this.aliasHeaders('search-shools', this.getApiHeaders()),
      params
    })
      .pipe(map(x => new ApiResponse<SimpleSchool>(x, SimpleSchool)));
  }

  getSubjects(schoolId: string): Observable<ApiResponse<Subject>> {
    const params = new HttpParams({fromObject: {schoolId}});

    return this.http.get<Subject[]>(`${this.apiHref()}/subjects`, {
      headers: this.aliasHeaders('search-subjects', this.getApiHeaders()),
      params
    }).pipe(map(res => new ApiResponse<Subject>(res, Subject)));
  }

  private _dataMapper(response: any): ApiResponse<string> {
    const resp = new ApiResponse<string>(response, this._strType);
    resp.data = resp.data.map(t => t.toString());

    return resp;
  }
}
