import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeavePageService {
  isLocationChanged$: BehaviorSubject<string> = new BehaviorSubject<string>('not-changed');
  isApprovedRoute$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  updateState(state: string): void {
    this.isLocationChanged$.next(state);
  }
}
