import {Injector} from '@angular/core';

export class AppInjector {
  private static _injector: Injector;

  static setInjector(injector: Injector): void {
    AppInjector._injector = injector;
  }

  static getInjector(): Injector {
    return AppInjector._injector;
  }
}
