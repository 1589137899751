export class DashboardJournal {

  subject: string;
  className: string;
  classId: string;
  groupId: string;
  journalId?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
