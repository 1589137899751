import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {StudentMovement} from '@nit-core/models/student-movement';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})

export class StudentsMovementService extends RestService<StudentMovement> {
  constructor() {
    super(StudentMovement, '/movement-table', 'movement-table');
  }

  getClassMovement(classId: string): Observable<StudentMovement> {
    return this.httpGet(`${this.apiHref()}/${classId}`);
  }

  updateStudentMovement(updateMovement: StudentMovement): Observable<StudentMovement> {
    return this.httpPut(this.apiHref(), updateMovement);
  }
}

