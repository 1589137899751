export class NushTableColumn {
  id?: string;
  parentId: string;
  name?: string;
  domainId?: string = null;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
