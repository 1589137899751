import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {UserWithTransfers} from '@nit-core/models/transfer';

@Injectable({
  providedIn: 'root'
})
export class TransfersGridService extends RestService<UserWithTransfers> {

  constructor() {
    super(UserWithTransfers, '/transfers/users', 'transfers/users');
  }
}
