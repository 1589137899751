import {ComparisonState, ComparisonType} from '@nit-core/global/domain/enums';

export class ComparisonReport {
  fileIds: string[];
  steps?: Steps[];
  totalUnmatchedUsersCount?: number;
  description?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.steps = item.steps ? item.steps.map((x: any) => new Steps(x)) : [];
  }
}

export class IdentityAttachments {
  fileId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class Steps {
  id: string;
  comparisonType: ComparisonType;
  errorMessage: string;
  fileNames: string[];
  iteration: number;
  state: ComparisonState;
  unmatchedUsersCountInFiles: number;
  unmatchedUsersCountInNit: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ComparedUsers {
  id: string;
  comparisonId: string;
  iteration: number;
  comparisonType: number;
  duplicateType: number;
  fileName: string;
  userId: string;
  fullNameFromFile: string;
  sexFromFile: number;
  birthDayFromFile: string;
  fullNameFromNit: string;
  emailFromNit: string;
  codeFromNit: string;
  emailExists: string;
  codeExists: string;
  schoolNameFromNit: string;
  schoolIdFromNit: number;
  sexFromNit: number;
  birthDayFromNit: string;
  relationsFromNit: RelationsFromNit[];
  addressFromNit: AddressFromNit;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.relationsFromNit = item?.relationsFromNit ? item.relationsFromNit.map((x: any) => new RelationsFromNit(x)) : [];
    this.addressFromNit = item?.addressFromNit ? new AddressFromNit(item.addressFromNit) : null;
  }

}

export class RelationsFromNit {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  code: string;
  emailConfirmed: boolean;
  phoneNumber: string;
  workPlace: WorkPlace;
  relationProfileImage: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.workPlace = item?.workPlace ? new WorkPlace(item.workPlace) : null;
  }
}

export class WorkPlace {
  institutionName: string;
  address: string;
  position: string;
  phone: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class AddressFromNit {
  region: string;
  city: string;
  street: string;
  building: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class DataForStep {
  fileIds: string[];
  iteration: number;
  comparisonType: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ComparisonReports {
  description: string;
  comparisonStepId: string;
  compareResultType: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

