import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ellypsize'
})
export class EllypsizePipe implements PipeTransform {
  transform(value: HTMLElement): Promise<boolean> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(value.offsetWidth < value.scrollWidth);
      }, 0);
    });
  }
}

