import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Mark} from '@nit-models';

@Injectable({
  providedIn: 'root'
})
export class PracticeMarkService extends RestService<Mark> {

  constructor() {
    super(Mark, '/practice-journal-marks', 'practice-journal-marks');
  }
}
