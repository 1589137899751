import {EditedPresence, PresenceJournal, PresenceTotalBody, StudentVisiting} from '@nit-core/models/presence';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {VisitAccounting} from '@nit-core/models/visit-accounting';
import {LessonsPresence} from '@nit-core/models/lessons-presence';
import {Filter} from '@nit-core/models/filter';
import {Injectable} from '@angular/core';
import {ApiResponse, JournalDay, StudentOfJournal} from '@nit-models';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class VisitingService extends RestService<JournalDay> {

  constructor() {
    super(JournalDay, '/visiting', 'visiting');
  }

  getVisiting(filter: Filter): Observable<PresenceJournal> {
    return this.httpGet(this.apiHref(), {query: filter});
  }

  addVisiting(data: StudentVisiting[]): Observable<StudentVisiting> {
    return this.httpPost(this.apiHref(), data);
  }

  updateVisiting(data: { visitingStatus: EditedPresence[] }): Observable<void> {
    return this.httpPut(this.apiHref(), data);
  }

  deleteVisiting(data: { ids: string[] }): Observable<void> {
    return this.httpDelete(this.apiHref(), {query: this.normalizeParams(data)});
  }

  getVisitingTotal(visitingFilter: VisitAccounting, userId?: string, schoolId?: number): Observable<ApiResponse<PresenceTotalBody>> {
    return this.httpGetAll(`${this.apiHref()}/total`, {
      query: this.normalizeParams(visitingFilter),
      asUserId: userId,
      asUserSchoolId: schoolId,
      factory: PresenceTotalBody
    });
  }

  getVisitingDay(userId: string, classId: string, scheduleId: string, date: string): Observable<LessonsPresence> {
    return this.httpGet(`${this.apiHref()}/day`, {query: {userId, classId, scheduleId, date}});
  }

  getVisitingUsers(classId, scheduleId): Observable<ApiResponse<StudentOfJournal>> {
    return this.httpGetAll(`${this.apiHref()}/users`, {query: {classId, scheduleId}, factory: StudentOfJournal});
  }
}
