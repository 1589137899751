import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'daysFromNumbers'
})
export class DaysFromNumbersPipe implements PipeTransform {
  dayShortNames = [null, 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'];
  dayFullNames = [null, 'Понеділок', 'Вівторок', 'Середа', 'Четвер', 'П\'ятниця', 'Субота', 'Неділя'];

  transform(days: number[], isFullFormat: boolean = false): string {
    const separateDays = [...days];
    const usedDayNames = [];

    if (separateDays[0] === 0) {
      separateDays[0] = 7;
    }

    separateDays.sort((a, b) => a - b);

    separateDays.forEach(day => {
      if (isFullFormat) {
        usedDayNames.push(this.dayFullNames[day]);
      } else {
        usedDayNames.push(this.dayShortNames[day]);
      }
    });

    return usedDayNames.join(', ');
  }
}
