import {AfterViewChecked, Component, ElementRef, Input, Renderer2, ViewChild} from '@angular/core';

@Component({
  selector: 'nit-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements AfterViewChecked {
  @ViewChild('NitTooltip', {static: true}) NitTooltip: ElementRef;
  @ViewChild('tooltip') tooltip: ElementRef;

  @Input() arrowLeft: boolean = false;

  data: any;
  horizontalPosition: 'left' | 'right';
  verticalPosition: 'top' | 'bottom' = 'bottom';
  maxWidth: number = 300;
  minWidth: number = 200;
  isSafari: boolean = false;
  topText: string;

  constructor(private readonly _renderer: Renderer2,
              private readonly _elementRef: ElementRef) {
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  ngAfterViewChecked(): void {
    if (this.data) {
      const offsetHeight = this.tooltip.nativeElement?.clientHeight;
      const offsetWidth = this.tooltip.nativeElement?.clientWidth;

      switch (this.verticalPosition) {
      case 'top':
        this._renderer.setStyle(this._elementRef.nativeElement.childNodes[0], 'top', (this.data?.y - offsetHeight - 30) + 'px');
        break;
      case 'bottom':
        if (0 < window.innerHeight - this.data?.y - offsetHeight - 30) {
          this._renderer.setStyle(this._elementRef.nativeElement.childNodes[0], 'top', (this.data?.y + 20) + 'px');
        } else {
          this._renderer.setStyle(this._elementRef.nativeElement.childNodes[0], 'top', (this.data?.y - offsetHeight - 30) + 'px');
          if (this.topText) {
            this._renderer.addClass(this._elementRef.nativeElement.childNodes[0].childNodes[0], 'bottom');
          }
        }
        break;
      }

      switch (this.horizontalPosition) {
      case 'left':
        this._renderer.setStyle(this._elementRef.nativeElement.childNodes[0], 'right', (window.innerWidth - this.data?.x - 30) + 'px');
        break;
      case 'right':
        this._renderer.setStyle(this._elementRef.nativeElement.childNodes[0], 'left', (this.data?.x - 20) + 'px');
        if (this.topText) {
          this._renderer?.addClass(this._elementRef.nativeElement.childNodes[0].childNodes[0], 'left');
        }
        break;
      default:
        if (window.innerWidth - this.data?.x - 250 - offsetWidth > 0 || !this.isSafari) {
          this._renderer.setStyle(this._elementRef.nativeElement.childNodes[0], 'left', (this.data?.x - 20) + 'px');
          if (this.topText) {
            this._renderer?.addClass(this._elementRef.nativeElement.childNodes[0].childNodes[0], 'left');
          }
        } else {
          this._renderer.setStyle(this._elementRef.nativeElement.childNodes[0], 'right', (window.innerWidth - this.data?.x - 30) + 'px');
        }
      }
    }
  }
}
