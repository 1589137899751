<div class="input-container" [class.input-disabled]="isDisabled">
  <span class="left-icon">{{prefix}}</span>
  <div>
    <kendo-maskedtextbox mask="(000) 000-00-00"
                         [disabled]="isDisabled"
                         [value]="value"
                         (valueChange)="onChange($event)"
                         (blur)="onTouch()">
    </kendo-maskedtextbox>
  </div>
</div>
