import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {JournalDay, JournalDayThematicRangeResponse} from '@nit-models';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {AverageScoreResponse, CustomColumnsForAverageScoreResponse} from '@nit-core/models/journal';

@Injectable({providedIn: 'root'})
export class JournalDayService extends RestService<JournalDay> {
  constructor() { super(JournalDay, '/journal-days', 'journal-days'); }

  getAverageScore(averageColumnId: string): Observable<AverageScoreResponse> {
    return this.httpGet(`${this.apiHref()}/${averageColumnId}/average`);
  }

  getJournalDaysFromFirstThematic(journalDayId: string): Observable<JournalDayThematicRangeResponse> {
    return this.httpGet(`${this.apiHref()}/${journalDayId}/from-first-thematic`);
  }

  getCustomColumnsForAverageScore(id: string, from: string, to: string): Observable<CustomColumnsForAverageScoreResponse> {
    return this.httpGet(`${this.apiHref()}/${id}/custom-columns?from=${from}&to=${to}`);
  }

  moveJournalDay(id: string, isRight: boolean): Observable<void> {
    return this.httpPut(`${this.apiHref()}/${id}/move`, {isRight});
  }
}
