import {HTTPOptions, RestService} from '@nit-core/services/global/http-services/rest.service';
import {Injectable} from '@angular/core';
import {FirstBriefing, UserChecks, FileId} from '@nit-core/models/briefing-journal';
import {Observable} from 'rxjs';
import {StudentOfJournal} from '@nit-core/models/practice-journal';
import {ApiResponse} from '@nit-core/models';
import {BriefingAccess} from '@nit-core/models/briefing-access';

@Injectable({
  providedIn: 'root'
})
export class BriefingJournalService extends RestService<FirstBriefing> {

  constructor() {
    super(FirstBriefing, '/briefing-journals', 'briefing-journals');
  }

  getStudentsOfJournal(briefingId: string): Observable<ApiResponse<StudentOfJournal>> {
    return this.httpGet(`${this.apiHref()}/${briefingId}/users`);
  }

  readBriefing(options?: HTTPOptions): Observable<BriefingAccess> {
    return this.httpGet<BriefingAccess>(`${this.apiHref()}/listen`, options);
  }

  updateBriefing(id: string, options?: HTTPOptions): Observable<void> {
    return this.httpPut(`${this.apiHref()}/${id}/listen`, null, options);
  }

  updateDate(briefingId: string, data: {users: UserChecks[]}): Observable<null> {
    return this.httpPut(`${this.apiHref()}/${briefingId}/user-checks`, data);
  }

  uploadBriefingFile(data: FormData): Observable<FileId> {
    return this.httpPost(`${this.apiHref()}/attachments/temporary`, data);
  }

  downloadBriefingFile(url: string): Observable<Blob> {
    return this.httpGetBlob(url, {});
  }
}
