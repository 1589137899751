import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Workload} from '@nit-core/models';

@Injectable({
  providedIn: 'root'
})
export class TeacherWorkloadService extends RestService<Workload> {
  constructor() {
    super(Workload, '/teacher-workloads', 'teacher-workloads');
  }

  /* getTotalTeacherWorkload(teacherId: string): Observable<any> {
    return this.httpGet(`${this.apiHref()}/calculate/${teacherId}`);
  } */
}
