import {AchievementMarkType, CustomMark, LeveledAssessmentType} from '@nit-core/global/domain/enums';
import {groupByKeys} from '@nit-core/methods';

class Mark {
  id: string;
  description: string;
  rating: number;
  isFinalMark: boolean;
  isVerbalFormed: boolean;
  customDigitalRating: CustomMark;
  leveledAssessmentType: LeveledAssessmentType;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class GrouppedMarks<T> {
  marks: {
    [key: string]: {
      [key: string]: T[] | {
        [key: string]: T[]
      }
    }
  };

  constructor(marks: any = [], colKey: string, rowKey: string, nextRowKey?: string) {
    const keys = nextRowKey ? [colKey, rowKey, nextRowKey] : [colKey, rowKey];
    const groupped = groupByKeys(marks, keys);
    this.marks = groupped;
  }

  public get(colId: string, rowId: string, nextRowKey?: string | number): T | null {
    const value = nextRowKey ? this.marks[colId]?.[rowId]?.[nextRowKey] : this.marks[colId]?.[rowId];

    return value ? value[0] : null;
  }
}

export class StudentsObservationMark extends Mark {
  studentsObservationId: string;
  columnId: string;
  rowId: string;

  constructor(item: any = {}) {
    super(item);
    Object.assign(this, item);
  }
}

export class AchievementMark extends Mark {
  learningOutcomeId: string;
  achievementId: string;
  type?: AchievementMarkType;

  constructor(item: any = {}) {
    super(item);
    Object.assign(this, item);
  }
}

export class CertificateHighClassesMark {
  id?: string;
  achievementId: string;
  learningOutcomeId: string;
  type: AchievementMarkType;
  isVerbalFormed?: boolean = true;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class DiagnosticWorkMark extends Mark {
  diagnosticWorkId: string;
  columnId: string;
  userId: string;

  constructor(item: any = {}) {
    super(item);
    Object.assign(this, item);
  }
}
