import {DiagnosticWorkMark} from './nush-mark';
import {Grading} from './nush-rule';
import {NushTableColumn} from './nush-table-column';

export class DiagnosticWork {
  id?: string;
  teacherIds?: string[];
  classId: string;
  scheduleId: string;
  personalRuleId?: string;
  subject: string;
  name: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class DiagnosticWorkUser {
  firstName: string;
  fullName: string;
  isLeft: boolean;
  lastName: string;
  middleName: string;
  grading: Grading;
  userId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.grading = item.grading ? new Grading(item.grading) : null;
  }
}

export class DiagnosticWorkPage {
  countPages: number;
  itemsPerPage: number;
  diagnosticWorkColumns: NushTableColumn[];
  diagnosticWorkMarks: DiagnosticWorkMark[];
  id: string;
  name: string;
  page: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.diagnosticWorkColumns = item.diagnosticWorkColumns ? item.diagnosticWorkColumns.map((x: any) => new NushTableColumn(x)) : [];
    this.diagnosticWorkMarks = item.diagnosticWorkMarks ? item.attachments.map((x: any) => new DiagnosticWorkMark(x)) : [];
  }

}

