import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {ComparedUsers} from '@nit-core/models/comparison-report';

@Injectable({
  providedIn: 'root'
})
export class ComparedUsersService extends RestService<ComparedUsers> {

  constructor() {
    super(ComparedUsers, '/comparisons/comparison-user-entries', 'comparisons/comparison-user-entries');
  }
}
