import {KendoFilterLogic, KendoFilterOperator, KendoSortDir} from '@nit-core/global/domain/enums';

export class KendoDataQuery {
  skip?: number;
  take?: number;
  sort?: KendoSortDescriptor[];
  filter?: KendoFilterDescriptor;

  private readonly _defaults = {
    skip: 0,
    take: 20,
    sort: [],
    filter: {
      logic: KendoFilterLogic.And,
      filters: []
    }
  };

  /**
   * Return generated query as a property of a class. Ex: yourFilter.query
   * Notes: This object can be used as a parameters in a rest service.
   */
  get query(): any {
    const query: any = {};

    if (this.take) {
      query.take = this.take;
    }
    if (this.skip) {
      query.skip = this.skip;
    }

    query['filter[logic]'] = this.filter.logic ? this.filter.logic : KendoFilterLogic.And;

    const filters = ([...this.filter?.filters ?? []]) as KendoFilter[];

    if (this.filter.filters) {
      const filterTemplate = 'filter[filters]';
      filters.forEach((res, index) => {
        query[`${filterTemplate}[${index}][field]`] = res.field;
        query[`${filterTemplate}[${index}][operator]`] = res.operator;
        query[`${filterTemplate}[${index}][value]`] = res.value instanceof Date
          ? res.operator === KendoFilterOperator.Lte
            ? new Date(res.value.setHours(23, 59, 59) ).toJSON()
            : new Date(res.value.setHours(0, 0, 0) ).toJSON()
          : res.value;
      });
    }

    if (this.sort) {
      const sortTemplate = 'sort';
      this.sort.forEach((value, index) => {
        if (value.dir) {
          query[`${sortTemplate}[${index}][field]`] = value.field;
          query[`${sortTemplate}[${index}][dir]`] = value.dir;
        }
      });
    }

    return query;
  }

  constructor(skip: number = 0,
    take: number = 20,
    sort: KendoSortDescriptor[] = [],
    filter: KendoFilterDescriptor = {
      logic: KendoFilterLogic.And,
      filters: []
    }) {
    this.skip = skip;
    this._defaults.skip = skip;
    this.take = take;
    this._defaults.take = take;
    this.sort = sort;
    this._defaults.sort = sort;
    this.filter = filter;
    this._defaults.filter = filter;
  }

  /**
   * The function that push sorts to class array, with one feature - automatically detect duplicates and delete them.
   */
  pushSorts(sorts: KendoSortDescriptor | KendoSortDescriptor[]): void {
    this.sort = this.sort
      .concat(sorts)
      .filter((sort, index, self) =>
        index === self.findIndex((s) => (s.field === sort.field)));
  }

  /**
   * The function that can take such parameters as:
   * - `string` - delete sort by field
   * - `Array<string>` - the same as above, but with multiples of field
   */
  popSorts(sorts: string | string[]): void {
    if (typeof sorts === 'string') {
      this.sort = this.sort.filter(item => item.field !== sorts as string);
    } else {
      const sortsByFieldToRemove = sorts as string[];
      this.sort = this.sort.filter((items) => !sortsByFieldToRemove.find(rm => (items.field === rm)));
    }
  }

  /**
   * Change filter logic.
   * Available options:
   * - `Or`
   * - `And`
   */
  changeFilterLogic(logic: KendoFilterLogic): void {
    this.filter.logic = logic;
  }

  /**
   * The function that push filters to class array, with one feature - automatically detect duplicates field and operator combination and replace them.
   */
  pushFilters(filters: KendoFilter | KendoFilter[]): void {
    let filtersToPush: KendoFilter[] = [];
    if (Array.isArray(filters)) {
      filtersToPush = filters;
    } else {
      filters = filters as KendoFilter;
      filtersToPush.push(filters);
    }
    filtersToPush.forEach(value => {
      const index = this.filter.filters.findIndex(item =>
        item.field === value.field &&
        item.operator === value.operator);
      if (index === -1) {
        this.filter.filters.push(value);
      } else {
        this.filter.filters[index] = value;
      }
    });
  }

  changeTake(take: number): void {
    this.take = take;
  }

  /**
   * The function that can take such parameters as:
   * - `string` - it will find all occurrences of that value with field, and delete all of them
   * - `Array<string>` - the same as above, but with multiples of field
   * - `KendoFilter` - delete only specific filter, so if there are 2 filters with the same field
   * and values but different operators than it will delete only that match by operator.
   * - `Array<KendoFilter>` - the same as above, but with multiples of Kendo Filters
   */
  popFilters(filters: string | string[] | KendoFilter | KendoFilter[]): void {
    if (typeof filters === 'string') {
      this.filter.filters = this.filter.filters.filter(item => item.field !== filters as string);
    } else {
      const isArray = Array.isArray(filters);
      if (isArray && (filters as []).every(filter => typeof filter === 'string')) {
        const filtersByFieldToRemove = filters as string[];
        this.filter.filters = this.filter.filters
          .filter((items) => !filtersByFieldToRemove.find(rm => (items.field === rm)));
      } else {
        if (isArray && (filters as []).every(filter => typeof filter === 'object')) {
          const filtersToRemove = filters as KendoFilter[];
          this.filter.filters = this.filter.filters
            .filter((items) => !filtersToRemove.find(rm => (
              rm.field === items.field &&
                        rm.operator === items.operator &&
                        rm.value === items.value)
            ));
        } else {
          const filterToRemove = filters as KendoFilter;
          this.filter.filters = this.filter.filters
            .filter(item =>
              item.field !== filterToRemove.field &&
                        item.operator !== filterToRemove.operator &&
                        item.value !== filterToRemove.value);
        }
      }
    }
  }

  /**
   * Clear KendoDataQuery to predefined defaults.
   */
  clear(): void {
    this.skip = 0;
    this.take = 20;
    this.sort = [];
    this.filter = {
      logic: KendoFilterLogic.And,
      filters: []
    };
  }

  /**
   * Reset KendoDataQuery to your defaults that was took as values from constructor;
   */
  reset(): void {
    this.skip = this._defaults.skip;
    this.take = this._defaults.take;
    this.sort = this._defaults.sort;
    this.filter = this._defaults.filter;
  }
}

interface KendoSortDescriptor {
  /**
   * The data item field to which the filter operator is applied.
   */
  field: string;
  /**
   * The sort direction. If no direction is set, the descriptor will be skipped during processing.
   *
   * The available values are:
   * - `asc`
   * - `desc`
   */
  dir?: KendoSortDir;
}

interface KendoFilterDescriptor {
  logic: KendoFilterLogic;
  filters: KendoFilter[];
}

export interface KendoFilter {
  field?: string;
  operator?: KendoFilterOperator;
  value?: any;
  logic?: KendoFilterLogic;
  filters?: KendoFilter[];
}
