import {Component} from '@angular/core';
import {PermissionService} from '@nit-core/permission/permission.service';

@Component({
  selector: 'nit-end-school-year',
  templateUrl: './end-school-year.component.html',
  styleUrls: ['./end-school-year.component.scss']
})

export class EndSchoolYearComponent {

  isAdmin = false;

  constructor(private readonly _permissionService: PermissionService) {
    this.isAdmin = this._permissionService.hasPermission(['update:school']);
  }

}
