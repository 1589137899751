import {Injectable} from '@angular/core';
import {Observable, of, switchMap} from 'rxjs';
import {AcademicYearService} from '@nit-core/services';

@Injectable({
  providedIn: 'root'
})
export class ScheduleNameService {

  constructor(private readonly _academicYearsService: AcademicYearService) { }

  getScheduleName(scheduleId: string): Observable<string> {
    if (!scheduleId || scheduleId === 'undefined' || scheduleId === 'general') {
      return of('');
    } else {
      return this._academicYearsService.read(scheduleId).pipe(switchMap(data => of(data.name)));
    }
  }
}
