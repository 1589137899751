import {Pipe, PipeTransform} from '@angular/core';
import {CustomMarkType, LeveledMarkType} from '@nit-core/global/domain/enums';
import {ChildMark} from '@nit-core/models/child-journal';

@Pipe({
  name: 'markTypes'
})
export class MarkTypesPipe implements PipeTransform {
  type: 'customMark' | 'verbalMark' | 'leveledMark';
  customMarkType = CustomMarkType;
  leveledMarkType = LeveledMarkType;

  transform(mark: ChildMark, needClass?: boolean): string {
    this.type = mark?.customMark !== null ? 'customMark'
      : mark?.isVerbalFormed ? 'verbalMark'
        : mark?.leveledMark !== null ? 'leveledMark' : null;

    switch (this.type) {
    case 'customMark':
      return needClass ? 'customMark' : this.customMarkType[mark?.customMark]?.text;
    case 'verbalMark':
      return 'verbalMark';
    case 'leveledMark':
      return needClass ? 'leveledMark' : this.leveledMarkType[mark?.leveledMark]?.text;
    }
  }
}
