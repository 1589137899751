import {GroupsStudents} from '@nit-core/models/class-group';

export class Class {
  id: string;
  number: string;
  letter: string;
  name: string;
  classTeacher: ClassTeacher;
  hasAcademicYear: boolean;
  hasNushRule: boolean;
  schoolRoom: string;
  students: string[];
  groups?: { [key: string]: SubjectGroup[] };
  fullName: string;

  constructor(item: any = {}) {
    Object.assign(this, item);

    this.fullName = `${this.number}-${this.letter}`;
    this.groups = item.groups
      ? Object.keys(item.groups).reduce((c, p) => { c[p] = item.groups[p]?.map(t => new SubjectGroup(t));

        return c; }, {})
      : undefined;
    this.classTeacher = item.classTeacher ? new ClassTeacher(item.classTeacher) : undefined;
  }
}

export class CreateUpdateClassBody {
  id: string;
  number: string;
  letter: string;
  schoolRoom: string;
  classTeacherId: string;
  teacherFullName: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class SubjectGroup {
  id: string;
  name: string;
  chosen?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ClassTeacher {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  fullName: string;
  code: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }

}

export class AssignUserToClass {
  userIds: string[];
  acceptanceDate: Date;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.acceptanceDate = item?.acceptanceDate ? new Date(item.acceptanceDate) : null;
  }

}

export class ManagingGroupUsers {
  scheduleId: string;
  groupsStudents: GroupsStudents[];
  assingingDate?: Date;
  deleteDate?: Date;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.assingingDate = item?.assingingDate ? new Date(item.assingingDate) : null;
    this.deleteDate = item?.deleteDate ? new Date(item.deleteDate) : null;
    this.groupsStudents = item.groupsStudents ? item.groupsStudents.map(x => new GroupsStudents(x)) : [];
  }
}

export class Teacher {
  firstName: string;
  fullName: string;
  id: string;
  lastName: string;
  middleName: string;
  isVacancy?: boolean;
  notificationSeen?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ChangelogInfo {
  changedBy: string;
  classId: string;
  id: string;
  teacher: Teacher;
  teacherId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.teacher = item?.teacher ? new Teacher(item.teacher) : null;
  }
}

export class ChatsClass {
  name: string;
  school: number;
  semester: number;
  unread: number;
  unread_v2?: number;
  _id: string;
  isSchool: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class EducationForms {
  id: string;
  fullName: string;
  classId: string;
  type: number;
  userId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
