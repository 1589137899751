import {AssessmentType} from '@nit-core/global/domain/enums';
import {Grading} from './nush-rule';

export class Achievement {
  id: string;
  classId: string;
  finalMarkType: AssessmentType;
  domainId?: string;
  name: string;
  scheduleId: string;
  subject: string;
  transferId: string;
  grading: Grading;
  userId: string;
  readOnly?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.grading = item.grading ? new Grading(item.grading) : null;
  }
}
