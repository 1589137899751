import {NushTableColumn} from './nush-table-column';
import {Grading} from './nush-rule';

export class StudentsObservation {
  id: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class StudentsObservationPage {
  id: string;
  itemsPerPage: number;
  countPages: number;
  page: number;
  studentsObservationColumns: NushTableColumn[];
  studentsObservationMarks: any[];
  grading: Grading;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.studentsObservationColumns = item.studentsObservationColumns ?
      item.attachments.map((x: any) => new NushTableColumn(x)) : [];
    this.grading = item.grading ? new Grading(item.grading) : null;
  }
}