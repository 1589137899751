import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {StudentsObservationMark} from '@nit-core/models/nush-mark';

@Injectable({providedIn: 'root'})
export class StudentsObservationMarkService extends RestService<StudentsObservationMark> {
  constructor() {
    super(StudentsObservationMark, '/students-observation-marks', 'students-observation-marks');
  }
}
