import {TransferLeaveDetails} from '.';
import {TotalFinalMark} from './total-final-mark';

export class Decision {
  id: string;
  arriveDate?: string;
  arriveTransferId?: string;
  description: string;
  nextClass: boolean;
  commendationLetter: boolean;
  certificateHonors: boolean;
  finishedSchool: boolean;
  repeatCourse: boolean;
  droppedSchool: boolean;
  leaveDate: Date;
  classId?: string;
  userId?: string;
  academicYearId?: string;
  leaveTransferId?: string;
  totalFinalMark?: TotalFinalMark;
  fullName: string;
  leaveDetails?: TransferLeaveDetails;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.totalFinalMark = item.totalFinalMark ? new TotalFinalMark(item.totalFinalMark) : undefined;
    this.leaveDate = item.leaveDate ? new Date(item.leaveDate) : undefined;
    this.leaveDetails = item.leaveDetails ? new TransferLeaveDetails(item.leaveDetails) : undefined;
  }
}

export class DecisionWithStudentName extends Decision {
  shortName: string;

  constructor (item: any = {}) {
    super(item);
    Object.assign(this, item);
  }
}

export class LeaveForm {
  orderNumber: string;
  date: Date;
  leaveDate: Date;
  isLeaveAddressUnknown: boolean;
  region: string;
  city: string;
  school: string;
  class: string;
  arbitraryLeaveInfo: string;
}

export class DecisionConfig {
  nextClass: string[];
  commendationLetter: string[];
  certificateHonors: string[];
  finishedSchool: string[];
  repeatCourse: string[];
  droppedSchool: string[];
}
