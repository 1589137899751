<div *ngIf="breadcrumbs && breadcrumbs.length" class="breadcrumb" [class.breadcrumbMobile]="breadcrumbs.length === 1">
  <div *ngFor= "let breadcrumb of breadcrumbs; let last = last" class="item">
      <a *ngIf="!last" [routerLink]="[breadcrumb.url]">
          {{ breadcrumb.label }}
      </a>
      <span *ngIf="last && !activeCall">{{ breadcrumb.label }}</span>
  </div>
  <div *ngIf="breadcrumbs[breadcrumbs.length - 2]" class="item last-mobile">
    <div class="arrow left"></div>
    <a [routerLink]="[breadcrumbs[breadcrumbs.length - 2].url]">
      {{ breadcrumbs[breadcrumbs.length - 2].label }}
    </a>
  </div>
</div>
