import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResponse, Class, DashboardChart, DashboardJournal, DashboardMark} from '@nit-models';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Diary} from '@nit-core/models/diary';

@Injectable({providedIn: 'root'})
export class DashboardService extends RestService<DashboardJournal> {
  constructor() {
    super(DashboardJournal, '/dashboard', 'dashboard');
  }

  getMarks(userId: string, schoolId?: number): Observable<ApiResponse<DashboardMark>> {
    return this.httpGetAll<DashboardMark>(`${this.apiHref()}/${userId}/marks`,
      {alias: 'dashboard-marks', factory: DashboardMark, asUserId: userId, asUserSchoolId: schoolId});
  }

  getJournal(userId: string): Observable<ApiResponse<DashboardJournal>> {
    return this.httpGetAll(`${this.apiHref()}/${userId}/journals`, {alias: 'dashboard-journal'});
  }

  getMyClass(userId: string): Observable<Class> {
    return this.httpGet<Class>(`${this.apiHref()}/my-class`, {
      alias: 'dashboard-my-class',
      factory: Class,
      asUserId: userId
    });
  }

  userDiary(userId: string, schoolId?: number, date?: Date, isDashboard?: boolean): Observable<ApiResponse<Diary>> {
    return this.httpGetAll<Diary>(`${this.apiHref()}/${isDashboard ? 'teacher-diary' : 'diary'}`,
      {
        alias: 'user-diary',
        query: date ? {date: date.toDateString()} : {},
        factory: Diary,
        asUserId: userId,
        asUserSchoolId: schoolId
      });
  }

  getProgress(userId: string, schoolId?: number, params?: { [key: string]: string }): Observable<DashboardChart> {
    return this._getChart('progress', 'dashboard-progress', userId, schoolId, params);
  }

  getClassProgress(userId: string, params?: { [key: string]: string }): Observable<DashboardChart> {
    return this._getChart('class-progress', 'dashboard-class-marks', userId, null, params);
  }

  getVisiting(userId: string, schoolId?: number, params?: { [key: string]: string }): Observable<DashboardChart> {
    return this._getChart('visiting', 'dashboard-visiting', userId, schoolId, params);
  }

  getClassVisitors(userId: string, params?: { [key: string]: string }): Observable<DashboardChart> {
    return this._getChart('class-visiting', 'dashboard-class-visiting', userId, null, params);
  }

  private _getChart(endpoint: string, alias: string, userId: string, schoolId?: number, params?: { [key: string]: string }): Observable<DashboardChart> {
    return this.httpGet<DashboardChart>(`${this.apiHref()}/${userId}/${endpoint}`, {
      alias, query: params, factory: DashboardChart, asUserId: userId, asUserSchoolId: schoolId
    });
  }
}
