import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'messageText'
})
export class MessageTextPipe implements PipeTransform {
  transform(value: string): string[] {

    const codeIndex = value.indexOf('<pre');

    if (codeIndex === -1) {

      return [value];
    } else {
      const firstPart = value.slice(0, codeIndex);
      const secondPart = value.slice(codeIndex, value.indexOf('</pre') + 7);
      const thirdPart = value.slice(value.indexOf('</pre') + 6);
      
      return [firstPart, secondPart, thirdPart];
    }
  }
}
