import {Pipe, PipeTransform} from '@angular/core';

// Angular incorrectly handles value like 31/12/23 therefore need in this pipe arose
@Pipe({
  name: 'dateWithDots'
})
export class DateWithDotsPipe implements PipeTransform {
  transform(value: string): string {
    const [year, month, day] = value.split('T')[0].split('-');

    return `${day}.${month}.${year}`;
  }
}
