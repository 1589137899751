import {Component, Input, OnInit, Self} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {NitForm} from '@nit-core/forms';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'nit-mark-item',
  templateUrl: './mark-item.component.html',
  styleUrls: ['./mark-item.component.scss'],
})
export class MarkItemComponent implements ControlValueAccessor, OnInit {
  @Input() controlName: string = '';
  @Input() value: boolean | string = false;
  @Input() form: NitForm;
  @Input() isVerbalControl: boolean = false;

  disabled: boolean = false;
  touched: boolean = false;
  model: boolean | string;

  private _value: any;

  get isChecked(): boolean {
    return this.model === this.value;
  }

  constructor(@Self() private readonly ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  onChange = (val: any) => { this._value = val; };
  onTouched = () => { };

  ngOnInit(): void {
    this.ngControl.control?.valueChanges.subscribe(value => {
      if (this.model === value) return;
      this.form.get('rating').setValue(null, {emitEvent: false});
      this.writeValue(value);
    });
  }

  change(): void {
    this.model = this.isChecked ? null : this.value;
    this.onChange(this.model);
  }

  writeValue(val: boolean): void {
    this.model = val;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
