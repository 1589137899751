import {ApiResponse, ClassMaterial, ClassMaterialVM, MaterialAttachment} from '@nit-models';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {RestService} from '@nit-core/services/global/http-services/rest.service';

@Injectable({providedIn: 'root'})
export class ClassMaterialService extends RestService<ClassMaterial> {
  constructor() {
    super(ClassMaterial, '/class-materials', 'class-materials');
  }

  getAllByClassId(classId: string, scheduleId: string, userId: string, schoolId: number): Observable<ApiResponse<ClassMaterialVM>> {
    return this.httpGetAll<ClassMaterialVM>(`${this.apiHref()}/classes/${classId}`, {
      alias: 'class-materials',
      query: {scheduleId},
      asUserId: userId,
      asUserSchoolId: schoolId
    });
  }

  getAllAttachments(query?: { [key: string]: string }): Observable<ApiResponse<MaterialAttachment>> {
    return this.httpGetAll<MaterialAttachment>(`${this.apiHref()}/attachments`, {alias: 'attachments-all', query});
  }

  uploadAttachment(id: string, data: any): Observable<MaterialAttachment> {
    return this.httpPut(`${this.apiHref()}/${id}/upload`, data);
  }

  deleteAttachment(classId: string, attachmentId: string): Observable<MaterialAttachment> {
    return this.httpDelete(`${this.apiHref()}/${classId}/attachment/${attachmentId}`);
  }

  deleteGroupAttachment(classId: string, attachmentId: string, groupId: string): Observable<any> {
    return this.httpDelete(`${this.apiHref()}/${classId}/attachment/${attachmentId}/group/${groupId}`);
  }

  assignAttachment(classId: string, data: any): Observable<any> {
    return this.httpPut(`${this.apiHref()}/${classId}/attach`, data);
  }

  getSecureImage(url: string, childId?: string): Observable<Blob> {
    return this.httpGetBlob(url, {asUserId: childId});
  }
}
