import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {ImportFile} from '@nit-core/models/import-file';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrainingPlanImportService extends RestService<ImportFile> {

  constructor() {
    super(ImportFile, '/training-plans/import', 'training-plans/import');
  }
  getTemplate(): Observable<Blob> {
    return this.httpGetBlob(this.apiHref(), {});
  }
}
