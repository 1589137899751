<div class="empty">
  <ng-container *ngIf="isAdmin; else isNotAdmin">
    <nit-no-data [hasData]="false" [text]="'Відбувається процес завершення поточного навчального року. Усі дані буде <br> ' +
         'заархівовано та збережено. Ви зможете повернутись до роботи з платформою <br> ' + 'незабаром!'">
    </nit-no-data>
  </ng-container>
  <ng-template #isNotAdmin>
    <nit-no-data [hasData]="false" [text]="'Відбувається процес завершення поточного навчального року. Усі дані буде <br> ' +
       'заархівовано та збережено. Ви зможете повернутись до роботи з платформою <br>' + 'незабаром!'">
    </nit-no-data>
  </ng-template>
</div>
