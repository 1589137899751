import {Injectable} from '@angular/core';
import {HTTPOptions, RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';
import {BriefingJournal, BriefingSubject} from '@nit-core/models/briefing-journal';
import {ApiResponse} from '@nit-models';
import {BriefingPlan} from '@nit-core/models/briefing-plan';

@Injectable({
  providedIn: 'root'
})
export class DiscourceJournalService extends RestService<BriefingSubject> {
  constructor() {
    super(BriefingSubject, '/discource-journals', 'discource-journals');
  }

  getDiscourceJournals(options: HTTPOptions): Observable<BriefingJournal> {
    return this.httpGet(this.apiHref(), {
      ...options,
      factory: BriefingJournal
    });
  }

  getDiscourceSubjects(options?: HTTPOptions): Observable<ApiResponse<BriefingSubject>> {
    return this.httpGet(`${this.apiHref()}/subjects`, options);
  }

  editWorkContent(journalId: string, data: BriefingPlan): Observable<any> {
    return this.httpPut<BriefingPlan>(`${this.apiHref()}/${journalId}/work-content/${data.id}`, data);
  }

  deleteWorkContent(journalId: string, id: string): Observable<any> {
    return this.httpDelete(`${this.apiHref()}/${journalId}/work-content/${id}`);
  }
}
