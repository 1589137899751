import {PipeTransform, Pipe} from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhoneFormatPipe implements PipeTransform {
  transform(val: string): string {
    if (val?.length === 10 && !Number.isNaN(Number(val))) {
      return val ? `+38 (${val.slice(0, 3)}) ${val.slice(3, 6)} ${val.slice(6, 8)} ${val.slice(8, 10)}` : '';
    }

    return val;
  }
}
