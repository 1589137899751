import {Pipe, PipeTransform} from '@angular/core';
import {disassembleDate} from '@nit-core/methods';
import {ClassGroup, GroupsStudents} from '@nit-core/models';
import {Transfer} from '@nit-core/models/transfer';

@Pipe({
  name: 'transfer'
})
export class TransferPipe implements PipeTransform {
  transform(transfer: Transfer, group: ClassGroup) {
    let direction: string;
    let unit: string;
    let groupOfStudent: GroupsStudents;
    if (group) {
      groupOfStudent = group.groupsStudents.find(subgroup => transfer.groupId === subgroup.id);
    }
    if (transfer.isArrived) {
      direction = 'Залучений в ';
      unit = groupOfStudent ? `${groupOfStudent.name} групу ` : 'клас ';
    } else {
      direction = 'Вилучений з ';
      unit = !!groupOfStudent && !transfer?.parentTransferId ? `${groupOfStudent.name} групи ` : 'класу ';
    }
    const date: string[] = disassembleDate(transfer.date);

    return direction + unit + `${date[2]}.${date[1]}.${date[0]}`;
  }
}
