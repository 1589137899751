import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, HostListener, Input, Output, TemplateRef, ViewChild, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'nit-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownComponent),
    multi: true
  }]
})
export class DropdownComponent implements ControlValueAccessor {
  @ViewChild('dropdownContainer') dropdownContainer: ElementRef;
  @ContentChild('additionalContent',{static: false}) headerTemplateRef: TemplateRef<any>;

  @Input() data: any[] = [];
  @Input() emptyText: string;
  @Input() textField?: string = '';
  @Input() defaultItem?: any;
  @Input() height: string = '44';
  @Input() justifyContent: string = '';
  @Input() border: boolean = false;
  @Input() valueField?: string;
  @Input() showContentTextSelectItem: boolean = true;
  @Input() disabled: boolean = false;
  @Input() isDraft: boolean = false;
  @Input() selectedItem: any = '';

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() openDropDown: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeDropDown: EventEmitter<void> = new EventEmitter<void>();
  @Output() createDraft: EventEmitter<void> = new EventEmitter<void>();


  isOpened: boolean = false;
  selectedValue: any;

  constructor(private readonly _cdRef: ChangeDetectorRef) {}

  onChange: (selectedItem: any) => void = () => { };
  onTouched: () => void = () => {};

  @HostListener('document:click', ['$event.target']) onClick(target) {
    const isOutside = !this.dropdownContainer.nativeElement.contains(target);
    if (isOutside) {
      this.isOpened = false;
      this.closeDropDown.emit();
    }
  }

  registerOnChange(onChange: (selectedItem: any) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  writeValue(selectedItem: any): void {
    if ((selectedItem || selectedItem === 0) && this.valueField &&
      !selectedItem[this.textField] && this.data?.length) {
      selectedItem = this.data.find(val => val[this.valueField] === selectedItem);
    }

    this.selectedItem = selectedItem;
    this.selectedValue = this.selectedItem;
    this._cdRef.detectChanges();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._cdRef.detectChanges();
  }

  toggleDropdown(): void {
    this.isOpened = !this.isOpened;
    if (this.isOpened) {
      this.openDropDown.emit();
    } else {
      this.closeDropDown.emit();
    }
  }

  choseValue(selectedItem: any): void {
    this.selectedItem = selectedItem;
    this.selectedValue = this.valueField ? selectedItem?.[this.valueField] : selectedItem;

    this.onChange(this.selectedValue);

    this.valueChange.emit(this.selectedValue);
    this.isOpened = false;
  }

  addDraft(): void {
    this.createDraft.emit();
    this.isOpened = false;
  }

  reset(): void {
    this.selectedItem = this.defaultItem || '';
    this.selectedValue = this.valueField ? this.selectedItem?.[this.valueField] : this.selectedItem;
    this.onChange(this.selectedValue);
    this.valueChange.emit(this.selectedValue);
    this._cdRef.detectChanges();
  }
}
