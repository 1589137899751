import {Injectable} from '@angular/core';
import {Olympiads, OlympiadsPayed, OlympiadsStatistic} from '@nit-core/models/marks-changelog';
import {Observable, map, BehaviorSubject} from 'rxjs';
import {ChildAccessibleOlympiad, OlympiadPaymentFormRequest, OlympiadPaymentResponse, OlympiadValidatePaymentRequest} from '@nit-core/models/olympiads';
import {RestService} from '../global/http-services/rest.service';

@Injectable({
  providedIn: 'root'
})

export class OlympiadService extends RestService<ChildAccessibleOlympiad> {
  olympiadData: BehaviorSubject<ChildAccessibleOlympiad[]> = new BehaviorSubject<ChildAccessibleOlympiad[]>(null);

  constructor() { super(ChildAccessibleOlympiad, '/olympiads', 'olympiads'); }

  getStatus(classNumber: number): Observable<Olympiads> {
    return this.http.get<Olympiads>(`${this.apiOrigin}/api/olympiads/check-unpaid-available-olympiads/${classNumber}`);
  }

  getStatusChild(): Observable<ChildAccessibleOlympiad[]> {
    return this.httpGet<ChildAccessibleOlympiad[]>(`${this.apiHref()}/check-accessible-olympiads-child`);
  }

  getAccessibleOlympiads(userId: string, schoolId: number): Observable<ChildAccessibleOlympiad[]> {
    return this.http.get<ChildAccessibleOlympiad[]>(`${this.apiOrigin}/api/olympiads/check-accessible-olympiads-all`, {
      headers: this.getHeaders(userId, schoolId)
    }).pipe(map(response => response.map(x => new ChildAccessibleOlympiad(x))));
  }

  getOlympiadPaymentInfo(childId: string, childSchoolId: number, request: OlympiadPaymentFormRequest): Observable<OlympiadPaymentResponse> {
    return this.http.post<OlympiadPaymentResponse>(`${this.apiOrigin}/api/payment/liqpay`, request, {
      headers: this.getHeaders(childId, childSchoolId)
    });
  }

  validateOlympiadPayment(childId: string, childSchoolId: number, request: OlympiadValidatePaymentRequest) {
    return this.httpPost(`${this.apiOrigin}/api/payment/validate`, request, childSchoolId, childId);
  }

  checkUserOlympiadPayment(id: string): Observable<{result: OlympiadsPayed[]}> {
    return this.httpGet(`${this.apiOrigin}/api/payment/check-user-payment/${id}`);
  }

  checkOlympiadReferalCode(data: {referalCode: string}): Observable<null> {
    return this.httpPut(`${this.apiOrigin}/api/olympiads/referalcode/increase`, data);
  }

  getStatisticReferalCode(code: string): Observable<OlympiadsStatistic> {
    return this.httpGet(`${this.apiOrigin}/api/olympiads/statistic/referalcode/${code}`);
  }
}
