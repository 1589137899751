export class FinalMark {
  journalId: string;
  subject: string;
  firstSemesterMark: Mark;
  secondSemesterMark: Mark;
  dpaMark: Mark;
  yearMark: Mark;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.firstSemesterMark = item.firstSemesterMark ? new Mark(item.firstSemesterMark) : undefined;
    this.secondSemesterMark = item.secondSemesterMark ? new Mark(item.secondSemesterMark) : undefined;
    this.dpaMark = item.dpaMark ? new Mark(item.dpaMark) : undefined;
    this.yearMark = item.yearMark ? new Mark(item.yearMark) : undefined;
  }
}

class Mark {
  rating: number;
  customMark: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
