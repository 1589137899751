import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {SupportProfile} from '@nit-core/models/profile';
import {CreateSchool} from '@nit-core/models/create-school';
import {RestService} from '@nit-core/services/global/http-services/rest.service';

@Injectable({providedIn: 'root'})
export class SupportService extends RestService<SupportProfile> {
  constructor(private readonly _oauthService: OAuthService) { super(SupportProfile, '/support', 'support'); }

  createSchool(school: CreateSchool): Observable<void> {
    return this.httpPost(`${this.apiHref()}/schools`, school);
  }

  confirmSupportPassword(userDetails: FormData): Observable<any> {
    return this.httpPost(`${this.apiHref()}/users/check-support-password`, userDetails);
  }

  updatePassword(newPassword: FormData): Observable<any> {
    return this.httpPut(`${this.apiHref()}/users/update-password`, newPassword);
  }
}
