import * as GroupBy from 'group-array';
import {LONG_DAYS_ENG_ORDER} from './global/domain/constans';
import {JournalDay, JournalStudent} from '@nit-models';
import {BlockedDaysForStudents} from '@nit-core/models/journal-student';

export const groupBy = (xs: any[], key: string): any => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);

    return rv;
  }, {});
};

export function groupByKeys(data: any[], keys: string[]): any {
  return GroupBy(data, keys);
}

export const getRangeOfDays = (startDate: string | Date, endDate: string | Date) => {
  const arr = [];
  for (const dt = new Date(startDate); dt <= new Date(endDate); dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }

  return arr;
};

export const getDayNameByDate = (date: string | Date) => {
  const days = LONG_DAYS_ENG_ORDER;
  const d = new Date(date);

  return days[d.getDay()];
};

export function downloadFile(blob: any, name: string): void {
  const a = generateAnchor(blob);
  a.download = name;
  a.click();
  document.body.removeChild(a);
}

export function displayFile(blob: any): void {
  const a = generateAnchor(blob);
  a.target = '_blank';
  a.click();
  document.body.removeChild(a);
}

export function generateAnchor(blob: any): HTMLAnchorElement {
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);
  const blobURL = URL.createObjectURL(blob);
  a.href = blobURL;

  return a;
}

export function disassembleDate(date: string): string[] {
  return date.split('T')[0].split('-');
}

export function assembleStringDate(date: Date): string {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function getShortName(firstName: string, lastName: string, middleName: string): string {
  const shortFirstName = firstName ? firstName?.slice(0, 1) + '. ' : '';
  const shortMiddleName = middleName ? middleName?.slice(0, 1) + '. ' : '';

  return `${lastName ? lastName : ''} ${shortFirstName}${shortMiddleName}`;
}

export function getShortNameFromFullName(fullName: string): string {
  if (!fullName) {
    return '';
  }
  const names = fullName?.split(' ');

  return `${names[0]} ${names[1].charAt(0)}.${names[2] ? names[2].charAt(0) : ''}`;
}

export function getFullName(firstName: string, lastName: string, middleName: string): string {
  return `${lastName ? lastName : ''} ${firstName} ${middleName}`;
}

export function sortClasses(a, b) {
  const numA: number = parseInt(a.name.match(/\d+/)[0]);
  const numB: number = parseInt(b.name.match(/\d+/)[0]);

  return numA === numB
    ? a.name.localeCompare(b.name)
    : numA - numB;
}

export function prepareBlurredDays(journalDays: JournalDay[], students: JournalStudent[], blurredDaysForStudents: BlockedDaysForStudents): JournalDay[] {
  return journalDays.map(journalDay => {
    const blurredForStudents = {};
    students.forEach(student => {
      const isBlurredCell = blurredDaysForStudents[student.userId]?.includes(journalDay.id);
      if (isBlurredCell) {
        blurredForStudents[student.userId] = isBlurredCell;
      }
    });

    return {...journalDay, blurredForStudents};
  });
}

export function normalizeDate(date: Date): Date {
  return new Date(date.setHours(0, 0, 0, 0));
}

export function getTimeInSeconds(lessonDate: Date): number | null {
  if (!lessonDate) return null;

  const date = new Date(lessonDate);

  return date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
}

