import {SocialGroupType} from '@nit-core/global/domain/enums';

export class SocialGroup {
  id: string;
  classId: string;
  socialGroups: SocialGroupInfo[];
  user: StudentInfo;
  
  constructor(item: any = {}) {
    Object.assign(this, item);
    this.user = item.user ? new StudentInfo(item.user) : null;
    this.socialGroups = item.socialGroups?.length > 0 ? item.socialGroups.map(x => new SocialGroupInfo(x)) : [];
  }
}
  
export class StudentInfo {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;

  constructor(item = {}) {
    Object.assign(this, item);
  }
}

export class SocialGroupInfo {
  type: SocialGroupType;
  from: Date;
  to: Date;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.from = item.from ? new Date(item.from) : null;
    this.to = item.to ? new Date(item.to) : null;
  }
}

export class SocialGroupGeneralStatistic {
  classNumber: number;
  typeCounts: Record<SocialGroupType, number>[];

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
