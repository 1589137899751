import {Injectable} from '@angular/core';
import {HTTPOptions, RestService} from '@nit-core/services/global/http-services/rest.service';
import {PracticeJournal, StudentOfJournal, TeacherPracticeJournal} from '@nit-core/models/practice-journal';
import {Observable} from 'rxjs';
import {ApiResponse} from '@nit-core/models';

@Injectable({
  providedIn: 'root'
})
export class PracticeJournalService extends RestService<PracticeJournal> {
  constructor() { super(PracticeJournal, '/practice-journals', 'practice-journal'); }

  getJournal(params: HTTPOptions): Observable<PracticeJournal> {
    return this.httpGet(this.apiHref(), params);
  }

  getTeachersPracticeJournal(scheduleId: string): Observable<TeacherPracticeJournal> {
    return this.httpGet(`${this.apiHref()}/teacher-journals`, {query: {scheduleId}});
  }

  getStudentsOfJournal(scheduleId: string): Observable<ApiResponse<StudentOfJournal>> {
    return this.httpGetAll<StudentOfJournal>(`${this.apiHref()}/${scheduleId}/users`,
      {alias: 'practice-journal-students', factory: StudentOfJournal});
  }
}
