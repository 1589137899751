<div class="form-group">
  <div *ngIf="!!name" class="form-group-title">
    <div class="legend-wrapper">
      <div class="legend-wrapper-title">{{name}}</div>
      <img *ngIf="allowDeletion" src="../../../../../assets/icons/delete.svg"
           class="delete-btn"
           alt="Delete button"
           (click)="delete.emit()">
      <span *ngIf="allowDeletion"></span>
      <div class="legend-wrapper-hr"></div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
