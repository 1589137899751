import {Injectable} from '@angular/core';
import {EducationForms} from '@nit-core/models/class';
import {ApiResponse, StudentOfJournal} from '@nit-models';
import {forkJoin, Observable} from 'rxjs';
import {KendoFilterOperator} from '@nit-core/global/domain/enums';
import {KendoDataQuery} from '@nit-core/models/common/kendo-data-query';
import {EducationFormService, JournalService} from '@nit-services';

@Injectable({
  providedIn: 'root'
})
export class UpdateStudentsInfoService {
  private readonly _query: KendoDataQuery = new KendoDataQuery(0, 999);

  constructor(private readonly _journalService: JournalService,
              private readonly _educationFormsService: EducationFormService) {}

  combineResults(journalId: string, classId: string): Observable<[ApiResponse<StudentOfJournal>, ApiResponse<EducationForms>]> {
    this._query.clear();
    this._query.pushFilters([{field: 'classId', operator: KendoFilterOperator.Eq, value: classId}]);

    return forkJoin([this._journalService.getStudentsOfJournal(journalId), this._educationFormsService.all({query: this._query.query})]);
  }

  updateDate(res: {data: any[]} | [ApiResponse<StudentOfJournal>, ApiResponse<EducationForms>]): StudentOfJournal[] {
    res[0]?.data.map(item => {
      const obj = res[1].data.find(el => el.userId === item.userId);
      item.educationType = obj ? obj.type : 0;
    });

    return res[0].data;
  }
}
