import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';
import {ClassMaterial} from '@nit-models';
import {FileId} from '@nit-core/models/material-attachment';

@Injectable({
  providedIn: 'root'
})
export class UploadFilesEducationPlanService extends RestService<ClassMaterial> {

  constructor() {
    super(ClassMaterial, '/attachments', 'attachments');
  }

  uploadHomeworkTeacher(data: FormData): Observable<FileId> {
    return this.httpPost(`${this.apiHref()}/temporary`, data);
  }
}
