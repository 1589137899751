<div class="not-found">
  <svg width="506" height="291" viewBox="0 0 506 291" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M57.7292 86.2011C35.369 93.4428 9.45154 104.623 5.25901 127.872C2.59103 142.991 11.3572 158.745 24.5701 166.495C31.0494 170.306 38.9263 172.974 42.3566 179.707C47.5655 190.125 38.164 201.94 28.2544 208.039C18.3448 214.137 6.14833 218.838 1.70171 229.637C-1.22036 236.751 -0.0769445 244.882 2.84512 251.997C13.6441 277.914 45.2786 289.348 73.3559 287.951C101.433 286.553 128.113 275.627 155.555 270.037C250.078 250.726 349.301 294.557 443.696 274.484C458.434 271.308 473.171 266.48 484.478 256.57C497.31 245.517 504.552 228.747 505.568 211.85C506.584 195.715 500.867 177.548 486.511 170.306C478.126 166.113 467.962 166.24 458.815 168.527C452.971 170.052 447.254 172.339 441.155 172.339C435.184 172.339 428.451 169.417 426.799 163.572C425.02 157.474 429.086 151.249 433.914 147.056C443.696 138.671 456.782 134.479 465.548 125.077C474.06 116.057 477.618 102.59 474.823 90.6478C471.901 78.5784 462.626 68.2876 450.938 64.0951C421.082 53.5502 388.685 82.3897 358.194 73.8776C355.907 73.2424 353.493 72.226 352.35 70.3203C351.206 68.2876 351.461 65.8737 352.096 63.5869C355.78 51.2634 370.263 43.5135 370.899 30.6818C371.28 23.6943 367.087 17.2149 361.878 12.5142C326.178 -19.5015 274.598 18.6124 238.262 30.5548C178.677 50.3741 117.695 66.763 57.7292 86.2011Z"
      fill="#38396F"/>
    <path
      d="M198.241 180.724L206.88 175.642C206.88 175.642 205.991 173.101 205.356 171.703C204.721 170.306 204.085 169.035 203.323 167.892C201.926 165.478 198.368 161.158 198.368 161.158L189.729 166.24L178.422 147.183C178.422 147.183 175.373 148.581 173.848 149.343C172.324 150.105 170.926 150.868 169.402 151.757C168.004 152.519 166.734 153.409 165.59 154.298C164.447 155.187 161.906 157.093 161.906 157.093L173.213 176.15L156.189 186.187L151.234 121.774C151.234 121.774 144.882 121.393 141.833 122.155C138.784 122.79 132.939 125.077 132.939 125.077L139.546 213.374L141.579 214.391L181.725 190.633L191.635 207.403C191.635 207.403 194.684 206.006 196.209 205.244C197.733 204.481 199.131 203.719 200.655 202.83C202.053 202.067 203.323 201.178 204.467 200.289C205.61 199.399 208.151 197.494 208.151 197.494L198.241 180.724Z"
      fill="white"/>
    <path
      d="M370.008 173.355C368.611 172.338 367.34 171.449 365.816 170.56C364.418 169.797 363.148 169.035 361.75 168.4C360.353 167.765 357.558 166.367 357.558 166.367L345.997 185.297L329.099 174.879L383.856 140.45C383.856 140.45 381.188 134.606 379.156 132.319C377.123 129.905 372.422 125.839 372.422 125.839L297.465 172.847V175.133L337.23 199.526L327.067 216.042C327.067 216.042 329.735 218.075 331.005 219.091C332.403 220.108 333.673 220.997 335.198 221.886C336.595 222.649 337.866 223.411 339.263 224.046C340.534 224.682 343.456 226.079 343.456 226.079L353.619 209.563L362.131 214.772C362.131 214.772 363.91 212.739 364.799 211.596C365.689 210.452 366.451 209.182 367.213 208.038C368.738 205.625 370.898 200.543 370.898 200.543L362.386 195.334L373.947 176.404C373.947 176.404 371.406 174.371 370.008 173.355Z"
      fill="white"/>
    <path
      d="M283.702 191.784C300.819 174.668 300.819 146.916 283.702 129.799C266.585 112.682 238.833 112.682 221.717 129.799C204.6 146.916 204.6 174.668 221.717 191.784C238.833 208.901 266.585 208.901 283.702 191.784Z"
      fill="#FBDC8E"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M209.297 155.314C222.256 157.22 244.108 147.946 251.095 146.548C265.324 143.753 279.935 147.056 294.418 147.565C293.402 144.388 292.004 141.339 290.353 138.544C285.017 138.798 279.427 137.782 273.964 137.655C263.8 137.401 253.636 140.069 243.345 139.434C238.645 139.18 227.973 134.987 218.317 133.335C213.616 139.561 210.313 147.184 209.297 155.314Z"
          fill="#F3B5B4"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M252.746 117.074C248.299 117.074 243.98 117.709 239.914 118.979C245.377 122.156 256.43 123.299 258.971 123.553C263.545 124.188 268.5 124.442 272.438 121.901L272.565 121.774C266.721 118.725 259.86 117.074 252.746 117.074Z"
          fill="#F3B5B4"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M296.578 161.413C284.001 158.237 270.661 157.983 257.829 160.778C250.969 162.302 244.235 164.716 237.375 166.368C228.736 168.528 219.842 169.544 210.949 169.417C210.568 169.417 210.187 169.417 209.806 169.417C211.584 178.183 215.904 185.933 222.002 192.031C232.547 188.347 242.584 183.392 251.985 177.548C260.243 172.339 268.501 166.241 278.157 165.351C284.128 164.843 290.099 166.368 296.07 167.638C296.324 165.478 296.451 163.446 296.578 161.413Z"
          fill="#F3B5B4"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M294.164 174.88C288.193 173.991 277.648 179.199 274.98 181.486C272.694 183.392 270.661 185.806 268.247 187.585C265.071 189.744 261.259 190.761 257.448 191.65C249.952 193.556 242.456 195.716 234.961 198.129C234.071 198.383 233.055 198.892 232.547 199.654C238.518 202.83 245.379 204.609 252.62 204.609C257.702 204.609 262.53 203.719 267.103 202.195C271.677 195.716 274.345 187.33 281.587 184.281C282.857 183.773 287.05 183.138 290.988 182.122C292.259 179.835 293.402 177.421 294.164 174.88Z"
          fill="#F3B5B4"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M383.475 291C372.549 232.813 321.477 188.728 260.113 188.728C198.75 188.728 147.677 232.813 136.751 291H383.475Z"
          fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M283.236 260.636C282.601 265.082 275.867 267.75 267.99 266.734C260.114 265.718 254.269 261.271 254.905 256.951C255.54 252.505 262.273 249.837 270.15 250.853C277.9 251.87 283.744 256.189 283.236 260.636Z"
          fill="#C7EFF9"/>
    <path
      d="M168.64 102.463C177.621 102.463 184.902 95.1826 184.902 86.2014C184.902 77.2202 177.621 69.9395 168.64 69.9395C159.659 69.9395 152.378 77.2202 152.378 86.2014C152.378 95.1826 159.659 102.463 168.64 102.463Z"
      fill="#ABC3FF"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M168.64 69.9395C165.718 69.9395 163.05 70.7017 160.763 71.9722C160.89 72.2263 161.017 72.3533 161.144 72.4804C162.795 74.132 166.734 74.259 168.894 74.259C172.07 74.3861 175.246 74.0049 178.295 72.9886C175.627 71.2099 172.324 69.9395 168.64 69.9395Z"
          fill="#79A1F2"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M181.09 78.7056C181.598 78.5785 182.107 78.3244 182.615 78.0703C182.234 77.3081 181.725 76.6728 181.217 76.0376C179.566 76.7999 178.041 77.4351 176.39 78.1974C175.119 78.7056 173.849 79.3408 172.578 79.7219C170.672 80.2301 168.513 80.2301 166.607 80.2301C162.414 80.2301 158.349 79.849 154.156 79.3408C153.14 81.5006 152.632 83.7874 152.632 86.3283C152.632 87.4718 152.759 88.6152 153.013 89.7586C153.902 89.3775 154.665 88.8693 155.427 88.3611C157.46 87.0906 159.873 86.2013 162.16 85.439C168.386 83.0251 174.738 80.8654 181.09 78.7056Z"
          fill="#79A1F2"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M183.632 79.8491C182.361 80.9925 181.472 82.5171 180.075 83.5335C178.677 84.6769 177.026 85.5662 175.374 86.2014C174.231 86.7096 172.96 86.9637 171.817 87.5989C171.435 87.853 170.927 88.1071 170.8 88.6153C170.673 89.5046 171.563 90.0128 172.452 90.2669C174.103 90.6481 175.755 90.521 177.534 90.521C179.566 90.394 181.472 90.394 183.505 90.2669C183.886 90.2669 184.267 90.2669 184.521 90.1399C184.775 88.8694 185.029 87.599 185.029 86.2014C184.902 83.9146 184.394 81.7548 183.632 79.8491Z"
          fill="#79A1F2"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M169.147 85.6934C166.225 86.3286 163.43 87.2179 160.762 88.3613C158.984 89.1236 157.205 90.14 155.426 90.7752C154.918 90.9023 154.156 91.0293 153.267 91.0293C154.283 94.0784 156.062 96.6194 158.348 98.6521C158.476 98.398 158.73 98.271 158.857 98.0169C161.271 94.8407 162.922 90.7752 166.479 88.7425C167.115 88.3613 167.75 88.1072 168.385 87.5991C169.02 87.0909 169.274 86.4556 169.147 85.6934Z"
          fill="#79A1F2"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M183.375 93.062C183.248 93.062 182.994 93.062 182.867 93.062C179.437 93.3161 176.007 93.4432 172.449 93.6972C170.289 93.8243 168.003 93.9513 166.224 95.2218C164.826 96.1111 163.937 97.6357 163.048 99.0332C162.794 99.5414 162.158 100.177 161.777 100.939C163.81 101.955 165.97 102.463 168.384 102.463C168.765 101.955 169.146 101.32 169.654 100.812C171.941 97.7627 175.625 95.73 179.437 95.3489C180.453 95.2218 181.47 95.2218 182.486 95.0948C182.613 94.3325 182.994 93.6972 183.375 93.062Z"
          fill="#79A1F2"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M183.503 92.5535C183.884 91.5371 184.265 90.5208 184.52 89.5044C180.327 91.029 175.372 92.2994 170.163 93.3158C164.827 94.3322 159.745 94.9674 155.299 95.0944C155.934 95.9838 156.569 96.746 157.332 97.5083C161.397 97.2542 165.971 96.619 170.671 95.7297C175.245 94.9674 179.692 93.951 183.503 92.5535Z"
          fill="#618DE5"/>
    <path
      d="M152.759 96.4923C146.914 96.4923 142.214 95.603 141.705 92.808C141.197 90.14 144.628 87.472 151.869 84.677C152.504 84.4229 153.267 84.8041 153.521 85.4393C153.775 86.0745 153.394 86.8368 152.759 87.0909C145.39 89.8859 144.119 91.9187 144.119 92.2998C144.373 93.6973 153.521 95.3489 170.037 92.0457C177.024 90.6482 183.377 88.8696 188.077 86.8368C193.413 84.55 194.303 82.8984 194.176 82.6443C194.049 82.2632 192.016 80.7386 183.758 80.9927C183.123 80.9927 182.487 80.4845 182.487 79.7222C182.487 78.9599 182.996 78.4518 183.758 78.4518C191.889 78.1977 196.335 79.4681 196.844 82.1361C197.352 84.4229 194.811 86.7098 189.221 89.2507C184.393 91.4105 177.787 93.3162 170.672 94.7137C165.844 95.603 158.73 96.4923 152.759 96.4923Z"
      fill="white"/>
    <path
      d="M421.735 129.183C428.832 127.817 433.477 120.956 432.11 113.86C430.743 106.764 423.883 102.119 416.786 103.485C409.69 104.852 405.045 111.713 406.412 118.809C407.779 125.905 414.639 130.55 421.735 129.183Z"
      fill="#7B91FF"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M425.528 104.877C423.495 103.734 421.336 103.226 419.176 103.353C419.176 103.48 419.176 103.734 419.303 103.861C419.811 105.639 422.606 107.164 424.004 108.053C426.163 109.324 428.577 110.34 431.118 110.721C429.975 108.434 428.069 106.275 425.528 104.877Z"
          fill="#80C3FF"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M430.992 115.803C431.501 115.93 431.882 115.93 432.39 115.93C432.39 115.295 432.263 114.66 432.136 113.898C430.738 113.771 429.341 113.643 427.816 113.516C426.673 113.389 425.656 113.389 424.513 113.135C422.989 112.754 421.464 111.992 420.066 111.23C417.144 109.578 414.349 107.799 411.554 105.767C410.03 106.91 408.759 108.308 407.743 110.086C407.235 110.975 406.981 111.738 406.727 112.627C407.489 112.754 408.251 112.627 408.886 112.5C410.792 112.373 412.825 112.627 414.73 113.008C420.321 114.025 425.656 114.914 430.992 115.803Z"
          fill="#80C3FF"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M432.39 117.582C431.119 117.836 429.849 118.598 428.451 118.852C427.054 119.106 425.529 119.106 424.004 118.979C422.988 118.852 421.972 118.598 420.955 118.598C420.574 118.598 420.193 118.598 419.939 118.979C419.431 119.487 419.939 120.25 420.447 120.758C421.464 121.647 422.734 122.282 423.877 122.917C425.275 123.68 426.8 124.315 428.197 125.077C428.451 125.204 428.705 125.331 428.959 125.458C429.722 124.696 430.23 123.807 430.865 122.917C431.754 121.012 432.263 119.233 432.39 117.582Z"
          fill="#80C3FF"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M419.812 116.184C417.525 115.549 415.238 115.041 412.824 114.914C411.3 114.787 409.648 114.787 408.123 114.533C407.742 114.406 407.107 114.152 406.472 113.897C405.964 116.438 406.218 118.979 407.234 121.266C407.488 121.139 407.615 121.139 407.869 121.012C410.791 119.615 413.586 117.455 416.763 117.328C417.398 117.328 417.906 117.328 418.541 117.201C419.049 117.201 419.685 116.82 419.812 116.184Z"
          fill="#80C3FF"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M427.054 126.856C426.926 126.729 426.799 126.729 426.672 126.602C424.131 125.459 421.59 124.188 419.05 123.045C417.525 122.283 415.873 121.52 414.095 121.774C412.824 121.901 411.554 122.537 410.41 123.299C410.029 123.553 409.394 123.807 408.759 124.061C409.775 125.459 411.173 126.729 412.824 127.746C413.332 127.492 413.841 127.237 414.349 126.983C417.144 125.713 420.447 125.586 423.369 126.856C424.131 127.11 424.894 127.619 425.656 127.873C426.164 127.492 426.545 127.237 427.054 126.856Z"
          fill="#80C3FF"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M164.066 238.276L147.423 269.783L142.849 267.37C140.436 266.099 139.419 263.05 140.817 260.636L152.759 238.022C154.03 235.608 157.079 234.592 159.493 235.989L164.066 238.276Z"
          fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M371.915 269.148L362.006 244.374L366.834 242.468C369.375 241.452 372.297 242.722 373.313 245.263L379.538 260.763C380.555 263.304 379.284 266.226 376.743 267.242L371.915 269.148Z"
          fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M218.189 199.654L189.35 220.362L186.301 216.17C184.649 213.883 185.157 210.834 187.444 209.182L208.153 194.318C210.44 192.666 213.489 193.174 215.14 195.461L218.189 199.654Z"
          fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M349.173 234.083L320.842 212.612L324.018 208.547C325.67 206.387 328.846 205.879 331.006 207.53L351.46 222.903C353.62 224.555 354.128 227.731 352.476 229.891L349.173 234.083Z"
          fill="white"/>
    <path
      d="M205.812 237.938C215.635 232.951 221.795 225.358 219.572 220.979C217.348 216.599 207.583 217.091 197.76 222.078C187.937 227.065 181.776 234.658 184 239.038C186.223 243.418 195.989 242.925 205.812 237.938Z"
      fill="#C7EFF9"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M199.003 225.953C207.896 221.379 216.789 220.617 219.966 223.666C220.093 222.649 219.966 221.76 219.584 220.998C217.298 216.678 207.515 217.186 197.732 222.141C187.823 227.096 181.725 234.719 183.884 239.165C184.138 239.546 184.393 239.928 184.774 240.309C185.409 235.862 190.999 230.018 199.003 225.953Z"
          fill="#494089"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M340.153 236.116C338.374 238.911 331.895 238.021 325.543 234.083C319.19 230.145 315.633 224.682 317.285 221.887C319.063 219.091 325.543 219.981 331.895 223.919C338.247 227.858 341.805 233.321 340.153 236.116Z"
          fill="#C7EFF9"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M330.117 225.952C335.834 229.509 339.392 234.337 338.629 237.259C339.265 237.005 339.773 236.624 340.027 236.116C341.806 233.321 338.121 227.858 331.769 223.919C325.417 219.981 318.937 219.091 317.159 221.887C317.031 222.141 316.904 222.522 316.777 222.776C319.699 221.505 325.035 222.776 330.117 225.952Z"
          fill="#494089"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M270.023 250.853C262.146 249.837 255.413 252.505 254.777 256.951C254.777 257.333 254.777 257.587 254.777 257.968C256.429 254.411 262.654 252.378 269.515 253.267C276.756 254.284 282.346 258.095 282.601 262.16C282.855 261.652 282.982 261.271 282.982 260.763C283.744 256.189 277.9 251.87 270.023 250.853Z"
          fill="#494089"/>
    <path
      d="M75.6436 146.421C77.8889 146.421 79.7091 144.601 79.7091 142.356C79.7091 140.11 77.8889 138.29 75.6436 138.29C73.3983 138.29 71.5781 140.11 71.5781 142.356C71.5781 144.601 73.3983 146.421 75.6436 146.421Z"
      fill="#A4DAEF"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M315.633 29.2845C314.617 29.2845 313.728 28.3952 313.728 27.3788C313.728 26.3625 314.617 25.4731 315.633 25.4731C316.65 25.4731 317.539 26.3625 317.539 27.3788C317.539 28.3952 316.65 29.2845 315.633 29.2845Z"
          fill="#90C9EA"/>
    <path
      d="M341.931 63.5868C342.984 63.5868 343.837 62.7336 343.837 61.6811C343.837 60.6286 342.984 59.7754 341.931 59.7754C340.879 59.7754 340.025 60.6286 340.025 61.6811C340.025 62.7336 340.879 63.5868 341.931 63.5868Z"
      fill="#90C9EA"/>
    <path
      d="M32.4467 123.934C33.4992 123.934 34.3524 123.081 34.3524 122.028C34.3524 120.976 33.4992 120.123 32.4467 120.123C31.3942 120.123 30.541 120.976 30.541 122.028C30.541 123.081 31.3942 123.934 32.4467 123.934Z"
      fill="#90C9EA"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M297.34 94.3323C297.34 95.6028 296.323 96.7462 294.926 96.7462C293.655 96.7462 292.512 95.7298 292.512 94.3323C292.512 93.0619 293.528 91.9185 294.926 91.9185C296.323 91.9185 297.34 92.9348 297.34 94.3323Z"
          fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M397.578 203.846C397.578 205.116 396.561 206.26 395.164 206.26C393.766 206.26 392.75 205.244 392.75 203.846C392.75 202.576 393.766 201.432 395.164 201.432C396.561 201.432 397.578 202.576 397.578 203.846Z"
          fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M467.962 207.784C467.962 209.055 466.945 210.198 465.548 210.198C464.277 210.198 463.134 209.182 463.134 207.784C463.134 206.514 464.15 205.371 465.548 205.371C466.818 205.498 467.962 206.514 467.962 207.784Z"
          fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M92.6657 242.595C92.6657 243.866 91.6493 245.009 90.2518 245.009C88.9813 245.009 87.8379 243.993 87.8379 242.595C87.8379 241.325 88.8543 240.181 90.2518 240.181C91.6493 240.181 92.6657 241.325 92.6657 242.595Z"
          fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M230.384 69.9393C230.384 71.2097 229.368 72.3532 227.971 72.3532C226.7 72.3532 225.557 71.3368 225.557 69.9393C225.557 68.6688 226.573 67.5254 227.971 67.5254C229.241 67.6524 230.384 68.6688 230.384 69.9393Z"
          fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M201.42 128L204.596 129.778L201.42 131.43L199.514 134.606L197.863 131.43L194.687 129.524L197.863 127.872L199.768 124.696L201.42 128Z"
          fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M351.206 94.4591L353.239 95.7296L351.206 96.7459L349.936 98.9057L348.793 96.7459L346.76 95.6025L348.92 94.4591L350.063 92.2993L351.206 94.4591Z"
          fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M106.133 194.064L108.293 195.334L106.133 196.35L104.99 198.51L103.846 196.35L101.813 195.207L103.846 194.064L105.117 192.031L106.133 194.064Z"
          fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M107.404 109.451L109.436 110.594L107.277 111.738L106.133 113.77L104.99 111.738L102.957 110.467L105.117 109.451L106.26 107.291L107.404 109.451Z"
          fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M43.8802 235.735L45.913 237.005L43.8802 238.022L42.6098 240.181L41.5934 238.022L39.4336 236.878L41.5934 235.735L42.7368 233.702L43.8802 235.735Z"
          fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M421.082 177.167L423.115 178.437L420.955 179.453L419.812 181.613L418.668 179.453L416.636 178.31L418.668 177.167L419.939 175.134L421.082 177.167Z"
          fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M446.618 260.763L448.65 261.906L446.618 263.05L445.347 265.082L444.331 263.05L442.171 261.779L444.331 260.763L445.474 258.603L446.618 260.763Z"
          fill="white"/>
  </svg>
  <div class="title">Ой-ой! Такої сторінки не знайдено!</div>
  <a class="button" [routerLink]="isParent ? '/profile' : '/dashboard'">Назад</a>
</div>
