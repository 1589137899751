<div #positionsSelect
     class="positions-filter"
     NitTooltip
     horizontalPosition="right"
     verticalPosition="top"
     [topText]="selectedOption?.text"
     (click)="openPopover()">
  <div class="selected-option">
    <span class="text">
      {{selectedOption?.text}}
    </span>
  </div>
  <img src="/assets/icons/arrow-down-dark-grey.svg" alt="Arrow down">
</div>
