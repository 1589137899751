import {ComponentRef, Injectable} from '@angular/core';
import {DynamicComponentDirective} from '@nit-core/directives/dynamic-component.directive';
import {Subscription} from 'rxjs';
import {AddEditRateCommentModalComponent} from 'src/app/private/pages/basic-data/pages/nush/components/mark/add-edit-rate-comment-modal/add-edit-rate-comment-modal.component';
import {DeleteRateCommentModalComponent} from 'src/app/private/pages/basic-data/pages/nush/components/mark/delete-rate-comment-modal/delete-rate-comment-modal.component';
import {NusPopoverComponent} from 'src/app/private/pages/basic-data/pages/nush/components/nus-popover/nus-popover.component';
import {NusPopoverEvent} from 'src/app/private/pages/basic-data/pages/nush/models/nush.models';

@Injectable()
export class NusPopoverService {
  addEditRateCommentModal: AddEditRateCommentModalComponent;
  deleteRateCommentModal: DeleteRateCommentModalComponent;
  dynamicComponentDirective: DynamicComponentDirective;
  popoverRef: ComponentRef<NusPopoverComponent>;
  hidePopoverSubscription: Subscription;
  deleteCommentSubscription: Subscription;
  addEditCommentSubscription: Subscription;
  isOpenedStatuses: Record<string, boolean> = {};

  openPopover(event: NusPopoverEvent,
    dynamicComponentDirective: DynamicComponentDirective,
    addEditRateCommentModal?: AddEditRateCommentModalComponent,
    deleteRateCommentModal?: DeleteRateCommentModalComponent,
    isAbsenseAllowed: boolean = true) {

    this.dynamicComponentDirective = dynamicComponentDirective;

    this.clearPopoverSubscriptions();
    const {form, additionalValues, rowId, colId, type, rate, hasComment, grading, cellRef, generalAchievementValue} = event;
    this.isOpenedStatuses[`_${rowId}|${colId}${generalAchievementValue != null ? '|' + generalAchievementValue : ''}`] = true;

    this.popoverRef = this.dynamicComponentDirective.viewContainerRef.createComponent(NusPopoverComponent);
    this.popoverRef.instance.form = form;
    this.popoverRef.instance.rowId = rowId;
    this.popoverRef.instance.colId = colId;
    this.popoverRef.instance.isCommentAllowed = hasComment;
    this.popoverRef.instance.isAbsenseAllowed = isAbsenseAllowed;
    this.popoverRef.instance.additionalValues = additionalValues;
    this.popoverRef.instance.rate = rate;
    this.popoverRef.instance.grading = grading;
    this.popoverRef.instance.cellRef = cellRef;
    this.popoverRef.instance.generalAchievementValue = generalAchievementValue;

    if (generalAchievementValue != null && type !== generalAchievementValue) {
      form.get('isVerbalFormed').patchValue(false, {emitEvent: false});
    }

    if (hasComment) {
      this.addEditRateCommentModal = addEditRateCommentModal;
      this.deleteRateCommentModal = deleteRateCommentModal;
      this._subscribeToAddEditComment(this.popoverRef);
      this._subscribeToDeleteComment(this.popoverRef);
    }
    this._subscribeToHidePopover(this.popoverRef);
  }

  clearPopoverSubscriptions(): void {
    this.dynamicComponentDirective?.viewContainerRef.clear();
    this._clearOpenedStatuses();
    this.addEditCommentSubscription?.unsubscribe();
    this.deleteCommentSubscription?.unsubscribe();
    this.addEditCommentSubscription?.unsubscribe();
    this.hidePopoverSubscription?.unsubscribe();
  }

  private _subscribeToAddEditComment(componentRef: ComponentRef<NusPopoverComponent>): void {
    this.addEditCommentSubscription = componentRef.instance.commentAddEditEmitted.subscribe(({data, rowId, colId}) => {
      this.addEditRateCommentModal.open(data, colId, rowId);
      this.clearPopoverSubscriptions();
    });
  }

  private _subscribeToDeleteComment(componentRef: ComponentRef<NusPopoverComponent>): void {
    this.deleteCommentSubscription = componentRef.instance.deleteCommentEmitted.subscribe(({data, rowId, colId}) => {
      this.deleteRateCommentModal.open(data, colId, rowId);
      this.clearPopoverSubscriptions();
    });
  }

  private _subscribeToHidePopover(componentRef: ComponentRef<NusPopoverComponent>): void {
    this.hidePopoverSubscription = componentRef.instance.hidePopover.subscribe(() => {
      this.clearPopoverSubscriptions();
    });
  }

  private _clearOpenedStatuses(): void {
    Object.keys(this.isOpenedStatuses).forEach(key => {
      this.isOpenedStatuses[key] = false;
    });
  }
}