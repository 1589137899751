import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogoByDateService {
  date: LogoDate[] = [
    {
      logo: '/assets/icons/menu-calendar/logo/constitution_day_sidebar.svg',
      feather: '/assets/icons/menu-calendar/leaves/constitution_day_sidebar.svg',
      menuBar: '/assets/icons/menu-calendar/menu-bar/constitution_day_sidebar.svg',
      start: new Date(2024, 5, 27),
      end: new Date(2024, 5, 28, 23, 59, 59),
      season: false,
      name: 'День конституції ',
      tooltip: 'День конституції'
    },
    {
      logo: '/assets/icons/menu-calendar/logo/28-07-statehood-day.svg',
      feather: '/assets/icons/menu-calendar/leaves/28-07-statehood-day.svg',
      menuBar: '/assets/icons/menu-calendar/menu-bar/28-07-statehood-day.svg',
      start: new Date(2024, 6, 15),
      end: new Date(2024, 6, 19, 23, 59, 59),
      season: false,
      name: 'День Української Державності',
      tooltip: 'День Української Державності - державне свято, що відзначається щороку в Україні 15 липня, у День хрещення Київської Русі - України'
    },
    {
      logo: '/assets/icons/menu-calendar/logo/independence_day.svg',
      feather: '/assets/icons/menu-calendar/leaves/independence_day.svg',
      menuBar: '/assets/icons/menu-calendar/menu-bar/independence_day.svg',
      start: new Date(2024, 7, 20),
      end: new Date(2024, 7, 23, 23, 59, 59),
      season: false,
      name: 'День державного прапора',
      tooltip: '23 серпня - День Державного Прапора України'
    },
    {
      logo: '/assets/icons/menu-calendar/logo/independence_day.svg',
      feather: '/assets/icons/menu-calendar/leaves/independence_day.svg',
      menuBar: '/assets/icons/menu-calendar/menu-bar/independence_day.svg',
      start: new Date(2024, 7, 24),
      end: new Date(2024, 7, 26, 23, 59, 59),
      season: false,
      name: 'День Незалежності',
      tooltip: '33-я річниця Незалежності. День Незалежності України'
    },
    {
      logo: '/assets/icons/menu-calendar/logo/memory_day_of_UA_Defenders.svg',
      feather: '/assets/icons/menu-calendar/leaves/memory_day_of_UA_Defenders.svg',
      menuBar: '/assets/icons/menu-calendar/menu-bar/memory_day_of_UA_Defenders.svg',
      start: new Date(2024, 7, 28),
      end: new Date(2024, 7, 29, 23, 59, 59),
      season: false,
      name: 'День памʼяті захисників України',
      tooltip: 'День пам\'яті захисників України'
    },
    {
      logo: '/assets/icons/menu-calendar/logo/knowledge_day.svg',
      feather: '/assets/icons/menu-calendar/leaves/01-09-knowledge-day.svg',
      menuBar: '/assets/icons/menu-calendar/menu-bar/knowledge_day.svg',
      start: new Date(2024, 8, 1),
      end: new Date(2024, 8, 3, 23, 59, 59),
      season: false,
      name: 'День Знань',
      tooltip: 'День Знань'
    },
    {
      logo: '/assets/icons/menu-calendar/logo/teachers-day.svg',
      feather: '/assets/icons/menu-calendar/leaves/04-10-teachers-day.svg',
      menuBar: '/assets/icons/menu-calendar/menu-bar/teachers-day.svg',
      start: new Date(2024, 9, 4),
      end: new Date(2024, 9, 7, 23, 59, 59),
      season: false,
      name: 'День вчителя',
      tooltip: 'День вчителя'
    },
    {
      logo: '/assets/icons/menu-calendar/logo/freedom_day.svg',
      feather: '/assets/icons/menu-calendar/leaves/14-10-defenders-of-ukraine-day.svg',
      menuBar: '/assets/icons/menu-calendar/menu-bar/defenders of-ukraine-day.svg',
      start: new Date(2024, 9, 14),
      end: new Date(2024, 9, 14, 23, 59, 59),
      season: false,
      name: 'День захисників і захисниць України',
      tooltip: 'День захисників і захисниць України'
    },
    {
      logo: '/assets/icons/menu-calendar/logo/freedom_day.svg',
      feather: '/assets/icons/menu-calendar/leaves/independence_day.svg',
      menuBar: '/assets/icons/menu-calendar/menu-bar/freedom_day.svg',
      start: new Date(2024, 10, 21),
      end: new Date(2024, 10, 21, 23, 59, 59),
      season: false,
      name: 'День Гідності та Свободи',
      tooltip: 'День Гідності та Свободи'
    },
    {
      logo: '/assets/icons/menu-calendar/logo/holodomor_victims_day.svg',
      feather: '/assets/icons/menu-calendar/leaves/memory_day_of_UA_Defenders.svg',
      menuBar: '/assets/icons/menu-calendar/menu-bar/holodomor_victims_day.svg',
      start: new Date(2024, 10, 23),
      end: new Date(2024, 10, 23, 23, 59, 59),
      season: false,
      name: 'День пам\'яті жертв голодоморy',
      tooltip: 'День пам\'яті жертв голодоморy 1932-1933 років та масових штучних голодів 1921-1923 і 1946-1947 років.'
    },
    {
      logo: '/assets/icons/menu-calendar/logo/armed_forces_of_Ukraine.svg',
      feather: '/assets/icons/menu-calendar/leaves/independence_day.svg',
      menuBar: '/assets/icons/menu-calendar/menu-bar/armed_forces_of_Ukraine.svg',
      start: new Date(2024, 11, 6),
      end: new Date(2024, 11, 6, 23, 59, 59),
      season: false,
      name: 'День Збройних Сил України',
      tooltip: 'День Збройних Сил України'
    },
    {
      logo: '/assets/icons/menu-calendar/logo/christmas.svg',
      feather: '/assets/icons/menu-calendar/leaves/christmas-sidebar.svg',
      menuBar: '/assets/icons/menu-calendar/menu-bar/christmas.svg',
      start: new Date(2024, 11, 20),
      end: new Date(2024, 11, 31, 23, 59, 59),
      season: false,
      name: 'Різдво/NY',
      tooltip: 'Щасливого Різдва та нового року!'
    },
    {
      logo: '/assets/icons/menu-calendar/logo/logo-winter.svg',
      feather: '/assets/icons/menu-calendar/leaves/leaves_menu.svg',
      menuBar: '/assets/icons/menu-calendar/menu-bar/winter.svg',
      start: new Date(2024, 0, 1),
      end: new Date(2024, 1, 29, 23, 59, 59),
      season: true,
      name: 'Зима ч1',
      tooltip: ''
    },
    {
      logo: '/assets/icons/menu-calendar/logo/logo-spring-2024.svg',
      feather: '/assets/icons/menu-calendar/leaves/leaves_menu.svg',
      menuBar: '/assets/icons/menu-calendar/menu-bar/menu-spring-frame.svg',
      start: new Date(2024, 2, 1),
      end: new Date(2024, 4, 31, 23, 59, 59),
      season: true,
      name: 'Весна',
      tooltip: ''
    },
    {
      logo: '/assets/icons/menu-calendar/logo/summer-logo.svg',
      feather: '/assets/icons/menu-calendar/leaves/leaves-summer-sun.svg',
      menuBar: '/assets/icons/menu-calendar/menu-bar/menu-summer.svg',
      start: new Date(2024, 5, 1),
      end: new Date(2024, 7, 31, 23, 59, 59),
      season: true,
      name: 'Літо',
      tooltip: ''
    },
    {
      logo: '/assets/icons/menu-calendar/logo/autumn.svg',
      feather: '/assets/icons/menu-calendar/leaves/leaves_menu.svg',
      menuBar: '/assets/icons/menu-calendar/menu-bar/autumn.svg',
      start: new Date(2024, 8, 1),
      end: new Date(2024, 10, 30, 23, 59, 59),
      season: true,
      name: 'Осінь',
      tooltip: ''
    },
    {
      logo: '/assets/icons/menu-calendar/logo/logo-winter.svg',
      feather: '/assets/icons/menu-calendar/leaves/leaves_menu.svg',
      menuBar: '/assets/icons/menu-calendar/menu-bar/winter.svg',
      start: new Date(2024, 11, 1),
      end: new Date(2024, 11, 31, 23, 59, 59),
      season: true,
      name: 'Зима ч2',
      tooltip: ''
    }

  ];

}
export class LogoDate {
  logo: string;
  feather: string;
  menuBar: string;
  start: Date;
  end: Date;
  season: boolean;
  name: string;
  tooltip: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.start = item?.start ? new Date(item.start) : null;
    this.end = item?.end ? new Date(item.end) : null;
  }
}
