import {Semester} from '@nit-core/models/new-academic-year';
import {JournalClass} from './filter';
import {ClassOfTutor} from './profile';

export class PresenceJournal {
  presence: PresenceDay[];
  page: number;
  countPages: number;
  className: string;
  constructor(item: any = {}) {
    Object.assign(this, item);
    this.presence = item.presence ? item.presence.map(x => new PresenceDay(x)) : [];
  }
}
export class PresenceDay {
  userId: string;
  countNotPresence: number;
  countSick: number;
  hasNotification: boolean;
  day: Date;
  isInactive: boolean;
  constructor(item: any = {}) {
    Object.assign(this, item);
    this.day = item.day ? new Date(item.day) : null;
  }
}
export class PresenceTotal {
  userId: string;
  countDayNotPresence: number;
  countNotPresence: number;
  countSick: number;
  countDaySick: number;
  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}


export class PresenceTotalBody {
  scheduleId?: string;
  usersTotalPresence?: PresenceTotal[];
  constructor(item: any = {}) {
    Object.assign(this, item);
    this.usersTotalPresence = item.usersTotalPresence ? item.usersTotalPresence.map(x => new PresenceTotal(x)) : [];
  }
}

export class PresenceTable {
  studentFirstSemesterPresence: PresenceTotal;
  studentSecondSemesterPresence: PresenceTotal;
  studentTotalSemesterPresence: PresenceTotal;
  constructor(item: any = {}) {
    Object.assign(this, item);
    this.studentFirstSemesterPresence = item.studentFirstSemesterPresence ? new PresenceTotal(item.studentFirstSemesterPresence) : undefined;
    this.studentSecondSemesterPresence = item.studentSecondSemesterPresence ? new PresenceTotal(item.studentSecondSemesterPresence) : undefined;
    this.studentTotalSemesterPresence = item.studentTotalSemesterPresence ? new PresenceTotal( item.studentTotalSemesterPresence) : undefined;
  }
}

export class ActiveVisitingFilter {
  currentClass: string;
  chosenSchedule: Semester;
  isTeacher: boolean;
  allClasses?: JournalClass[] | ClassOfTutor[];
  constructor(item: any = {}) {
    Object.assign(this, item);
    this.chosenSchedule = item.chosenSchedule ? new Semester(item.chosenSchedule) : null;
  }
}

export class EditedPresence {
  visitingId: string;
  absense: number;
  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class StudentVisiting {
  journalId: string;
  userId: string;
  absense: number;
  day: Date;
  journalDayId: string;
  scheduleId: string;
  subject: string;
  classId: string;
  constructor(item: any = {}) {
    Object.assign(this, item);
    this.day = item.day ? new Date(item.day) : null;
  }
}

export class StudentVisitingData {
  addVisitingData: StudentVisiting[];
  deleteVisitingData: string[];
  visitingStatus: EditedPresence[];
  constructor(item: any = {}) {
    Object.assign(this, item);
    this.addVisitingData = item.addVisitingData ? item.addVisitingData.map((x: any) => new StudentVisiting(x)) : [];
    this.visitingStatus = item.visitingStatus ? item.visitingStatus.map((x: any) => new EditedPresence(x)) : [];
  }
}
