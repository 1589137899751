export class BriefingPlan {
  day?: Date;
  date: Date;
  comment: string;
  content: string;
  id: string;
  type?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.day = item.day ? new Date(item.day) : null;
    this.date = item.date ? new Date(item.date) : null;
  }
}
