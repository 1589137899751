import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {NushCertificate} from '@nit-core/models';

@Injectable({
  providedIn: 'root'
})
export class NushCertificateService extends RestService<NushCertificate> {
  constructor() {
    super(NushCertificate, '/nush-certificates', 'nush-certificates');
  }
}
