import {LeaveType} from '@nit-core/global/domain/enums';

export class UserWithTransfers {
  userId: string;
  fullName: string;
  code: string;
  groupId?: string;
  groupName?: string;
  isActive: boolean;
  isActiveStatus?: string;
  transfers: Transfer[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.transfers = item.transfers ? item.transfers.map(transfer => new Transfer(transfer)) : [];
  }
}

export class Transfer {
  id?: string;
  userId?: string;
  classId?: string;
  groupId?: string;
  code?: string;
  groupName?: string;
  date: string;
  isArrived?: boolean;
  createGroupTransfers?: boolean;
  isParentTransfer?: boolean;
  parentTransferId?: string;
  leaveType?: LeaveType;
  leaveDetails?: TransferLeaveDetails;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.leaveDetails = item.leaveDetails ? new TransferLeaveDetails(item.leaveDetails) : undefined;
  }
}

export class TransferLeaveDetails {
  orderNumber: string;
  isLeaveAddressUnknown: boolean;
  leaveAddress?: TransferLeaveAddress;
  arbitraryLeaveInfo?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.leaveAddress = item.leaveAddress ? new TransferLeaveAddress(item.leaveAddress) : undefined;
  }
}

class TransferLeaveAddress {
  region: string;
  city: string;
  school: string;
  regionId?: string;
  schoolId?: string;
  cityId?: string;
  class?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
