import {Teacher} from './class';

export class JournalRemark {
  subject: string;
  subjectId?: string;
  class: string;
  scheduleId: string;
  finalDate: Date;
  remark: string;
  journalType: number;
  action: number;
  done?: boolean;
  id?: string;
  group?: string;
  adminStatus?: number;
  teacherStatus?: boolean;
  teacherNotificationSeen?: boolean;
  createdAt?: string;
  teacherIds?: string[];
  teachers?: Teacher[];
  isFds?: boolean;

  constructor(item: any = {}) {
    this.finalDate = item.finalDate ? new Date(item.finalDate) : null;
    this.teachers = item.teachers ? item.teachers.map(x => new Teacher(x)) : [];
    this.teacherIds = item.teacherIds ? [...item.teacherIds] : [];
    Object.assign(this, item);
  }
}


export class TeacherJournalInfo {
  className: string;
  subject?: string;
  scheduleId?: string;
  classGroupName?: string;
  teachers: Teacher[];
  teacherName?: string;

  constructor(item: any = {}) {
    this.teachers = item.teachers ? item.teachers.map(x => new Teacher(x)) : [];
    Object.assign(this, item);
  }
}
