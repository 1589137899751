export class Subject {
  id: string;
  name: string;
  shortName: string;
  isFds: boolean;
  canBeEdited: boolean;
  academicHours?: number;
  isActive: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
