import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Archive} from '@nit-core/models/archive';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ArchiveService extends RestService<Archive> {
  constructor() { super(Archive, '/archives', 'archives'); }

  downloadArchive(url: string): Observable<Blob> {
    return this.httpGetBlob(url, {});
  }
}
