import {ComponentRef, ElementRef, inject, Injectable, NgZone} from '@angular/core';
import {DropdownFilterOptionsComponent} from '@nit-core/components/dropdown-filter-options/dropdown-filter-options.component';
import {DropdownFilterComponent} from '@nit-core/components/dropdown-filter/dropdown-filter.component';
import {DynamicComponentDirective} from '@nit-core/directives/dynamic-component.directive';
import {PositionType} from '@nit-core/models';
import {Subscription} from 'rxjs';
import {PositionsOptionsComponent} from 'src/app/private/pages/users/components/position-options/positions-options.component';
import {PositionsFilterComponent} from 'src/app/private/pages/users/components/positions-filter/positions-filter.component';

type FilterOptions = ComponentRef<DropdownFilterOptionsComponent> | ComponentRef<PositionsOptionsComponent>;
type Dropdown = DropdownFilterComponent | PositionsFilterComponent;

@Injectable({
  providedIn: 'root'
})
export class DropdownFilterService {
  dropdowns: {[index: string]: Dropdown} = {};
  subscriptions: {[index: string]: Subscription} = {};
  dynamicComponentDirective: DynamicComponentDirective;

  private readonly _ngZone = inject(NgZone);

  handleDropdownToggling(event: {isOpened: boolean, dropdownSelect: ElementRef},
    dropdown: Dropdown,
    dynamicComponentDirective: DynamicComponentDirective): void {
    this.dynamicComponentDirective = dynamicComponentDirective;

    if (event.isOpened) {
      this.removeDynamicComponent();
      let component: FilterOptions;

      if (dropdown instanceof DropdownFilterComponent) {
        component = dynamicComponentDirective.viewContainerRef.createComponent(DropdownFilterOptionsComponent);
        component.instance.dropdownSelect = event.dropdownSelect;
        component.instance.selectedOption = dropdown.selectedOption;
        component.instance.options = dropdown.data;
        component.instance.valueField = dropdown.valueField;
        component.instance.textField = dropdown.textField;
        component.instance.defaultText = dropdown.defaultText;
        component.instance.defaultValue = dropdown.defaultValue;
        component.instance.noDefaultItem = dropdown.noDefaultItem;
        component.instance.hasScroll = dropdown.hasScroll;
      } else {
        component = this.dynamicComponentDirective.viewContainerRef.createComponent(PositionsOptionsComponent);
        component.instance.positionsSelect = event.dropdownSelect;
        component.instance.selectedOption = dropdown.selectedOption;
      }

      this.addSubscription(dropdown, component);
    } else {
      this.removeSubscription(dropdown.component);
    }
  }

  addSubscription(dropdown: Dropdown, optionsComponent: FilterOptions): void {
    const filterName = dropdown instanceof DropdownFilterComponent ?
      dropdown?.alias || dropdown.component : dropdown.component;
    this._addDropdownToList(filterName, dropdown);

    let subscr: Subscription;
    if (optionsComponent.instance instanceof DropdownFilterOptionsComponent) {
      subscr = optionsComponent.instance.selected.subscribe((option: Record<string, any>) => {
        if (this.dropdowns[filterName] instanceof DropdownFilterComponent) {
          (this.dropdowns[filterName] as DropdownFilterComponent).selectedOption$.next(option);
          this.removeDynamicComponent();
          this.removeSubscription(filterName);
        }
      });
    } else {
      subscr = optionsComponent.instance.selected.subscribe((option: PositionType) => {
        if (this.dropdowns[filterName] instanceof PositionsFilterComponent) {
          (this.dropdowns[filterName] as PositionsFilterComponent).selectedOption$.next(option);
          this.removeDynamicComponent();
          this.removeSubscription(filterName);
        }
      });
    }

    this.subscriptions[filterName] = subscr;
  }

  handleClickOutside(event: string): void {
    setTimeout(() => {
      if (Object.keys(this.subscriptions).length === 1 &&
        Object.keys(this.dropdowns).includes(event)) {
        this.removeDynamicComponent();
      }
      this.removeSubscription(event, false);
    }, 50);
  }

  removeSubscription(filterName: string,
    isDropdownClick: boolean = true): void {
    this.subscriptions?.[filterName]?.unsubscribe();
    delete this.subscriptions[filterName];
    delete this.dropdowns[filterName];

    if (isDropdownClick) {
      this.removeDynamicComponent();
    }
  }

  removeDynamicComponent(): void {
    this.dynamicComponentDirective?.viewContainerRef.clear();
  }

  hideOptionsOnTableScroll(): void {
    this._ngZone.run(() => {
      this.unsubscribeAll();
      this.removeDynamicComponent();
    });
  }

  unsubscribeAll(): void {
    Object.entries(this.subscriptions).forEach(([_, subscr]) => {
      subscr.unsubscribe();
    });
    this.subscriptions = {};
    this.dropdowns = {};
  }

  private _addDropdownToList(filterName: string, dropdown: Dropdown): void {
    this.dropdowns[filterName] = dropdown;
  }
}
