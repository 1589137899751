import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LogoComponent} from './logo.component';
import {RouterModule} from '@angular/router';
import {PermissionModule} from '@nit-core/permission/permission.module';
import {NitSharedModule} from 'src/app/core/modules/shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PermissionModule,
    NitSharedModule
  ],
  declarations: [
    LogoComponent
  ],
  exports: [
    LogoComponent
  ]
})
export class LogoModule {
}
