import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild, inject} from '@angular/core';
import {MarkItemComponent} from '../mark/mark-item/mark-item.component';
import {FormModule} from '@nit-core/modules';
import {AchievementMark} from '@nit-core/models/nush-mark';
import {NitForm} from '@nit-core/forms';
import {CommonModule} from '@angular/common';
import {fromEvent, skipUntil, timer} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Grading} from '@nit-core/models';
import {configControlsType} from '../mark/mark.component';
import {CommentChangedEvent, DeleteCommentEvent} from '../../models/nush.models';
import {AchievementMarkType} from '@nit-core/global/domain/enums';

@UntilDestroy({checkProperties: true})
@Component({
  standalone: true,
  selector: 'nit-nus-popover',
  templateUrl: './nus-popover.component.html',
  styleUrls: ['./nus-popover.component.scss'],
  imports: [
    MarkItemComponent,
    FormModule,
    CommonModule
  ]
})
export class NusPopoverComponent implements OnInit, AfterViewInit {
  @ViewChild('nusPopover', {static: false}) container: ElementRef;
  @Output() commentAddEditEmitted: EventEmitter<CommentChangedEvent> = new EventEmitter<CommentChangedEvent>();
  @Output() deleteCommentEmitted: EventEmitter<{data: DeleteCommentEvent; rowId: string; colId: string}> =
    new EventEmitter<{data: DeleteCommentEvent; rowId: string; colId: string}>();
  @Output() hidePopover: EventEmitter<void> = new EventEmitter<void>();

  readOnly: boolean;
  rate: AchievementMark;
  additionalValues: Record<string, string | boolean>;
  form: NitForm;
  rowId: string;
  colId: string;
  grading: Grading;
  cellRef: ElementRef;
  isAbsenseAllowed: boolean = false;
  isCommentAllowed: boolean = false;
  generalAchievementValue: AchievementMarkType;

  configControls: configControlsType = {
    isVerbalFormed: {
      type: 'isVerbalGradingActive',
      controls: [
        {name: 'Сформовано', value: true}
      ]
    },
    customDigitalRating: {
      type: 'isDigitalGradingActive',
      controls: [
        {name: 'зв.', value: 0},
        {name: 'н/а', value: 1},
        {name: 'зарах.', value: 2},
        {name: 'вивч.', value: 3},
        {name: 'н/о', value: 4},
        {name: 'не зарах.', value: 5}
      ]
    },
    absense: {
      type: 'absense',
      controls: [
        {name: 'H', value: 0}
      ]
    },
    leveledAssessmentType: {
      type: 'isLeveledGradingActive',
      controls: [
        {name: 'П', value: 0},
        {name: 'С', value: 1},
        {name: 'Д', value: 2},
        {name: 'В', value: 3},
      ]
    }
  };
  private readonly _elementRef = inject(ElementRef);
  private readonly _renderer = inject(Renderer2);

  ngOnInit(): void {
    fromEvent(document, 'click').pipe(
      skipUntil(timer(500)),
      untilDestroyed(this)
    ).subscribe(userClick => {
      const isClickedOutside = !this.container.nativeElement.contains(userClick.target);
      const isRelatedMarkCellClicked = this.cellRef.nativeElement.contains(userClick.target);

      if (isClickedOutside && !isRelatedMarkCellClicked) {
        this.hidePopover.emit();
      }
    });
  }

  ngAfterViewInit(): void {
    const divWidth = this._elementRef.nativeElement.childNodes[0].offsetWidth;
    const divHeight = this._elementRef.nativeElement.childNodes[0].offsetHeight;
    const cellCoords = this.cellRef.nativeElement.getBoundingClientRect();
    const shownFromLeft = cellCoords.right + divWidth + 51 >= window.innerWidth;
    const betterShownFromRight = cellCoords.left - divWidth - 51 < 0;
    const shownFromTop = cellCoords.bottom + divHeight >= window.innerHeight;

    const xPosition = shownFromLeft && !betterShownFromRight ?
      cellCoords.left - divWidth - 51 + 'px':
      cellCoords.right + 51 + 'px';
    const yPosition = shownFromTop ?
      cellCoords.top - divHeight + 30 + 'px' :
      cellCoords.bottom - 34 + 'px';

    this._renderer.setStyle(this._elementRef.nativeElement, 'position', 'fixed');
    this._renderer.setStyle(this._elementRef.nativeElement, 'top', yPosition);
    this._renderer.setStyle(this._elementRef.nativeElement, 'left', xPosition);
    this._renderer.setStyle(this._elementRef.nativeElement, 'z-index', '100');
  }

  addComment(): void {
    this.commentAddEditEmitted.emit({
      data: {
        title: 'Додати коментар',
        rate: this.rate,
        additionalValues: this.additionalValues
      },
      rowId: this.rowId,
      colId: this.colId,
      grading: this.grading
    });
    this.hidePopover.emit();
  }

  editComment(): void {
    this.commentAddEditEmitted.emit({
      data: {
        title: 'Редагувати коментар',
        rate: this.rate,
        additionalValues: this.additionalValues
      },
      rowId: this.rowId,
      colId: this.colId,
      grading: this.grading
    });
    this.hidePopover.emit();
  }

  removeComment(): void {
    this.deleteCommentEmitted.emit({
      data: {
        rate: this.rate,
        configControls: this.configControls
      },
      rowId: this.rowId,
      colId: this.colId
    });
    this.hidePopover.emit();
  }
}
