import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {ActiveAcademicYear, NewAcademicYear, Semester} from '@nit-core/models/new-academic-year';
import {Observable} from 'rxjs';
import {KendoDataQuery} from '@nit-core/models/common/kendo-data-query';
import {KendoFilterOperator} from '@nit-core/global/domain/enums';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AcademicYearService extends RestService<NewAcademicYear> {
  constructor() {
    super(NewAcademicYear, '/academic-years', 'academic-years');
  }

  getActive(userId?: string, schoolId?: number): Observable<ActiveAcademicYear> {
    return this.httpGet(`${this.apiHref()}/active`, {
      alias: 'academic-year-active',
      asUserId: userId,
      asUserSchoolId: schoolId
    });
  }

  getByClassId(classId: string, userId?: string, schoolId?: number): Observable<NewAcademicYear> {
    const queryFilters: KendoDataQuery = new KendoDataQuery(0, 1);
    queryFilters.pushFilters([{field: 'classIds', operator: KendoFilterOperator.In, value: classId}]);
    const options = {
      query: queryFilters.query,
      asUserId: userId,
      asUserSchoolId: schoolId
    };

    return this.all(options).pipe(
      map((res) => {
        return res.data.length ? res.data[0]: null;
      })
    );
  }

  getCurrentSemester(academicYear: ActiveAcademicYear): Semester {
    return new Date().getFullYear() === academicYear.yearStart
      ? academicYear.firstSemester : academicYear.secondSemester;
  }

}
