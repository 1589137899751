import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {JournalRemark} from '@nit-core/models/journal-remark';
import {RestService} from '@nit-core/services/global/http-services/rest.service';

@Injectable({
  providedIn: 'root'
})
export class JournalRemarkService extends RestService<JournalRemark> {
  constructor() {
    super( JournalRemark, '/journal-remarks', 'journal-remarks');
  }

  updateViewNotification(notificationData: {[key: string]: string}): Observable<void> {
    return this.httpPut(`${this.apiHref()}/teacher/notification-seen`, notificationData);
  }

  updateTeacherStatus(id: string, done: boolean): Observable<void> {
    return this.httpPut(`${this.apiHref()}/${id}/teacher`, {done});
  }

  updateAdminStatus(id: string, adminStatus: number ): Observable<void> {
    return this.httpPut(`${this.apiHref()}/${id}/admin`, {adminStatus});
  }
}
