import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NitTooltipDirective} from '@nit-core/directives/tooltip/nit-tooltip.directive';
import {NitTooltipComponentModule} from '../../../private/components/tooltip/tooltip.module';
import {ConditionalTooltipDirective} from './conditional-tooltip.directive';

@NgModule({
  declarations: [
    NitTooltipDirective,
    ConditionalTooltipDirective
  ],
  exports: [
    NitTooltipDirective,
    ConditionalTooltipDirective
  ],
  imports: [
    CommonModule,
    NitTooltipComponentModule
  ]
})
export class NitTooltipDirectiveModule { }
