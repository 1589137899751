import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DialogCloseResult} from '@progress/kendo-angular-dialog';

@Component({
  selector: 'nit-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() autoFocusedElement = '';
  @Input() title = '';
  @Input() subTitle = '';
  @Input() simple: boolean = false;
  @Input() closeOutside: boolean = false;
  @Input() width: number;
  @Input() height: string;
  @Input() minHeight: number;
  @Input() maxWidth: number;
  @Input() buttonWidth = 300;
  @Input() isPopup: boolean;
  @Input() closeOnEsc: boolean = true;
  @Input() displaySubmitBtn = true;
  @Input() displayCancelBtn = false;
  @Input() disableSubmitBtn = false;
  @Input() displaySubmitIcon = false;
  @Input() submitBtnType: string;
  @Input() submitIconPath: string;
  @Input() isRegistrationForOlympics: boolean = false;
  @Input() submitBtnText = 'Зберегти';
  @Input() cancelBtnText = 'Скасувати';
  @Input() titleAlign: 'left' | 'center' | 'right' = 'center';
  @Input() titleSize: 'small' | 'middle' | 'large' = 'large';
  @Input() paddingSize: 'small' | 'middle' | 'large' = 'small';

  @Output() opened: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() submited: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();

  isOpened: boolean = false;

  open(): void {
    this.isOpened = true;
    this.opened.emit();
    document.body.style.overflow = 'hidden';
  }

  close(event?: any): void {
    if (!(event instanceof DialogCloseResult) && this.closeOnEsc) {
      this.isOpened = false;
      this.closed.emit();
      document.body.style.overflow = '';
    }
  }

  cancel(): void {
    this.canceled.emit();
    document.body.style.overflow = '';
  }

  submit(): void {
    this.submited.emit();
  }
}
