<ng-container *ngIf="hasData && !isLoadInProgress">
  <ng-content></ng-content>
</ng-container>

<ng-container *ngIf="!hasData && !isLoadInProgress">
  <div class="no-data" [class.with-arrow]="withArrow">
    <img *ngIf="emptyClass" src="/assets/images/class/empty-class.svg" alt="no-data">
    <img *ngIf="!emptyClass && !isCallPage"
         src="/assets/images/shared/no-data.svg"
         alt="no-data"
         [class.small-img]="size === 'small'">
    <div [class]="thematicEvaluation ? 'no-data-thematic' : 'no-data-title'"
         [class.small-text]="size === 'small'"
         [ngStyle]="{'color': (isCallPage ? 'var(--white);' : 'var(--tertiary-300);')}"
         [innerHTML]="text"></div>
    <div *ngIf="additionalText"
         [class]="thematicEvaluation ? 'no-data-thematic-add' : 'no-data-title-add'"
         [innerHTML]="additionalText">
    </div>
  </div>
</ng-container>

