import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GuidService {
  generateGUID(): string {
    const guid = (
      this._chunk() + '-' + this._chunk() + '-4' + this._chunk().substr(0, 3) + '-' + this._chunk() + '-' + this._chunk()
    ).toLowerCase();

    return guid;
  }

  generatePassword(): string {
    const random = Math.round(Math.random() * 10);
    const specialSymbol = '#?!@$%^&*_-';
    const upper = 'ABCDEFGKLMNOPRS';
    const lower = 'abcdefjhkgld';
    const password = (
      this._chunk() + lower[random] + specialSymbol[random] + this._chunk() + upper[random] + this._chunk()
    );

    return password;
  }

  private _chunk(): string {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
}
