export class StudentMovement {
  firstSemester: Semester;
  secondSemester: Semester;
  year: Semester;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.firstSemester = item?.firstSemester ? new Semester(item.firstSemester) : null;
    this.secondSemester = item?.secondSemester ? new Semester(item.secondSemester) : null;
    this.year = item?.year ? new Semester(item.year) : null;
  }
}

export class Semester {
  id: string;
  classId: string;
  semester: number;
  studentsCountAtTheStart: number;
  newStudentsCount: number;
  excludedStudentsCount: number;
  studentCountAtTheEnd: number;
  movedToOtherSchoolsStudentsCount: number;
  excludedBecauseOfOtherReasonsStudentsCount: number;
  nonAssessedStudentsCount: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

