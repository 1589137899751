import {Injectable} from '@angular/core';
import {RestService} from '../global/http-services/rest.service';
import {Payment} from '@nit-core/models/payment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends RestService<Payment> {
  constructor() {
    super(Payment, '/payments', 'payments');
  }

  cancelPayment(id: string): Observable<null> {
    return this.httpPut(`${this.apiHref()}/${id}/cancel`, null);
  }

  remindPayment(id: string): Observable<null> {
    return this.httpPost(`${this.apiHref()}/${id}/remind`, null);
  }
}
