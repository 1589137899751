import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'mimetypeSplitter'
})
export class MimetypeSplitterPipe implements PipeTransform {

  transform(mimetype: string, secondElement: boolean = false): string {
    return mimetype?.split('/')[secondElement ? 1 : 0];
  }

}
