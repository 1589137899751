import {Component, OnInit} from '@angular/core';
import {PermissionService} from '@nit-core/permission/permission.service';
import {UserService} from '@nit-services';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'nit-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})

export class LogoComponent implements OnInit {

  logoUrl = '';
  isNewYear = false;

  constructor(private readonly permissionService: PermissionService, private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this._initLogoUrl();
    this._checkNewYear();
  }

  private _initLogoUrl(): void {
    this.userService.currentSchoolInfo$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.logoUrl = '/profile';
      if (value && this.permissionService.hasPermission(['get:dashboard-teacher', 'get:dashboard'])) {
        this.logoUrl = '/dashboard';
      }
    });
  }

  private _checkNewYear(): void {
    const isStartNewYear = new Date() >= new Date(new Date().getFullYear(), 11, 23);
    const isEndNewYear = new Date() <= new Date(new Date().getFullYear(), 0, 21);
    this.isNewYear = isStartNewYear && isEndNewYear;
  }
}
