import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {NushTableColumn} from '@nit-core/models/nush-table-column';

@Injectable({providedIn: 'root'})
export class StudentsObservationColumnService extends RestService<NushTableColumn> {

  constructor() {
    super(NushTableColumn, '/students-observation-columns', 'students-observation-columns');
  }

}
