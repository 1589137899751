export class ChatMedia {
  _id: string;
  file: ChatFile;
  originalName: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.file = item.file ? item.file.map((x: any) => new ChatFile(x)) : [];
  }
}

export class ChatFile {
  encoding: string;
  fieldName: string;
  filename: string;
  mimetype: string;
  originalname: string;
  path: string;
  size: number;
  sizeMB: string;
  validUntil: Date;
  createdAt: Date;
  file: ChatFile;
  shortFileName: string;
  originalNameFiltered: string;
  messageId?: string;
  user?: string;
  format: string;
  
  constructor(item: any = {}) {
    Object.assign(this, item);
    this.validUntil = item.validUntil ? new Date(item.validUntil) : undefined;
    this.createdAt = item.createdAt ? new Date(item.createdAt) : undefined;
    this.file = item.file ? item.file.map((x: any) => new ChatFile(x)) : [];
    this.shortFileName = item.split('.').pop();
    this.sizeMB = (item.file?.size / (1024*1024)).toFixed(2);
    this.originalNameFiltered = item.file.originalname.substring(0, item.file?.originalname.lastIndexOf('.'));
    this.format = item.file.mimetype.split('/').pop();
  }
}
