import {ApiResponse} from '@nit-models';
import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';
import {Select} from '@nit-core/models/select';

@Injectable({providedIn: 'root'})
export class AddressService extends RestService<Select> {
  constructor() {
    super(Select, '/address', 'address');
  }

  getRegions(name: string): Observable<ApiResponse<Select>> {
    return this.http.get<ApiResponse<Select>>(`${this.apiHref()}/regions?name=${name}`, {headers: this.aliasHeaders('regions', this.getApiHeaders())});
  }

  getCities(regionId: string, name: string): Observable<ApiResponse<Select>> {
    return this.http.get<ApiResponse<Select>>(`${this.apiHref()}/cities?regionId=${regionId}&name=${name}`,
      {headers: this.aliasHeaders('cities', this.getApiHeaders())});
  }

  getStreets(regionId: string, cityId: string, name: string): Observable<ApiResponse<Select>> {
    return this.http.get<ApiResponse<Select>>(`${this.apiHref()}/streets?regionId=${regionId}&cityId=${cityId}&name=${name}`,
      {headers: this.aliasHeaders('streets', this.getApiHeaders())});
  }
}
