import {AdministratorRole, SchoolType, LegalForm} from '@nit-core/global/domain/enums';

export class School {
  id: string;
  name: string;
  fullName: string;
  profileImage: string;
  type: SchoolType;
  legalForm: LegalForm;
  address: SchoolAddress;
  contact: SchoolContact;
  administration: SchoolAdministrator[];
  aikomId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.address = item?.address ? new SchoolAddress(item.address) : null;
    this.contact = item?.contact ? new SchoolContact(item.contact) : null;
    this.administration = item?.administration ? item.administration.map(x => new SchoolContact(x)) : [];
  }
}

export class SimpleSchool {
  id: string;
  name: string;
  schoolId: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class SchoolAddress {
  region: string;
  city: string;
  street: string;
  building: string;
  id?: string;
  regionId?: string;
  cityId?: string;
  streetId?: string;
  postIndex?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class SchoolContact {
  id: string;
  phoneNumber: string;
  email: string;
  webSite: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class SchoolAdministrator {
  id: string;
  fullName: string;
  role: AdministratorRole;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class SchoolStatus {
  active: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class NewSchool {
  id: string;
  schoolId: number;
  fullName: number;
  name: string;
  email: string;
  confirmed: boolean;
  aikomId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ConnectAikomBody {
  schoolId: number;
  aikomId: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class SchoolByEdrpou {
  id: string;
  name: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
