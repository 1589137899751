import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Warning} from '@nit-core/models/warning';
import {tap} from 'rxjs/operators';

@Injectable()
export class WarningService extends RestService<Warning> {
  hasWarning: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { super(Warning, '/warning', 'warning'); }

  getWarning(): Observable<Warning> {
    return this.http.get<Warning>(`${this.apiBaseHref()}/warning`)
      .pipe(tap(res => this.hasWarning.next(res.isActive)));
  }

  updateWarning(warning: Warning): Observable<Warning> | null {
    return this.httpPut(this.apiHref(), warning);
  }
}
