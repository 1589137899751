import {getShortName} from '@nit-core/methods';
import {Grading} from './nush-rule';

export class JournalStudent {
  firstName: string;
  fullName: string;
  lastName: string;
  middleName: string;
  userId: string;
  shortName?: string;
  journalGrading: Grading;
  educationType?: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.shortName = getShortName(this.firstName, this.lastName, this.middleName);
    this.journalGrading = item.journalGrading ? new Grading(item.journalGrading) : null;
  }
}

export class BlockedDaysForStudents {
  [key: string]: string[];

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
