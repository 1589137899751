import {NgModule} from '@angular/core';
import {MonthPickerComponent} from './month-picker.component';

@NgModule({
  declarations: [
    MonthPickerComponent,
  ],
  exports: [
    MonthPickerComponent,
  ]
})
export class MonthPickerModule {}
