<div class="options-container"
     [class.no-scroll]="!hasScroll">
  <div class="inner-container">
    <div *ngIf="!noDefaultItem"
         #optionElement
         class="option default"
         [class.selected]="selectedOption?.[valueField] === defaultValue"
         (click)="choseOption(defaultItem)">
      {{defaultText}}
    </div>

    <div class="category">
      <div class="options">
        <div *ngFor="let option of options"
             #optionElement
             class="option"
             [class.selected]="selectedOption?.[valueField] === option[valueField]"
             (click)="choseOption(option)">
          <span class="option-name"
                [class.has-margin]="true">
            {{option[textField]}}
          </span>
          <span *ngIf="option?.isFds"
                class="fds-label">
            ШПД
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
