import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'shortName'
})

export class ShortNamePipe implements PipeTransform {
  transform(value: string, short: boolean): any {
    if (value && short) {
      const temp: string[] = value?.split(' ');
      const firstName = temp.shift();
      const shortNames = temp.map( item => item.substr(0, 1) + '.');

      return firstName + ' ' + shortNames.join('');
    }

    return value;
  }
}
