import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {EducationForms} from '@nit-core/models/class';

@Injectable({
  providedIn: 'root'
})
export class EducationFormService extends RestService<EducationForms> {

  constructor() { super(EducationForms, '/education-forms', 'education-forms'); }
}
