import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {KendoDatePickerMessagesComponent} from './kendo-datepicker-messages';
import {DateInputsModule} from '@progress/kendo-angular-dateinputs';

@NgModule({
  imports: [
    CommonModule,
    DateInputsModule
  ],
  declarations: [
    KendoDatePickerMessagesComponent
  ],
  exports: [
    KendoDatePickerMessagesComponent
  ]
})
export class KendoDatePickerMessagesModule {}
