export class NushClassParallel {
  grade: string;
  name: string;
  parallelClasses: ParallelClass[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.parallelClasses = item.parallelClasses.length ?
      item.parallelClasses.map(parallelClass => new ParallelClass(parallelClass)) : [];
  }
}

export class ParallelClass {
  id: string;
  name: string;
  hasNushRule: boolean;
  chosen?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
