import {ApiResponse} from '@nit-core/models/common/response';
import {EducationPlan} from '@nit-core/models/education-plan';

export class JournalClass {
  id: string;
  name: string;
  number: number;
  letter: string;
  subjects: Subject[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.subjects = item.subjects ? item.subjects.map(el => new Subject(el)) : null;
  }
}

export class FilterState {
  classData?: JournalClass;
  classGroup: string;
  trainingPlanNextDate: ApiResponse<EducationPlan>;
  constructor(item: any = {}) {
    Object.assign(this, item);
    this.classData = item.classData ? new JournalClass(item.classData) : null;
    this.trainingPlanNextDate = item.trainingPlanNextDate ? new ApiResponse<EducationPlan>(item.trainingPlanNextDate, EducationPlan) : null;
  }
}

export class Filter {
  [key: string]: string;

  constructor(values: any = {}) {
    Object.assign(this, values);
  }
}

export class TotalRatingFilter {
  academicYearId?: string;
  selectedClassId: string;
  selectedDecisionId: string;

  constructor(values: any = {}) {
    Object.assign(this, values);
  }
}


export class Subject {
  groups: JournalSubjectGroup[];
  subject: string;
  isMyJournal: boolean;
  isFds: boolean;
  schedule: number;
  scheduleId: string;
  journalId?: string;
  hasCompetences: boolean;

  constructor(values: any = {}) {
    Object.assign(this, values);
    this.groups = values.groups ? this.groups.map(el => new JournalSubjectGroup(el)) : [];
  }
}

export class JournalSubjectGroup {
  id: string;
  name: string;
  isMyJournal: boolean;
  journalId: string;

  constructor(values: any = {}) {
    Object.assign(this, values);
  }
}

export class JournalInfo {
  journalId: string;
  hasCompetences: boolean;

  constructor(values: any = {}) {
    Object.assign(this, values);
  }
}
