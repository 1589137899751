export class PaymentSetting {
  id?: string;
  name: string;
  type: number;
  publicKey: string;
  secretKey?: string;
  confirmed: boolean;
  enabled: boolean;
  disabled?: boolean;
  tooltipText?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ConfirmationSettingData {
  signature: string;
  data: string;
  url: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
