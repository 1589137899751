import {Pipe, PipeTransform} from '@angular/core';
import {MatchQuestionAnswer} from '@nit-core/models/course';

@Pipe({
  name: 'matchQuestionAnswers'
})
export class MatchQuestionAnswersPipe implements PipeTransform {

  transform(matchQuestion: MatchQuestionAnswer[]): string[] {
    const answers: any[] = [];
    matchQuestion?.forEach(answerObj => {
      answers.push({viewValue: answerObj?.correctAnswer, id: answerObj?.correctAnswerId});
    });

    return answers;
  }
}
