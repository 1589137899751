import {SignalRHubMethod, FeathersHubMethod, FeathersHubService} from '@nit-core/global/domain/enums';

export class ApiResponse<T> {
  data: T[];
  total: number;
  firstFreeNumberOfLesson: string;
  firstFreePlanDate: string;
  ucheckedHomeworkCount: number;
  constructor(response: any, tType: (new (value: any) => any)) {
    if (response.data && Array.isArray(response.data)) {
      this.data = response.data.map((x: any) => new tType(x));
    }
    this.total = response.total;

    if (response.firstFreePlanDate) {this.firstFreePlanDate = response.firstFreePlanDate; }
    if (response.firstFreeNumberOfLesson) {this.firstFreeNumberOfLesson = response.firstFreeNumberOfLesson; }
    if (response.ucheckedHomeworkCount) {this.ucheckedHomeworkCount = response.ucheckedHomeworkCount; }

  }
}

export interface SignalRResponse {
  method: SignalRHubMethod;
  data: any;
}

export interface FeathersResponse {
  method: FeathersHubMethod | FeathersHubService;
  data: any;
}
