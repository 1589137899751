import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';
import {StudentsObservation, StudentsObservationPage} from '@nit-core/models/students-observation';

@Injectable({
  providedIn: 'root'
})
export class StudentsObservationService extends RestService<StudentsObservation> {
  constructor() {
    super(StudentsObservation, '/students-observations', 'students-observations');
  }

  getObservationColumns(query: {[param: string]: string},
    userId: string = null, schoolId: number = null): Observable<StudentsObservationPage> {
    const options: any = {
      query,
      alias: 'observation-pages',
      asUserId: userId,
      asUserSchoolId: schoolId
    };

    return this.httpGet<StudentsObservationPage>(`${this.apiHref()}/pages`, options);
  }
}
