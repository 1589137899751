import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Injectable()
export class GlobalService {
  get authUrl(): string { return environment.authUrl; }
  get apiUrl(): string { return environment.apiUrl; }
  get maintenanceUrl(): string { return environment.maintenanceUrl; }
  get chatUrl(): string { return environment.chatUrl; }

  constructor(private readonly _router: Router) { }

  changeType(type: string): void {
    const urlTree = this._router.createUrlTree([], {
      queryParams: {type},
      queryParamsHandling: 'merge',
      preserveFragment: true
    });

    this._router.navigateByUrl(urlTree);
  }
}
