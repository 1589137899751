import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UpdateVersionModalComponent} from '@nit-core/components/update-version-modal/update-version-modal.component';
import {ModalModule} from '@nit-core/components/modal/modal.module';

@NgModule({
  declarations: [UpdateVersionModalComponent],
  exports: [
    UpdateVersionModalComponent
  ],
  imports: [
    CommonModule,
    ModalModule
  ]
})
export class UpdateVersionModalModule { }
