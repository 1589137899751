import {Injectable} from '@angular/core';
import {RestService} from '../global/http-services/rest.service';
import {ConfirmationSettingData, PaymentSetting} from '@nit-core/models';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentSettingsService extends RestService<PaymentSetting> {
  constructor() {
    super(PaymentSetting, '/payment-settings', 'payment-settings');
  }

  updateStatus(id: string, data: {enabled: boolean}): Observable<null> {
    return this.httpPut(`${this.apiHref()}/${id}/status`, data);
  }

  confirmSetting(id: string): Observable<ConfirmationSettingData> {
    return this.httpGet(`${this.apiHref()}/${id}/confirmation`);
  }
}
