import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, searchTerm: string): string {
    if (!searchTerm) return value;

    const re = new RegExp(searchTerm, 'gi');
    const match = value.match(re);

    if (!match) {
      return value;
    }

    return value.replace(re, '<span class=\'highlighted-text\'>' + match[0] + '</span>');
  }
}
