export class SchoolEvent {
  id?: string;
  color?: string;
  name: string;
  type?: string;
  description?: string;
  start: Date;
  end: Date;
  notificationTime?: Date;
  eventNotificationTimeDelay?: number;
  notificationList?: NotificationList;
  notificationListCount?: NotificationListCount;
  detailedNotificationList?: DetailedNotificationList;
  wasSent?: boolean;
  startDay: number;
  endDay: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.start = item.start ? new Date(item.start) : null;
    this.startDay = this.start ? this.start.getDay() : null;
    this.end = item.end ? new Date(item.end) : null;
    this.endDay = this.end ? this.end.getDay() : null;
    this.notificationTime = item.notificationTime ? new Date(item.notificationTime) : null;
    this.notificationList = item.notificationList ? new NotificationList(item.notificationList) : null;
    this.notificationListCount = item.notificationListCount ? new NotificationListCount(item.notificationListCount) : null;
    this.detailedNotificationList = item.detailedNotificationList ? new DetailedNotificationList(item.detailedNotificationList) : null;
  }
}

export class NotificationList {
  classTeacherIds?: string[];
  userClassIds?: string[];
  subjectTeacherIds?: string[];
  parentClassIds?: string[];

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class NotificationListCount {
  classTeacherIdsCount?: number;
  userClassIdsCount?: number;
  subjectTeacherIdsCount?: number;
  parentClassIdsCount?: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class DetailedNotificationList {
  classTeacherIds?: DetailedNotification[];
  userClassIds?: DetailedNotification[];
  subjectTeacherIds?: DetailedNotification[];
  parentClassIds?: DetailedNotification[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.classTeacherIds = item?.classTeacherIds ? item.classTeacherIds.map(x => new DetailedNotification(x)) : null;
    this.userClassIds = item?.userClassIds ? item.userClassIds.map(x => new DetailedNotification(x)) : null;
    this.subjectTeacherIds = item?.subjectTeacherIds ? item.subjectTeacherIds.map(x => new DetailedNotification(x)) : null;
    this.parentClassIds = item?.parentClassIds ? item.parentClassIds.map(x => new DetailedNotification(x)) : null;
  }
}

export class DetailedNotification {
  id: string;
  name: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
