import {Component, Input, OnInit} from '@angular/core';
import {HttpPendingService} from '@nit-services';
import {untilDestroyed, UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'nit-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})
export class NoDataComponent implements OnInit {
  @Input() text: string;
  @Input() additionalText: string;
  @Input() hasData: boolean;
  @Input() size: 'large' | 'small' = 'large';
  @Input() withArrow: boolean;
  @Input() spinnerType: 'first-load' | 'always' = 'first-load';
  @Input() spinnerMarker: string;
  @Input() emptyClass: boolean = false;
  @Input() thematicEvaluation: boolean = false;
  isLoadInProgress: boolean;
  isCallPage: boolean;
  private _subscription: Subscription;

  constructor(
    private readonly _pendingService: HttpPendingService,
    private readonly _route: ActivatedRoute
  ) {
    this.isCallPage = this._route.snapshot.data.isCallPage;
  }

  ngOnInit(): void {
    this.isLoadInProgress = !!this.spinnerMarker;
    if (this.spinnerMarker) {
      this._subscription = this._pendingService.isLoadInProgress$
        .pipe(untilDestroyed(this))
        .subscribe(x => this._handleEvent(x));
    }
  }

  private _handleEvent(data: any): void {
    if (data.event === this.spinnerMarker) {
      this.isLoadInProgress = data.inProgress;

      if (this.spinnerType === 'first-load' && !data.inProgress) {
        Promise.resolve(0).then(() => this._subscription.unsubscribe());
      }
    }
  }
}
