import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {MobileVersion} from '@nit-core/models/mobile-version';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileVersionService extends RestService<MobileVersion> {
  constructor() {
    super(MobileVersion, '/mobile/versions', 'mobile/versions');
  }
  getMobileVersions(): Observable<MobileVersion> {
    return this.http.get<MobileVersion>(`${this.apiBaseHref()}/mobile/versions`);
  }

  updateWarning(form: MobileVersion): Observable<MobileVersion> | null {
    return this.httpPut(this.apiHref(), form);
  }
}
