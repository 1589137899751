import {BlockedJournalDay, Mark} from '@nit-core/models/journal';
import {StudentOfJournal} from './practice-journal';
import {JournalDay} from '@nit-core/models/journal-day';
import {ReportAttachment} from '@nit-core/models/report';

export class BriefingJournal {
  activeDay?: Date;
  classTeacher?: string;
  currentPage?: number;
  fullName?: string;
  groups?: string;
  journalIds?: string;
  marks?: Mark[];
  maxPage?: number;
  practiceJournalDays?: JournalDay[];
  subject?: string;
  teacherId: string;
  journalSubject: string;
  userId?: string;
  className?: string;
  userBlockedJournalDays: BlockedJournalDay[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.marks = item.marks ? item.marks.map((x: any) => new Mark(x)) : [];
    this.practiceJournalDays = item.practiceJournalDays ? item.practiceJournalDays.map((x: any) => new JournalDay(x)) : [];
    this.userBlockedJournalDays = item.userBlockedJournalDays ? item.userBlockedJournalDays.map((x: any) => new BlockedJournalDay(x)) : [];
    this.activeDay = item.activeDay ? new Date(item.activeDay) : null;
  }
}

export class BriefingSubject {
  contentCount: number;
  journalId: string;
  lastRecordDate: Date;
  subjectName: string;
  teacherId: string;
  id: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.lastRecordDate = item.lastRecordDate ? new Date(item.lastRecordDate) : null;
  }
}

export class FirstBriefing {
  id: string;
  classTeacher: string;
  date: Date;
  fullName: string;
  groups: string;
  userChecks: UserChecks[];
  requiredForStudents: boolean;
  attachment: ReportAttachment;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.date = item.date ? new Date(item.date) : null;
    this.userChecks = item.userChecks ? item.userChecks.map(x => new UserChecks(x)) : null;
    this.attachment = item.attachment ? new ReportAttachment(item.attachment) : null;
  }
}

export class UserChecks {
  briefingListened?: boolean;
  userId: string;
  teacherFullName?: string;
  date: Date;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.date = item.date ? new Date(item.date) : null;
  }
}

export class BriefingTeacherInfo {
  teacherId: string;
  teacherFullName: string;
  classId: string;
  scheduleId: string;
  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class StudentOfBriefing extends StudentOfJournal {
  briefingListened?: boolean;

  constructor(item: any = {}) {
    super(item);
    Object.assign(this, item);
  }
}

export enum BriefingActions {
  Create = 'create', Edit = 'edit',
}

export class FileId {
  fileId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
