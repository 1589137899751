import {BlockedJournalDay, Mark} from '@nit-core/models/journal';
import {Grading} from '@nit-core/models/nush-rule';
import {getShortName} from '@nit-core/methods';
import {BlurredInfo} from '@nit-core/models/journal-day';

export class PracticeJournal {
  id?: string;
  activeDay: Date;
  classId: string;
  className: string;
  countPages: number;
  currentPage: 0;
  maxPage: number;
  marks: Mark[];
  subject: string;
  scheduleFrom: Date;
  scheduleTo: Date;
  teacherId: string;
  practiceJournalDays: PracticeJournalDay[];
  userBlockedJournalDays: BlockedJournalDay[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.marks = item.marks ? item.marks.map((x: any) => new Mark(x)) : [];
    this.practiceJournalDays = item?.practiceJournalDays ?
      item.practiceJournalDays.map((x: any) => new PracticeJournalDay(x)) : [];
    this.userBlockedJournalDays = item?.userBlockedJournalDays ?
      item.userBlockedJournalDays.map((x: any) => new BlockedJournalDay(x)) : [];
    this.scheduleFrom = item.scheduleFrom ? new Date(item.scheduleFrom) : null;
    this.scheduleTo = item.scheduleTo ? new Date(item.schedulTo) : null;
    this.activeDay = item.activeDay ? new Date(item.activeDay) : null;
  }
}

export class PracticeJournalDay {
  date: Date;
  id: string;
  isCompleted: boolean;
  isBlurred: boolean;
  blurredForStudents?: BlurredInfo;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.date = item?.date ? new Date(item.date) : null;
    this.blurredForStudents = item.blurredForStudents ? new BlurredInfo(item.key) : null;
  }
}

export class TeacherPracticeJournal {
  marks: Mark[];
  practiceJournalDays: PracticeJournalDay[];
  practiceJournalsIds: string[];
  constructor(item: any = {}) {
    Object.assign(this, item);
    this.marks = item.marks ? item.marks.map((x: number) => new Mark(x)) : null;
    this.practiceJournalDays = item.practiceJournalDays ? item.practiceJournalDays.map((x: any) => new PracticeJournalDay(x)) : null;
  }
}


export class StudentOfJournal {
  userId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
  shortName: string;
  journalGrading: Grading;
  educationType?: number;
  teacherFullName: string;
  date: Date;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.shortName = getShortName(this.firstName, this.lastName, this.middleName);
    this.journalGrading = item.journalGrading ? new Grading(item.journalGrading) : null;
    this.date = item.date ? new Date(item.date) : null;
  }
}

export class LearningOutcomesCharacteristics {
  id: string;
  journalId: string;
  learningOutcomeId: string;
  name: string;
  fullName: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
