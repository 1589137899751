import {Pipe, PipeTransform} from '@angular/core';
import {MONTHS_GENITIVE} from '@nit-core/global/domain/constans';

@Pipe({
  name: 'monthInGenitiveCase'
})
export class MonthNameGenitivePipe implements PipeTransform {
  monthListGenitive: string[] = MONTHS_GENITIVE;

  transform(value: Date): string {
    return this.monthListGenitive[value.getMonth()];
  }
}
