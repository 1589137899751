<nit-modal #modal id="update-version"
           isPopup="true"
           [width]="628"
           [title]="'Зверніть увагу!'"
           [titleSize]="'small'">
  <div class="description">
    Ваша версія застосунку застаріла. Для коректної роботи застосунку перезавантажте, будь ласка, сторінку, попередньо зберігши зміни.
  </div>
  <div class="action-box">
    <button class="cancel">Нагадати пізніше</button>
    <button class="reload" (click)="reloadPage()">Перезавантажити сторінку</button>
  </div>
</nit-modal>
