<div class="options-container">
  <div #innerContainer class="inner-container">
    <div #option
         class="option"
         [class.selected]="selectedOption.value === 0 &&  selectedOption.type === 'all'"
         (click)="choseOption({type: 'all', text: 'Всі', value: 0})">
      Всі
    </div>

    <div class="category">
      <span class="category-name">Загальні посади</span>
      <div class="options">
        <div *ngFor="let position of generalPositions"
             #option
             class="option"
             [class.selected]="selectedOption.value === position.value && selectedOption.type === 'general'"
             (click)="choseOption({
               type: 'general',
               text: position.text,
               value: position.value
             })">
          {{position.text}}
        </div>
      </div>
    </div>

    <div class="category">
      <span class="category-name">ШПД</span>
      <div class="options">
        <div *ngFor="let position of fdsPositions"
             #option
             class="option"
             [class.selected]="selectedOption.value === position.value && selectedOption.type === 'fds'"
             (click)="choseOption({
               type: 'fds',
               text: position.text,
               value: position.value
             })">
          {{position.text}}
        </div>
      </div>
    </div>
  </div>
</div>
