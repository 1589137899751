import {isTeacher} from 'src/app/private/pages/basic-data/pages/new-teacher-schedule/utils/new-teacher-schedule.utils';
import {DayWithLessons} from './scheduled-lesson';
import {Teacher} from './teacher';
import {Load} from './workload';

export class Vacancy {
  name: string;
  subject: string;
  subjectId?: string;
  id?: string;
  shortName?: string;
  days?: DayWithLessons[];
  load?: Load;
  isFds?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.shortName = item.name;
    this.load = item.load ? new Load(item.load) : null;
    this.days = item.days ? item.days.map(day => new DayWithLessons(day)) : [];
  }
}

export class Replacement {
  teacher: Teacher | Vacancy;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.teacher = !item.teacher ? null :
      isTeacher(item.teacher) ? new Teacher(item.teacher): new Vacancy(item.teacher);
  }
}
