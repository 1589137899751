import {CommonModule} from '@angular/common';
import {AfterViewInit, Component, ElementRef, EventEmitter, Output, QueryList, Renderer2, ViewChildren, inject} from '@angular/core';
import {FdsPositionsList, GeneralPositionsList} from '@nit-core/global/domain/enums-select';
import {PositionType} from '@nit-core/models';

@Component({
  standalone: true,
  selector: 'nit-positions-options',
  templateUrl: './positions-options.component.html',
  styleUrls: ['./positions-options.component.scss'],
  imports: [CommonModule]
})
export class PositionsOptionsComponent implements AfterViewInit {
  @ViewChildren('option') optionDivs: QueryList<ElementRef<HTMLDivElement>>;
  @Output() selected: EventEmitter<PositionType> = new EventEmitter<PositionType>();

  selectedOption: PositionType = {type: 'all', value: 0, text: 'Всі'};
  positionsSelect: ElementRef;
  fdsPositions = FdsPositionsList;
  generalPositions = GeneralPositionsList;

  private readonly _elementRef = inject(ElementRef);
  private readonly _renderer: Renderer2 = inject(Renderer2);

  ngAfterViewInit(): void {
    const positionsSelectWidth = this.positionsSelect.nativeElement.offsetWidth + 'px';
    const cellCoords = this.positionsSelect.nativeElement.getBoundingClientRect();

    const xPosition = cellCoords.left + 'px';
    const yPosition = cellCoords.bottom + 'px';
    this._renderer.setStyle(this._elementRef.nativeElement, 'position', 'fixed');
    this._renderer.setStyle(this._elementRef.nativeElement, 'top', yPosition);
    this._renderer.setStyle(this._elementRef.nativeElement, 'left', xPosition);
    this._renderer.setStyle(this._elementRef.nativeElement, 'z-index', '1');
    this._renderer.setStyle(this._elementRef.nativeElement, 'width', positionsSelectWidth);

    this._scrollToChosen();
  }

  choseOption(option: PositionType): void {
    this.selectedOption = option;
    this.selected.emit(option);
  }

  private _scrollToChosen(): void {
    const options = this.optionDivs.toArray();
    const targetElement = options.find((option: ElementRef<HTMLDivElement>) =>
      option.nativeElement.classList.contains('selected'));

    targetElement.nativeElement?.scrollIntoView({
      behavior: 'auto',
      block: 'end',
      inline: 'center'
    });
  }
}
