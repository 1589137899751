import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'trimTags'
})
export class TrimTagsPipe implements PipeTransform {
  transform(value: string): string {
    return value?.replace(/<(?:.|\n)*?>/gm, ' ');
  }
}
