import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'roomName'
})
export class RoomNamePipe implements PipeTransform {

  transform(roomName: string, isPrivate: boolean): string {

    return isPrivate ? roomName?.slice(0, 1).toUpperCase() + roomName?.split(' ')[1].slice(0, 1).toUpperCase() : roomName?.slice(0, 2);
  }
}
