import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {PendingInterceptor} from './pending.interceptor';
import {ErroInterceptor} from './error.interceptor';

export const HttpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: PendingInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErroInterceptor,
    multi: true
  },
];
