import {NotificationType} from '@nit-core/global/domain/enums';

export class ProfileNotification {
  id: string;
  createdAt: Date;
  read: boolean;
  message: string;
  userId: string;
  createdUserId: string;
  type: NotificationType;
  sender?: string;
  subject?: string;
  data?: DataEventId;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.createdAt = item.createdAt ? new Date(item.createdAt) : undefined;
    this.data = item.data ? new DataEventId(item.data) : null;
  }
}

export class SchoolEventNotification {
  id: string;
  eventTitle: string;
  createdAt: Date;
  dateEvent: string;
  type: number;
  constructor(item: any = {}) {
    Object.assign(this, item);
    this.createdAt = item.createdAt ? new Date(item.createdAt) : null;
  }
}

export class DataEventId {
  eventId?: string;
  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
