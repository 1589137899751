export class ClassGroup {
  id: string;
  subject: string;
  subjectId?: string;
  isFds?: boolean;
  classId: string;
  groupsStudents: GroupsStudents[] = [];
  scheduleId: string;
  isNotGrouped: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class GroupsStudents {
  id?: string;
  name: string;
  students: string[] = [];

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class SelectedStudentGroup {
  subjectId: string;
  studentId: string[];
  isAllChecked: boolean;
  isUsualCheckbox: boolean;
  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class Pairs {
  subject: string;
  classId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class GroupPairs {
  pairs: Pairs[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.pairs = item.pairs ? item.pairs.map(pair => new Pairs(pair)) : [];
  }
}
