import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {MarksChangelog} from '@nit-core/models/marks-changelog';

@Injectable({
  providedIn: 'root'
})
export class MarksChangelogService extends RestService<MarksChangelog> {
  constructor() { super(MarksChangelog, '/marks-changelog', 'marks-changelog'); }
}
