import {FinalMark} from '@nit-core/models/final-mark';

export class TotalFinalMark {
  average1S: number;
  average2S: number;
  averageYear: number;
  averageDPA: number;
  finalMarks: FinalMark[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.finalMarks = item.finalMarks ? item.finalMarks.map(finalMark => new FinalMark(finalMark)) : [];
  }
}
