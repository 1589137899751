import {Injectable} from '@angular/core';
import {HTTPOptions, RestService} from '@nit-core/services/global/http-services/rest.service';
import {SocialGroup, SocialGroupGeneralStatistic} from '@nit-core/models/social-group';
import {ApiResponse} from '@nit-models';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocialGroupService extends RestService<SocialGroup> {
  constructor() {
    super(SocialGroup, '/social-groups', 'social-groups');
  }

  getStatistic(options?: HTTPOptions): Observable<ApiResponse<SocialGroupGeneralStatistic>> {
    return this.httpGetAll(`${this.apiHref()}/statistics`, {
      ...options,
      factory: SocialGroupGeneralStatistic
    });
  }
}
