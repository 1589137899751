import {Component, Input, Output, EventEmitter} from '@angular/core';
import {MONTHS} from '@nit-core/global/domain/constans';

@Component({
  selector: 'nit-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss']
})
export class MonthPickerComponent {
    @Input() selectedDate: Date = new Date();
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Output() monthChanged: EventEmitter<Date> = new EventEmitter();
    monthes: string[] = MONTHS;

    changeDate(pointer: 'next' | 'previous'): void {
      let newDate: Date;

      if (pointer === 'next') {
        newDate = this.selectedDate.getMonth() === 11
          ? new Date(this.selectedDate.getFullYear() + 1, 0)
          : new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1);
      } else {
        newDate = this.selectedDate.getMonth() === 0
          ? new Date(this.selectedDate.getFullYear() - 1, 11)
          : new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() - 1);
      }

      if (newDate.getFullYear() >= this.minDate.getFullYear() && newDate.getMonth() >= this.minDate.getMonth() && newDate <= this.maxDate) {
        this.selectedDate = newDate;
        this.monthChanged.emit(newDate);
      }
    }
}
