import {User} from './common/user';
import {Payment} from './payment';
import {Subject} from './subject';

export class Request {
  id: string;
  region: string;
  city: string;
  type: number;
  status: number;
  class: string;
  canConfirm: boolean;
  rejectDecision: string;
  changedByUserId: string | null;
  createdAt: Date;
  sender: Sender;
  school: School;
  child: Child;
  payment?: Payment;
  attachments: Attachment[];
  mark: Mark;
  subjects: Subject[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.createdAt = item.createdAt ? new Date(item.createdAt) : undefined;
    this.sender = item.sender ? new Sender(item.sender) : undefined;
    this.school = item.school ? new School(item.school) : undefined;
    this.child = item.child ? item.child : undefined;
    this.attachments = item.attachments ? item.attachments.map(x => new Attachment(x)) : undefined;
    this.mark = item.mark ? new Mark(item.mark) : undefined;
    this.subjects = item.subjects?.length ? item.subjects.map(subject => new Subject(subject)) : [];
  }
}
export class Mark {
  class: string;
  day: string;
  id: string | null;
  newMarkAbsense: number;
  newMarkPresence: boolean | null;
  newMarkRating: number | null;
  oldMarkPresence: boolean | null;
  oldMarkAbsense: number;
  oldMarkRating: number | null;
  oldCustomMark: number | null;
  oldLeveledMark: number | null;
  newCustomMark: number | null;
  newLeveledMark: number | null;
  oldIsVerbalFormed: number | null;
  newIsVerbalFormed: number | null;
  reasonOfChanging: string | null;
  subType: number;
  subject: string | null;
  isFds: boolean;

  get hasOldData(): boolean {
    return this.oldMarkAbsense !== null || this.oldMarkRating !== null ||
      this.oldCustomMark !== null || this.oldIsVerbalFormed !== null ||
      this.oldLeveledMark !== null;
  }

  get hasNewData(): boolean {
    return this.newMarkAbsense !== null || this.newMarkRating !== null ||
      this.newCustomMark !== null || this.newIsVerbalFormed !== null ||
      this.newLeveledMark !== null;
  }

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class School {
  id: string;
  name: string;
  region: string;
  city: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class Child extends User {

  constructor(item: any = {}) {
    super(item);
    Object.assign(this, item);
  }
}

export class Sender extends User {
  id: string;
  email: string;

  constructor(item: any = {}) {
    super();
    Object.assign(this, item);
  }
}

export class Attachment {
  name: string;
  url: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

