import {Class} from '@nit-core/models/class';
import {ProfileAddress} from '@nit-core/models/profile';
import {TransferLeaveDetails} from './transfer';

export class StudentGeneralInfoTable {
  currentClass: Class;
  teacherClassId: string;
  generalInfoAboutStudents: StudentGeneralInfo[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.generalInfoAboutStudents = item?.generalInfoAboutStudents?.length > 0 ? item.generalInfoAboutStudents.map(x => new StudentGeneralInfo(x)) : [];
    this.currentClass = item.currentClass ? new Class(item.currentClass) : null;
  }
}

export class StudentGeneralInfo {
  id: string;
  userId: string;
  classId: string;
  email: string;
  phoneNumber: string;
  birthDay: Date;
  orderDate: Date;
  firstName: string;
  fullName: string;
  lastName: string;
  middleName: string;
  sex: number;
  relations?: StudentParent[];
  address: ProfileAddress;
  leaveDetails?: TransferLeaveDetails;
  personalFile: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.leaveDetails = item.leaveDetails ? new TransferLeaveDetails(item.leaveDetails) : undefined;
    this.birthDay = item.birthDay ? new Date(item.birthDay) : undefined;
    this.birthDay = this.birthDay?.getFullYear() < 1900 ? new Date(new Date(this.birthDay).setFullYear(1900)) : this.birthDay;
    this.address = item.address ? new ProfileAddress(item.address) : undefined;
    this.relations = item.relations.length ? item.relations.map(relation => new StudentParent(relation)) : [];
  }
}

export class StudentParent {
  firstName: string;
  lastName: string;
  middleName: string;
  fullName: string;
  code?: string;
  birthDay?: Date;
  email?: string;
  workPlace?: StudentParentWorkplace;
  phoneNumber?: string;
  address?: ProfileAddress;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.address = item.address ? new ProfileAddress(item.address) : undefined;
    this.workPlace = item.workPlace ? new StudentParentWorkplace(item.workPlace) : undefined;
    this.birthDay = item.birthDay ? new Date(item.birthDay) : undefined;
    this.birthDay = this.birthDay?.getFullYear() < 1900 ? new Date(new Date(this.birthDay).setFullYear(1900)) : this.birthDay;
    this.fullName = this._getFullName([item.lastName, item.firstName, item.middleName]);
  }

  private _getFullName(names: string[]): string {
    const fullName = [];
    names.forEach(name => {
      if (name) {
        fullName.push(name);
      }
    });

    return fullName.join(' ');
  }
}

class StudentParentWorkplace {
  institutionName: string;
  address: string;
  position: string;
  phone: string;
  workPlaceAddress: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.workPlaceAddress = [this.institutionName, this.address, this.phone].filter(Boolean).join(', ');
  }
}
