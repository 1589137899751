import {Pipe, PipeTransform} from '@angular/core';
import {ChildMark} from '@nit-core/models/child-journal';

@Pipe({
  name: 'hasMarkData'
})

export class HasMarkDataPipe implements PipeTransform {
  transform(mark: ChildMark): boolean {
    return !!mark.description || mark.absense !== null || !!mark.rating ||
      mark.customMark !== null || !!mark.isVerbalFormed || mark.leveledMark !== null;
  }
}
