import {HttpPendingService} from '@nit-services';
import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'nit-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() isDisabled: boolean;
  @Input() link: string;
  @Input() listenHttp: boolean = true;
  @Input() isSmall: boolean;
  @Input() type: ButtonType = 'primary';
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  disabledByRequestLoading: boolean = false;
  isTargetBtn: boolean = false;

  constructor(private readonly _pending: HttpPendingService) { }

  clickHandler(): void {
    this.isTargetBtn = true;
    this.clicked.emit();
  }

  ngOnInit(): void {
    if (this.listenHttp) {
      this._pending.isInProgress$.pipe(untilDestroyed(this)).subscribe({
        next: isInProgress => {
          this.disabledByRequestLoading = isInProgress;

          if (!isInProgress) {
            this.isTargetBtn = false;
          }
        }
      });
    }
  }
}

type ButtonType = 'new-child' | 'primary' | 'secondary' | 'info' | 'download' |
  'cancel' | 'cancel-edit' | 'refuse' | 'delete' | 'add-domain';
